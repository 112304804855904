import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  Paper,
  Button,
  Typography,
  Container,
} from "@mui/material";
import motivationsData from "./data/motivations.json";
import specificMotivationsData from "./data/specificMotivations.json";
import formatSource from "./Util/FormatSource";

let motivations = motivationsData
let specificMotivations = specificMotivationsData

function App({ character, handleCharacterChange, appFiles }) {
  const [motivationList, setMotivationList] = useState(character.Motivations);
  const blankMotivation = {
    Motivation: { Key: "" },
    SpecificMotivation: { Key: "" },
    Text: "",
  };

  useEffect(() => {
    if (appFiles.motivations && appFiles.specificMotivations) {
      motivations = JSON.parse(appFiles.motivations)
      specificMotivations = JSON.parse(appFiles.specificMotivations)
    }
  }, [appFiles.motivations, appFiles.specificMotivations]);

  const handleMotivationChange = (event, index) => {
    let newList = [...motivationList];
    newList[index].Motivation = motivations.find(
      (m) => m.Key === event.target.value,
    );
    newList[index].SpecificMotivation = { Key: "" };
    setMotivationList(newList);
    handleCharacterChange({ Motivations: newList });
  };

  const handleSpecificMotivationChange = (event, index) => {
    let newList = [...motivationList];
    newList[index].SpecificMotivation = specificMotivations.find(
      (m) => m.Key === event.target.value,
    );
    setMotivationList(newList);
    handleCharacterChange({ Motivations: newList });
  };

  const handleMotivationDetailsChange = (event, index) => {
    let newList = [...motivationList];
    newList[index].Text = event.target.value;
    setMotivationList(newList);
    handleCharacterChange({ Motivations: newList });
  };

  const addMotivation = (index) => {
    let newList = [...motivationList];
    newList.push(blankMotivation);
    setMotivationList(newList);
    handleCharacterChange({ Motivations: newList });
  };

  const removeMotivation = (index) => {
    let newList = [...motivationList];
    newList.splice(index, 1);
    if (newList.length === 0) {
      newList.push(blankMotivation);
    }
    setMotivationList(newList);
    handleCharacterChange({ Motivations: newList });
  };

  return (
    <Container>
      <Typography
        style={{ paddingTop: "25px", paddingLeft: "25px" }}
        variant="h3"
      >
        Motivations
      </Typography>
      <Paper elevation={3}>
        <Grid container style={{ padding: "25px" }}>
          {motivationList.map((motivation, index) => (
            <div key={index} style={{ width: "100%" }}>
              <Grid item xs={12} sm={Math.max(4, 12 / motivationList.length)}>
                <Grid container>
                  <Grid item xs={12} sm={11}>
                    <FormControl fullWidth sx={{ marginTop: '10px' }}>
                      <InputLabel>Select Motivation</InputLabel>
                      <Select
                        value={motivation.Motivation.Key}
                        onChange={(event) =>
                          handleMotivationChange(event, index)
                        }
                      >
                        {motivations.map((motivation, index) => (
                          <MenuItem key={motivation.Key} value={motivation.Key}>
                            {motivation.Name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={1}
                    style={{ display: "grid", autoGridFlow: "column" }}
                  >
                    {index === 0 && (
                      <Button
                        size="small"
                        style={{
                          fontSize: "0.7rem",
                          minWidth: "30px",
                        }}
                        onClick={() => addMotivation()}
                      >
                        +
                      </Button>
                    )}
                    <Button
                      size="small"
                      style={{
                        fontSize: "0.7rem",
                        minWidth: "30px",
                      }}
                      onClick={() => removeMotivation(index)}
                    >
                      -
                    </Button>
                  </Grid>
                </Grid>
                <Typography fontSize=".8rem">
                  {motivation.Motivation.Description}
                </Typography>
                {motivation.Motivation.Source && (<Typography fontSize=".8rem">
                  {formatSource(motivation.Motivation.Source)}
                </Typography>)}
                {motivation.Motivation.Name && (
                  <FormControl fullWidth sx={{ marginTop: '10px' }}>
                    <InputLabel>Select Specific Motivation</InputLabel>
                    <Select
                      value={motivation.SpecificMotivation.Key}
                      onChange={(event) =>
                        handleSpecificMotivationChange(event, index)
                      }
                    >
                      {specificMotivations
                        .filter(
                          (m) => m.Motivation === motivation.Motivation.Name,
                        )
                        .map((specificMotivation, index) => (
                          <MenuItem
                            key={specificMotivation.Key}
                            value={specificMotivation.Key}
                          >
                            {specificMotivation.Name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
                <Typography fontSize=".8rem">
                  {motivation.SpecificMotivation.Description}
                </Typography>
                {motivation.SpecificMotivation.Source && (<Typography fontSize=".8rem">
                  {formatSource(motivation.SpecificMotivation.Source)}
                </Typography>)}
                <TextField
                  label="Motivation Details"
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  value={motivation.Text}
                  onChange={(event) =>
                    handleMotivationDetailsChange(event, index)
                  }
                />
              </Grid>
            </div>
          ))}
        </Grid>
      </Paper>
    </Container>
  );
}

export default App;
