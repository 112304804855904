import talents from "../data/talents.json";
import forcePowers from "../data/forcePowers.json";
import forceAbilities from "../data/forceAbilities.json";
import skills from "../data/skills.json";
import * as _ from "lodash";
import getFullBoughtTalents from "./GetFullBoughtTalents";
import findNestedProperty from "./FindNestedProperty";

const DiceModifiers = (character, handleCharacterChange, talents, forcePowers, forceAbilities, skills) => {

  const { Bought: boughtTalents, universalForce } = getFullBoughtTalents(character);

  const getExtraCareerSkills = () => {
    let extraCareerSkills = [];
    if (character.Specializations[1]) {
      character.Specializations.forEach((s) => {
        if (s.CareerSkills) {
          extraCareerSkills.push.apply(extraCareerSkills, s.CareerSkills.Key);
        }
      });
    }
    boughtTalents.forEach((t) => {
      if (t.data.ChooseCareerSkills && t.data.ChooseCareerSkills.NewSkills) {
        if (Array.isArray(t.data.ChooseCareerSkills.NewSkills.Key)) {
          for (let s in t.data.ChooseCareerSkills.NewSkills.Key) {
            extraCareerSkills.push(
              skills.find(
                (sk) => sk.Key === t.data.ChooseCareerSkills.NewSkills.Key[s],
              ).skill,
            );
          }
        } else {
          extraCareerSkills.push(
            skills.find(
              (sk) => sk.Key === t.data.ChooseCareerSkills.NewSkills.Key,
            ).skill,
          );
        }
      }
    });
    return extraCareerSkills;
  };

  const extraCareerSkills = getExtraCareerSkills();
  if (!_.isEqual(extraCareerSkills, character.ExtraCareerSkills)) {
    handleCharacterChange({ ExtraCareerSkills: extraCareerSkills });
  }

  function getFullBoughtPowers() {
    const boughtPowers = [];
    const powerMap = {};

    for (let key in character.ForcePowers) {
      let power = forcePowers.find((p) => p.Key === key);
      power.BoughtPowers = character.ForcePowers[key];
      if (!power.AbilityRows) {
        return [];
      }
      power.AbilityRows.AbilityRow.forEach((row, rowIndex) => {
        row.Abilities.Key.forEach((powerKey, index) => {
          if (power.BoughtPowers[rowIndex][index]) {
            if (!powerMap[powerKey]) {
              const powerData = forceAbilities.find((p) => p.Key === powerKey);
              powerMap[powerKey] = powerData;
            }

            const existingPower = boughtPowers.find((p) => p.key === powerKey);
            if (existingPower) {
              existingPower.count++;
            } else {
              boughtPowers.push({
                key: powerKey,
                data: powerMap[powerKey],
                count: 1,
              });
            }
          }
        });
      });
    }
    return boughtPowers;
  }

  if (
    universalForce === 1 ||
    (character.Career.Attributes && character.Career.Attributes.ForceRating) ||
    (boughtTalents && boughtTalents.find((t) => t.key === "WITCHCCOTR"))
  ) {
    let FR = 1;
    if (character.Extras && character.Extras.ShortPath) { FR += 1 }
    if (boughtTalents && boughtTalents.find((t) => t.key === "FORCERAT")) {
      FR += boughtTalents.find((t) => t.key === "FORCERAT").count;
    }
    if (FR != character.ForceRating) {
      handleCharacterChange({ ForceRating: FR });
    }
  } else if (character.ForceRating !== 0) {
    handleCharacterChange({ ForceRating: 0 });
  }

  let weapons = character.Weapons;
  let armor = character.Armor;
  let gear = character.Gear;
  let boughtPowers = getFullBoughtPowers();
  let species = character.Species;
  let modifiers = [];

  function parseMods(mods) {
    if (mods[0]) {
      for (let m in mods) {
        let mod = mods[m];
        if (mod.DieModifiers) {
          if (mod.DieModifiers.DieModifier[0]) {
            for (let dm in mod.DieModifiers.DieModifier) {
              modifiers.push(mod.DieModifiers.DieModifier[dm]);
            }
          } else {
            modifiers.push(mod.DieModifiers.DieModifier);
          }
        }
      }
    } else {
      if (mods.DieModifiers) {
        if (mods.DieModifiers[0]) {
          for (let dm in mods.DieModifiers) {
            modifiers.push(mods.DieModifiers[dm]);
          }
        } else if (mods.DieModifiers.SkillKey) {
          modifiers.push(mods.DieModifiers);
        } else if (mods.DieModifiers.DieModifier[0]) {
          for (let dm in mods.DieModifiers.DieModifier) {
            modifiers.push(mods.DieModifiers.DieModifier[dm]);
          }
        } else {
          modifiers.push(mods.DieModifiers.DieModifier);
        }
      }
    }
  }

  if (character.Extras?.Reputation) {
    let reputation = parseInt(character.Extras.Reputation)
    if (reputation > 0) {
      parseMods({ DieModifiers: [{ SkillKey: 'CHARM', BoostCount: reputation }, { SkillKey: 'NEG', BoostCount: reputation }, { SkillKey: 'COERC', AddSetbackCount: reputation }] })
    }
    if (reputation < 0) {
      reputation = Math.abs(reputation)
      parseMods({ DieModifiers: [{ SkillKey: 'CHARM', AddSetbackCount: reputation }, { SkillKey: 'NEG', AddSetbackCount: reputation }, { SkillKey: 'COERC', BoostCount: reputation }] })
    }
  }

  for (let w in weapons) {
    if (weapons[w].Equipped && weapons[w].BaseMods) {
      if (weapons[w].BaseMods.DieModifiers) {
        parseMods(weapons[w].BaseMods);
      } else if (Array.isArray(weapons[w].BaseMods)) {
        for (let m in weapons[w].BaseMods) {
          if (weapons[w].BaseMods[m].DieModifiers) {
            parseMods(weapons[w].BaseMods[m]);
          }
        }
      }
    }
  }
  for (let a in armor) {
    if (armor[a].Equipped && armor[a].BaseMods) {
      if (armor[a].BaseMods.DieModifiers) {
        parseMods(armor[a].BaseMods);
      } else if (Array.isArray(armor[a].BaseMods)) {
        for (let m in armor[a].BaseMods) {
          if (armor[a].BaseMods[m].DieModifiers) {
            parseMods(armor[a].BaseMods[m]);
          }
        }
      }
    }
  }
  for (let g in gear) {
    if (gear[g].Equipped && gear[g].BaseMods) {
      if (gear[g].BaseMods.DieModifiers) {
        parseMods(gear[g].BaseMods);
      } else if (Array.isArray(gear[g].BaseMods)) {
        for (let m in gear[g].BaseMods) {
          if (gear[g].BaseMods[m].DieModifiers) {
            parseMods(gear[g].BaseMods[m]);
          }
        }
      }
    }
  }
  for (let t in boughtTalents) {
    let data = talents.find((tal) => boughtTalents[t].key === tal.Key);
    if (data.DieModifiers) {
      parseMods(data);
    }
  }
  for (let p in boughtPowers) {
    let data = boughtPowers[p].data;
    if (data.DieModifiers) {
      parseMods(data);
    }
  }

  if (species.OptionChoices && species.OptionChoices[0]) {
    for (let o in species.OptionChoices) {
      if (species.OptionChoices[o].Options.DieModifiers) {
        parseMods(species.OptionChoices[o].Options);
      }
    }
  } else if (
    species.OptionChoices &&
    species.OptionChoices.Options.DieModifiers
  ) {
    parseMods(species.OptionChoices.Options);
  }

  const skillMods = new Map();
  for (const item of modifiers) {
    const skillKey = item.SkillKey;
    const countType = Object.keys(item)[1]; // Get the first key (count type)
    const modCount = parseInt(item[countType], 10); // Parse string to integer

    if (skillMods.has(skillKey)) {
      const existingBoosts = skillMods.get(skillKey);
      existingBoosts[countType] = (existingBoosts[countType] || 0) + modCount;
    } else {
      skillMods.set(skillKey, { [countType]: modCount });
    }
  }

  // Convert the Map to the desired output format
  const mergedData = Array.from(skillMods.entries()).map(([key, value]) => ({
    SkillKey: key,
    ...value, // Spread the nested counts object for desired output
  }));

  return mergedData;
};
export default DiceModifiers;
