import modStatChange from "./ModStatChange";

const editQualities = (item, qualities, remove) => {
    let updatedQualities = [...(item.Qualities || [])];
    let moddedItem = { ...item }
    let statMod = false
    let newItem = { ...item }

    if (remove) {
        // Remove qualities
        qualities.forEach((quality) => {
            const existingQualityIndex = updatedQualities.findIndex(
                (q) => q.Key === quality.Key
            );
            if (existingQualityIndex !== -1) {
                // Quality already exists, update count
                const existingQuality = updatedQualities[existingQualityIndex];
                if (quality.Count) {
                    if (quality.NewCount && parseInt(existingQuality.Count) === parseInt(quality.NewCount)) {
                        quality.Count = quality.NewCount
                    }
                    existingQuality.Count =
                        (parseInt(existingQuality.Count || "0") || 0) -
                        (parseInt(quality.Count) || 0)
                }
                if (!existingQuality.Count || parseInt(existingQuality.Count) <= 0) {
                    updatedQualities.splice(existingQualityIndex, 1);
                }
            } else {
                [newItem, statMod] = modStatChange(newItem, quality, remove)
                if (statMod) { moddedItem = newItem }
            }
        });
    } else {
        // Add or update qualities
        qualities.forEach((quality) => {
            const existingQualityIndex = updatedQualities.findIndex(
                (q) => q.Key === quality.Key
            );
            if (existingQualityIndex !== -1) {
                // Quality already exists, update count
                const existingQuality = updatedQualities[existingQualityIndex];
                if (quality.Count) {
                    if (quality.QualReplace) {
                        existingQuality.Count = quality.Count
                    } else if (quality.QualSet) {
                        existingQuality.Count = String(Math.max(parseInt(quality.Count), parseInt(existingQuality.Count)))
                    } else {
                        existingQuality.Count = String(
                            (parseInt(existingQuality.Count || "0") || 0) +
                            (parseInt(quality.Count) || 0)
                        );
                    }
                }
            } else {
                // Quality doesn't exist, add it
                if (quality.NewCount) {
                    quality.Count = quality.NewCount
                }
                [newItem, statMod] = modStatChange(newItem, quality)
                if (statMod) { moddedItem = newItem }
                else { updatedQualities.push(quality); }
            }
        });
    }
    moddedItem.Qualities = updatedQualities
    return moddedItem;
};

export default editQualities