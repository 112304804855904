import React, { useState, useEffect, useRef } from 'react';
import {
    Modal, Box, Typography, Button, Select, MenuItem,
    TextField, FormControl, InputLabel, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, Paper,
    Grid, Chip
} from '@mui/material';
import MarkedText from './MarkedText';
import modStatChange from './ModStatChange';
import droidQuirks from '../data/droidQuirks.json'
import { Description } from '@mui/icons-material';

const positiveQuirks = droidQuirks.filter((q) => !q.IsNegative)
const negativeQuirks = droidQuirks.filter((q) => q.IsNegative)

const SuperiorHardwareSelector = ({ maxSelections, onSelectionsChange }) => {
    const [selections, setSelections] = useState([]);
    const [currentSelection, setCurrentSelection] = useState('');

    const options = [
        { value: 'BR', label: 'Brawn' },
        { value: 'AG', label: 'Agility' },
        { value: 'INT', label: 'Intellect' },
        { value: 'CUN', label: 'Cunning' },
        { value: 'WIL', label: 'Willpower' },
        { value: 'PR', label: 'Presence' },
    ];

    useEffect(() => {
        onSelectionsChange(selections);
    }, [selections]);

    const handleAdd = (event) => {
        const value = event.target.value;
        if (value && selections.length < maxSelections) {
            setSelections([...selections, value]);
            setCurrentSelection('');
        }
    };

    const handleDelete = (indexToDelete) => {
        setSelections(selections.filter((_, index) => index !== indexToDelete));
    };

    return (
        <FormControl fullWidth >
            <Grid container>
                <Grid item sx={{ display: 'flex', alignItems: 'center', }}>
                    <Typography variant="subtitle1" sx={{ paddingLeft: "15px" }}>
                        Superior Hardware:
                    </Typography>

                </Grid>
                <Grid item sx={{ paddingLeft: "10px" }}>
                    <Select
                        value={currentSelection}
                        onChange={handleAdd}
                        displayEmpty
                        disabled={selections.length >= maxSelections}
                    >
                        <MenuItem value="" disabled>Select {maxSelections} {maxSelections > 1 ? "options" : "option"}</MenuItem>
                        {options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item sx={{ display: 'flex', alignItems: 'center', paddingLeft: "10px" }}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, marginBottom: 1 }}>
                        {selections.map((item, index) => (
                            <Chip
                                key={index}
                                label={options.find(option => option.value === item).label}
                                onDelete={() => handleDelete(index)}
                            />
                        ))}
                    </Box>
                </Grid>
            </Grid>
        </FormControl>
    );
};

const AdaptiveProgrammingSelector = ({ options, maxSelections, onSelectionsChange }) => {
    const [selections, setSelections] = useState([]);
    const [currentSelection, setCurrentSelection] = useState('');

    useEffect(() => {
        onSelectionsChange(selections);
    }, [selections]);

    const handleAdd = (event) => {
        const value = event.target.value;
        if (value && selections.length < maxSelections) {
            setSelections([...selections, value]);
            setCurrentSelection('');
        }
    };

    const handleDelete = (indexToDelete) => {
        setSelections(selections.filter((_, index) => index !== indexToDelete));
    };

    return (
        <FormControl fullWidth >
            <Grid container>
                <Grid item sx={{ display: 'flex', alignItems: 'center', }}>
                    <Typography variant="subtitle1" sx={{ paddingLeft: "15px" }}>
                        Adaptive Programming:
                    </Typography>

                </Grid>
                <Grid item sx={{ paddingLeft: "10px" }}>
                    <Select
                        value={currentSelection}
                        onChange={handleAdd}
                        displayEmpty
                        disabled={selections.length >= maxSelections}
                    >
                        <MenuItem value="" disabled>Select {maxSelections} {maxSelections > 1 ? "options" : "option"}</MenuItem>
                        {options.map((option) => (
                            <MenuItem key={option.Key} value={option.Key}>
                                {option.skill}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item sx={{ display: 'flex', alignItems: 'center', paddingLeft: "10px" }}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, marginBottom: 1 }}>
                        {selections.map((item, index) => (
                            <Chip
                                key={index}
                                label={options.find(option => option.Key === item).skill}
                                onDelete={() => handleDelete(index)}
                            />
                        ))}
                    </Box>
                </Grid>
            </Grid>
        </FormControl>
    );
};

const SkillsSelector = ({ options, maxSelections, onSelectionsChange, directive, setDirectiveSkills }) => {
    const [selections, setSelections] = useState([]);
    const [currentSelection, setCurrentSelection] = useState('');
    const [optionsCache, setOptionsCache] = useState([])

    useEffect(() => {
        setSelections([]);
        setCurrentSelection('');
        setOptionsCache(options)
        onSelectionsChange([]);
        setDirectiveSkills([])
    }, [directive]);


    const handleAdd = (event) => {
        const value = event.target.value;
        if (value && selections.length < maxSelections) {
            const newSelections = [...selections, value];
            setSelections(newSelections);
            setCurrentSelection('');
            onSelectionsChange(newSelections);
            setDirectiveSkills(newSelections)
        }
    };

    const handleDelete = (indexToDelete) => {
        const newSelections = selections.filter((_, index) => index !== indexToDelete);
        setSelections(newSelections);
        onSelectionsChange(newSelections);
    };

    return (
        <FormControl fullWidth >
            <Grid container>
                <Grid item sx={{ display: 'flex', alignItems: 'center', }}>
                    <Typography variant="subtitle1" sx={{ paddingLeft: "15px" }}>
                        Skills:
                    </Typography>
                </Grid>
                <Grid item sx={{ paddingLeft: "10px" }}>
                    <Select
                        value={currentSelection}
                        onChange={handleAdd}
                        displayEmpty
                        disabled={selections.length >= maxSelections}
                    >
                        <MenuItem value="" disabled>Select {maxSelections} {maxSelections > 1 ? "options" : "option"}</MenuItem>
                        {options.filter((o) => !selections.some(s => s.Key === o.Key)).map((option) => (
                            <MenuItem key={option.Key} value={option}>
                                {option.skill}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item sx={{ display: 'flex', alignItems: 'center', paddingLeft: "10px" }}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, marginBottom: 1 }}>
                        {selections.map((item, index) => (
                            <Chip
                                key={index}
                                label={item.skill}
                                onDelete={() => handleDelete(index)}
                            />
                        ))}
                    </Box>
                </Grid>
            </Grid>
        </FormControl>
    );
};

const ResultsTable = ({ filteredResults, getCostString, selectedTemplate, setSelectedTemplate, character, handleCharacterChange, specificTemplate, setSpecificTemplate, resetKey, setResetKey, modularHardwareOptions, modularCyber, setModularCyber, skills, talents, addedSkills, setAddedSkills, setIncrementedSkills, incrementedTalents, setIncrementedTalents, getAllSkills }) => {
    const [results, setResults] = useState({})
    const [spentResults, setSpentResults] = useState({})
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [currentTemplate, setCurrentTemplate] = useState("")
    const [directiveSkills, setDirectiveSkills] = useState([])
    const [positiveQuirk, setPositiveQuirk] = useState({})
    const [negativeQuirk, setNegativeQuirk] = useState({})

    useEffect(() => {
        if (currentTemplate !== specificTemplate.Key) {
            setCurrentTemplate(specificTemplate.Key)
            setDirectiveSkills([])
        }
    }, [resetKey]);

    if ((!specificTemplate.Key || specificTemplate.Key !== currentTemplate) && (selectedOptions.length > 0 || JSON.stringify(results) !== "{}" || JSON.stringify(spentResults) !== "{}")) {
        setSelectedOptions([])
        setResults({})
        setSpentResults({})
        setCurrentTemplate(specificTemplate.Key)
        setResetKey(prevKey => prevKey + 1);
    }

    const handleOptionAdd = (index, count, type) => {
        let newSpentResults = { ...spentResults }
        if (type === "AdvThr") {
            if (count > 0) {
                newSpentResults["[AD]"] ? newSpentResults["[AD]"] += count : newSpentResults["[AD]"] = count
            }
            if (count < 0) {
                newSpentResults["[TH]"] ? newSpentResults["[TH]"] += Math.abs(count) : newSpentResults["[TH]"] = Math.abs(count)
            }
        }
        if (type === "TriDes") {
            if (count > 0) {
                newSpentResults["[TR]"] ? newSpentResults["[TR]"] += count : newSpentResults["[TR]"] = count
            }
            if (count < 0) {
                newSpentResults["[DE]"] ? newSpentResults["[DE]"] += Math.abs(count) : newSpentResults["[DE]"] = Math.abs(count)
            }
        }
        setSpentResults(newSpentResults)

        let newItem = JSON.parse(JSON.stringify(selectedTemplate))
        let newProfile = newItem.Droid
        if (filteredResults[index].WoundChange) {
            newProfile.Attributes.WoundThreshold = parseInt(newProfile.Attributes.WoundThreshold) + parseInt(filteredResults[index].WoundChange)
        }
        if (filteredResults[index].SoakChange) {
            newProfile.Attributes.Soak = parseInt(newProfile.Attributes.Soak) + parseInt(filteredResults[index].SoakChange)
        }
        if (filteredResults[index].MDefChange) {
            newProfile.Attributes.DefenseMelee = parseInt(newProfile.Attributes.DefenseMelee || 0) + parseInt(filteredResults[index].MDefChange)
        }
        if (filteredResults[index].RDefChange) {
            newProfile.Attributes.DefenseRanged = parseInt(newProfile.Attributes.DefenseRanged || 0) + parseInt(filteredResults[index].RDefChange)
        }
        if (filteredResults[index].SilhouetteChange) {
            newProfile.Silhouette = parseInt(newProfile.Silhouette || 1) + parseInt(filteredResults[index].SilhouetteChange)
        }
        if (filteredResults[index].RandomPositiveQuirk) {
            let randomQuirk = positiveQuirks[Math.floor(Math.random() * (Math.floor(positiveQuirks.length - 1) - 0 + 1))]
            randomQuirk.IsRandom = true
            newProfile.Abilities.push(randomQuirk)
        }
        if (filteredResults[index].RandomNegativeQuirk) {
            let randomQuirk = negativeQuirks[Math.floor(Math.random() * (Math.floor(negativeQuirks.length - 1) - 0 + 1))]
            randomQuirk.IsRandom = true
            newProfile.Abilities.push(randomQuirk)
        }
        if (filteredResults[index].Unobtrusive) {
            newProfile.Talents ? (newProfile.Talents.INDIS ? newProfile.Talents.INDIS += 1 : newProfile.Talents.INDIS = 1) : newProfile.Talents = { "INDIS": 1 }
        }
        if (filteredResults[index].DuplicateItem) {
            newItem.Duplicate ? newItem.Duplicate += parseInt(filteredResults[index].DuplicateItem) : newItem.Duplicate = parseInt(filteredResults[index].DuplicateItem)
        }

        if (filteredResults[index].Schematic) {
            let newSchematics = character.Schematics || {}
            newSchematics[specificTemplate.Key] ? newSchematics[specificTemplate.Key] += 1 : newSchematics[specificTemplate.Key] = 1
            handleCharacterChange({ Schematics: newSchematics })
        }
        if (!filteredResults[index].BaseMods && !(filteredResults[index].AddToSkill || filteredResults[index].AddToTalent || filteredResults[index].Schematic || filteredResults[index].RandomPositiveQuirk || filteredResults[index].RandomNegativeQuirk || filteredResults[index].PositiveQuirk || filteredResults[index].NegativeQuirk)) {
            newProfile.Abilities.push({ Name: filteredResults[index].Name, Description: filteredResults[index].Description })
        }
        newItem.Droid = newProfile
        setSelectedTemplate(newItem)

        let newOptions = [...selectedOptions]
        newOptions[index] = selectedOptions[index] ? selectedOptions[index] + 1 : 1
        setSelectedOptions(newOptions);
    };

    const handleOptionRemove = (index) => {
        let availableRefunds = {
            "AD": parseInt(spentResults["[AD]"] || 0),
            "TH": parseInt(spentResults["[TH]"] || 0),
            "TR": parseInt(spentResults["[TR]"] || 0),
            "DE": parseInt(spentResults["[DE]"] || 0),
            "LI": parseInt(spentResults["LI]"] || 0),
            "DA": parseInt(spentResults["[DA]"] || 0)
        }
        let newSpentResults = { ...spentResults }

        if (selectedOptions.reduce(function (s, v) { return s + (v || 0); }, 0) === 1) { newSpentResults = {} }
        else if (filteredResults[index].CostAdvThr && parseInt(filteredResults[index].CostAdvThr) > 0 && parseInt(filteredResults[index].CostAdvThr) <= availableRefunds.AD) {
            newSpentResults["[AD]"] -= parseInt(filteredResults[index].CostAdvThr)
        } else if (filteredResults[index].CostAdvThr && Math.abs(parseInt(filteredResults[index].CostAdvThr)) <= availableRefunds.TH && availableRefunds.TH > 0) {
            newSpentResults["[TH]"] += parseInt(filteredResults[index].CostAdvThr)
        } else if (parseInt(filteredResults[index].CostTriDes) > 0 && parseInt(filteredResults[index].CostTriDes) <= availableRefunds.TR) {
            newSpentResults["[TR]"] -= parseInt(filteredResults[index].CostTriDes)
        } else if (Math.abs(parseInt(filteredResults[index].CostTriDes)) <= availableRefunds.DE && availableRefunds.DE > 0) {
            newSpentResults["[DE]"] += parseInt(filteredResults[index].CostTriDes)
        } else if (parseInt(filteredResults[index].CostLightDark) > 0 && parseInt(filteredResults[index].CostLightDark) <= availableRefunds.LI) {
            newSpentResults["[LI]"] -= parseInt(filteredResults[index].CostLightDark)
        } else if (parseInt(filteredResults[index].CostLightDark) < 0 && Math.abs(parseInt(filteredResults[index].CostLightDark)) <= availableRefunds.DA && availableRefunds.DA > 0) {
            newSpentResults["[DA]"] += parseInt(filteredResults[index].CostLightDark)
        }
        setSpentResults(newSpentResults)

        let newItem = { ...selectedTemplate }
        let newProfile = newItem.Droid
        if (filteredResults[index].WoundChange) {
            newProfile.Attributes.WoundThreshold = parseInt(newProfile.Attributes.WoundThreshold) - parseInt(filteredResults[index].WoundChange)
        }
        if (filteredResults[index].SoakChange) {
            newProfile.Attributes.Soak = parseInt(newProfile.Attributes.Soak) - parseInt(filteredResults[index].SoakChange)
        }
        if (filteredResults[index].MDefChange) {
            newProfile.Attributes.DefenseMelee = parseInt(newProfile.Attributes.DefenseMelee) - parseInt(filteredResults[index].MDefChange)
        }
        if (filteredResults[index].RDefChange) {
            newProfile.Attributes.DefenseRanged = parseInt(newProfile.Attributes.DefenseRanged) - parseInt(filteredResults[index].RDefChange)
        }
        if (filteredResults[index].DuplicateItem) {
            newItem.Duplicate -= filteredResults[index].DuplicateItem
        }
        if (filteredResults[index].SilhouetteChange) {
            newProfile.Silhouette = parseInt(newProfile.Silhouette) - parseInt(filteredResults[index].SilhouetteChange)
        }
        if (filteredResults[index].RandomPositiveQuirk) {
            newProfile.Abilities = newProfile.Abilities.filter((a) => !(!a.IsNegative && a.IsRandom))
        }
        if (filteredResults[index].RandomNegativeQuirk) {
            newProfile.Abilities = newProfile.Abilities.filter((a) => !(a.IsNegative && a.IsRandom))
        }
        if (filteredResults[index].PositiveQuirk) {
            newProfile.Abilities = newProfile.Abilities.filter((a) => !(!a.IsNegative && a.IsQuirk))
        }
        if (filteredResults[index].NegativeQuirk) {
            newProfile.Abilities = newProfile.Abilities.filter((a) => !(a.IsNegative && !a.IsRandom))
        }
        if (filteredResults[index].AddToTalent) {
            setIncrementedTalents([])
        }
        if (filteredResults[index].AddToSkill) {
            setIncrementedSkills([])
        }
        if (filteredResults[index].InbuiltCybernetic) {
            delete newProfile.BaseMods
            setModularCyber("")
            if (modularCyber?.BaseMods) {
                let statMod
                let newAttributes
                [newAttributes, statMod] = modStatChange(newProfile.Attributes, modularCyber.BaseMods, true)
                if (statMod) { newProfile.Attributes = newAttributes } else if (newProfile.Characteristics[modularCyber.BaseMods.Key]) {
                    newProfile.Characteristics[modularCyber.BaseMods.Key] = parseInt(newProfile.Characteristics[modularCyber.BaseMods.Key]) - (modularCyber.BaseMods.Count || 1);
                }
            }
        }
        if (filteredResults[index].Unobtrusive) {
            newProfile.Talents.INDIS -= 1
            if (newProfile.Talents.INDIS === 0) {
                delete newProfile.Talents.INDIS
            }
        }
        if (filteredResults[index].Schematic) {
            let newSchematics = character.Schematics || {}
            newSchematics[specificTemplate.Key] -= 1
            handleCharacterChange({ Schematics: newSchematics })
        }
        if (!filteredResults[index].BaseMods && !(filteredResults[index].AddToSkill || filteredResults[index].AddToTalent || filteredResults[index].Schematic || filteredResults[index].RandomPositiveQuirk || filteredResults[index].RandomNegativeQuirk || filteredResults[index].PositiveQuirk || filteredResults[index].NegativeQuirk)) {
            newProfile.Abilities = newProfile.Abilities.filter((mod) => mod.Description !== filteredResults[index].Description)
        }
        newItem.Droid = newProfile
        setSelectedTemplate(newItem)

        let newOptions = [...selectedOptions]
        newOptions[index] = newOptions[index] - 1
        setSelectedOptions(newOptions);
    };


    const availableSymbol = (symbol) => {
        let available = 0
        available = parseInt(results[symbol] || 0) - parseInt(spentResults[symbol] || 0)
        return available
    }

    const adjustResults = (symbol, number) => {
        if (isNaN(number)) { return }
        let newResults = { ...results }
        newResults[symbol] = number
        setResults(newResults)
    }

    const diffString = (temp) => {
        let count = parseInt(temp.CheckDifficulty)
        count -= ((character.Schematics && character.Schematics[temp.Key]) ? character.Schematics[temp.Key] : 0)
        count = Math.max(count, 0)
        let string = ""
        string += "[DI]".repeat(count)
        if (string === "") {
            string = "(-)"
        }
        return string
    }

    const schematicString = (temp) => {
        let string = ""
        string += ((character.Schematics && character.Schematics[temp.Key] && character.Schematics[temp.Key] > 0) ? " with " + character.Schematics[temp.Key] + " schematics" : "")
        return string
    }

    const handleSupHard = (value) => {
        let newItem = { ...selectedTemplate }
        let newProfile = newItem.Droid

        newProfile.SuperiorHardware = value

        newItem.Droid = newProfile
        setSelectedTemplate(newItem)
    }

    const handleModularHardware = (item) => {
        let newModularTemp = { ...selectedTemplate }
        let modularProfile = newModularTemp.Droid
        if (modularCyber?.BaseMods) {
            let statMod
            let newAttributes
            [newAttributes, statMod] = modStatChange(modularProfile.Attributes, modularCyber.BaseMods, true)
            if (statMod) { modularProfile.Attributes = newAttributes } else if (modularProfile.Characteristics[modularCyber.BaseMods.Key]) {
                modularProfile.Characteristics[modularCyber.BaseMods.Key] = parseInt(modularProfile.Characteristics[modularCyber.BaseMods.Key]) - parseInt(modularCyber.BaseMods.Count || 1)
            }
        }
        if (item.BaseMods) {
            modularProfile.BaseMods = item.BaseMods
            let statMod
            let newAttributes
            [newAttributes, statMod] = modStatChange(modularProfile.Attributes, item.BaseMods)
            if (statMod) { modularProfile.Attributes = newAttributes } else if (modularProfile.Characteristics[item.BaseMods.Key]) {
                modularProfile.Characteristics[item.BaseMods.Key] = parseInt(modularProfile.Characteristics[item.BaseMods.Key]) + parseInt(item.BaseMods.Count || 1)
            }
        }
        newModularTemp.Droid = modularProfile
        setSelectedTemplate(newModularTemp)
        setModularCyber(item)
    }

    const handleSkillChoice = (skills) => {

        let newDirective = { ...specificTemplate }
        let newProfile = newDirective.DroidDirect
        if (!newProfile.Skills) { newProfile.Skills = [] }
        if (newProfile.Skills.length > 0) {
            for (let s in directiveSkills) {
                let skill = directiveSkills[s]
                newProfile.Skills = newProfile.Skills.filter((sk) => sk.Key !== skill.Key)
            }
        }
        for (let s in skills) {
            let skill = skills[s]
            if (!newProfile.Skills.find((s) => s.Key === skill.Key)) {
                newProfile.Skills.push({ Key: skill.Key, Rank: specificTemplate.DroidDirect.SkillChoiceRanks })
            }
        }
        setDirectiveSkills(skills)
        newDirective.DroidDirect = newProfile
        setSpecificTemplate(newDirective)
    }

    const handleAddSkill = (skill, key) => {
        let newSkills = { ...addedSkills }
        newSkills[key] = skill
        setAddedSkills(newSkills)
    }

    const handleIncrementSkill = (skill) => {
        setIncrementedSkills(skill)
    }
    const handleIncrementTalent = (talent) => {
        setIncrementedTalents(talent)
    }

    const handlePositiveQuirk = (quirk) => {
        setPositiveQuirk(quirk)
        let newTemplate = selectedTemplate
        let newProfile = selectedTemplate.Droid
        newProfile.Abilities = newProfile.Abilities.filter((a) => !(a.IsQuirk && !a.IsRandom))
        quirk.IsQuirk = true
        newProfile.Abilities.push(quirk)
        newTemplate.Droid = newProfile
        setSelectedTemplate(newTemplate)
        setResetKey(prevKey => prevKey + 1);
    }

    const handleNegativeQuirk = (quirk) => {
        setNegativeQuirk(quirk)
        let newTemplate = selectedTemplate
        let newProfile = selectedTemplate.Droid
        newProfile.Abilities = newProfile.Abilities.filter((a) => !(a.IsNegative && !a.IsRandom))
        newProfile.Abilities.push(quirk)
        newTemplate.Droid = newProfile
        setSelectedTemplate(newTemplate)
        setResetKey(prevKey => prevKey + 1);
    }

    return (
        specificTemplate.Key && <>
            <div
                dangerouslySetInnerHTML={{
                    __html: MarkedText.renderer(diffString(specificTemplate) + " " + (specificTemplate.TempType === "DroidDirect" ? "Computers" : "Mechanics") + " check" + schematicString(specificTemplate))
                }}
            />
            <Typography key={resetKey}>Material Cost: {specificTemplate.MaterialCost} Credits | Rarity: {specificTemplate.MaterialRarity} | Crafting Time: {specificTemplate.TimeHours} hours</Typography>
            <Grid container sx={{ paddingTop: "15px" }}>
                <Grid item sx={{ paddingLeft: "30px" }}>
                    <Typography sx={{ paddingBottom: "5px" }}>Results: </Typography>
                    <Typography>Available: </Typography>
                </Grid>
                <Grid item sx={{ paddingLeft: "5px" }}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: MarkedText.renderer(" [AD] ")
                        }}
                    />
                </Grid>
                <Grid item sx={{ paddingLeft: "5px" }}>
                    <TextField
                        key={resetKey}
                        type="text"
                        value={results["[AD]"]}
                        onChange={(event) => adjustResults("[AD]", event.target.value)}
                        inputProps={{
                            style: {
                                padding: '4px 8px',
                                width: '20px',
                                textAlign: 'center'
                            },
                            inputMode: 'numeric',
                            pattern: '[0-9]*'
                        }}
                        variant="outlined"
                        size="small"
                    />
                    <Typography sx={{ textAlign: 'center' }}>{(parseInt(results['[AD]']) || 0) - (parseInt(spentResults['[AD]']) || 0)}</Typography>
                </Grid>
                <Grid item sx={{ paddingLeft: "5px" }}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: MarkedText.renderer(" [TR] ")
                        }}
                    />
                </Grid>
                <Grid item sx={{ paddingLeft: "5px" }}>
                    <TextField
                        key={resetKey}
                        type="text"
                        value={results["[TR]"]}
                        onChange={(event) => adjustResults("[TR]", event.target.value)}
                        inputProps={{
                            style: {
                                padding: '4px 8px',
                                width: '20px',
                                textAlign: 'center'
                            },
                            inputMode: 'numeric',
                            pattern: '[0-9]*'
                        }}
                        variant="outlined"
                        size="small"
                    />
                    <Typography sx={{ textAlign: 'center' }}>{(parseInt(results['[TR]']) || 0) - (parseInt(spentResults['[TR]']) || 0)}</Typography>
                </Grid>
                <Grid item sx={{ paddingLeft: "5px" }}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: MarkedText.renderer(" [TH] ")
                        }}
                    />
                </Grid>
                <Grid item sx={{ paddingLeft: "5px" }}>
                    <TextField
                        key={resetKey}
                        type="text"
                        value={results["[TH]"]}
                        onChange={(event) => adjustResults("[TH]", event.target.value)}
                        inputProps={{
                            style: {
                                padding: '4px 8px',
                                width: '20px',
                                textAlign: 'center'
                            },
                            inputMode: 'numeric',
                            pattern: '[0-9]*'
                        }}
                        variant="outlined"
                        size="small"
                    />
                    <Typography sx={{ textAlign: 'center' }}>{(parseInt(results['[TH]']) || 0) - (parseInt(spentResults['[TH]']) || 0)}</Typography>
                </Grid>
                <Grid item sx={{ paddingLeft: "5px" }}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: MarkedText.renderer(" [DE] ")
                        }}
                    />
                </Grid>
                <Grid item sx={{ paddingLeft: "5px" }}>
                    <TextField
                        key={resetKey}
                        type="text"
                        value={results["[DE]"]}
                        onChange={(event) => adjustResults("[DE]", event.target.value)}
                        inputProps={{
                            style: {
                                padding: '4px 8px',
                                width: '20px',
                                textAlign: 'center'
                            },
                            inputMode: 'numeric',
                            pattern: '[0-9]*'
                        }}
                        variant="outlined"
                        size="small"
                    />
                    <Typography sx={{ textAlign: 'center' }}>{(parseInt(results['[DE]']) || 0) - (parseInt(spentResults['[DE]']) || 0)}</Typography>
                </Grid>
                {(() => {
                    const index = filteredResults.findIndex((o) => o.Key === "IMPDROIDSUPHARD");
                    return (
                        index !== -1 &&
                        selectedOptions[index] !== undefined &&
                        selectedOptions[index] > 0 && (
                            <Grid item>
                                <SuperiorHardwareSelector
                                    maxSelections={selectedOptions[index]}
                                    onSelectionsChange={(selections) => {
                                        handleSupHard(selections)
                                    }}
                                />
                            </Grid>
                        )
                    );
                })()}
                {(() => {
                    const index = filteredResults.findIndex((o) => o.Key === "IMPDROIDMODHARD");
                    return (
                        index !== -1 &&
                        selectedOptions[index] !== undefined &&
                        selectedOptions[index] > 0 && (
                            <Grid item>
                                <FormControl fullWidth sx={{ mt: 2, minWidth: "200px", paddingLeft: "20px", marginTop: "0px" }}>
                                    <InputLabel sx={{ paddingLeft: "15px" }}>Modular Hardware</InputLabel>
                                    <Select value={modularCyber} onChange={(e) => handleModularHardware(e.target.value)}>
                                        {modularHardwareOptions
                                            .map(item => (
                                                <MenuItem key={item.Key} value={item}>{item.Name}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        )
                    );
                })()}
                {specificTemplate.DroidDirect?.SkillChoices && <>
                    <Grid item>
                        <SkillsSelector
                            options={skills.filter((s) => s.type === specificTemplate.DroidDirect.SkillChoices.TypeValue)}
                            maxSelections={specificTemplate.DroidDirect.SkillChoices.SkillCount || 0}
                            onSelectionsChange={(selections) => {
                                handleSkillChoice(selections)
                            }}
                            directive={specificTemplate.Key}
                            setDirectiveSkills={setDirectiveSkills}
                        />
                    </Grid>
                </>}
                {(() => {
                    let skillAddSelects = []
                    for (let r in filteredResults) {
                        if (filteredResults[r].AddSkill && selectedOptions[r] !== undefined && selectedOptions[r] > 0) {
                            skillAddSelects.push(filteredResults[r])
                        }
                    }
                    return (
                        skillAddSelects.map((result, index) =>
                            <Grid item>
                                <FormControl fullWidth sx={{ mt: 2, minWidth: "200px", paddingLeft: "20px", marginTop: "0px" }}>
                                    <InputLabel sx={{ paddingLeft: "15px" }}>{result.Name}</InputLabel>
                                    <Select value={addedSkills[result.Key]} onChange={(e) => handleAddSkill(e.target.value, result.Key)}>
                                        {skills
                                            .filter((s) =>
                                                s.type === result.AddSkill.TypeValue &&
                                                !specificTemplate.DroidDirect.Skills?.some(skill => skill.Key === s.Key)
                                            )
                                            .map(item => (
                                                <MenuItem key={item.Key} value={item}>{item.skill}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        )
                    );
                })()}
                {(() => {
                    const index = filteredResults.findIndex((o) => o.Key === "IMPDROIDDIRPQUIRK");
                    return (
                        index !== -1 &&
                        selectedOptions[index] !== undefined &&
                        selectedOptions[index] > 0 && (
                            <Grid item>
                                <FormControl fullWidth sx={{ mt: 2, minWidth: "200px", paddingLeft: "20px", marginTop: "0px" }}>
                                    <InputLabel sx={{ paddingLeft: "15px" }}>Positive Quirk</InputLabel>
                                    <Select value={positiveQuirk} onChange={(e) => handlePositiveQuirk(e.target.value)}>
                                        {positiveQuirks.map(item => (
                                            <MenuItem key={item.Key} value={item}>{item.Name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )
                    );
                })()}
                {(() => {
                    const index = filteredResults.findIndex((o) => o.Key === "IMPDROIDDIRNQUIRK");
                    return (
                        index !== -1 &&
                        selectedOptions[index] !== undefined &&
                        selectedOptions[index] > 0 && (
                            <Grid item>
                                <FormControl fullWidth sx={{ mt: 2, minWidth: "200px", paddingLeft: "20px", marginTop: "0px" }}>
                                    <InputLabel sx={{ paddingLeft: "15px" }}>Negative Quirk</InputLabel>
                                    <Select value={negativeQuirk} onChange={(e) => handleNegativeQuirk(e.target.value)}>
                                        {negativeQuirks.map(item => (
                                            <MenuItem key={item.Key} value={item}>{item.Name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )
                    );
                })()}
                {(() => {
                    const index = filteredResults.findIndex((o) => o.Key === "IMPDROIDDIRADAPPROG");
                    return (
                        index !== -1 &&
                        selectedOptions[index] !== undefined &&
                        selectedOptions[index] > 0 && (
                            <Grid item>
                                <AdaptiveProgrammingSelector
                                    options={skills.filter((s) => getAllSkills()[skill.Key])}
                                    maxSelections={selectedOptions[index]}
                                    onSelectionsChange={handleIncrementSkill}
                                />
                            </Grid>
                        )
                    );
                })()}
                {(() => {
                    const index = filteredResults.findIndex((o) => o.Key === "IMPDROIDDIRBEHOPT");
                    return (
                        index !== -1 &&
                        selectedOptions[index] !== undefined &&
                        selectedOptions[index] > 0 && (
                            <Grid item>
                                <FormControl fullWidth sx={{ mt: 2, minWidth: "200px", paddingLeft: "20px", marginTop: "0px" }}>
                                    <InputLabel sx={{ paddingLeft: "15px" }}>Behavioral Optimization</InputLabel>
                                    <Select value={incrementedTalents} onChange={(e) => handleIncrementTalent(e.target.value)}>
                                        {talents.filter((t) => specificTemplate.DroidDirect.Talents?.some(talent => talent.Key === t.Key) || (selectedTemplate.Droid.Talents && selectedTemplate.Droid.Talents[t.Key]))
                                            .map(item => (
                                                <MenuItem key={item.Key} value={item}>{item.Name}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        )
                    );
                })()}
            </Grid>

            {Object.keys(results).length > 0 && < TableContainer
                component={Paper}
                style={{
                    maxHeight: "calc(100vh - 40.5vh)",
                    overflowY: "auto",
                }}
            >
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow sx={{ padding: "1px" }}>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell sx={{ textAlign: "center" }}>Cost</TableCell>
                            <TableCell sx={{ textAlign: "center" }}>Count</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredResults && filteredResults.map((option, index) => (
                            (selectedOptions[index] > 0 ||
                                (parseInt(option.CostAdvThr) > 0 && parseInt(option.CostAdvThr) <= parseInt(results['[AD]']) - (spentResults['[AD]'] || 0)) ||
                                (parseInt(option.CostAdvThr) < 0 && Math.abs(parseInt(option.CostAdvThr)) <= results['[TH]'] - (spentResults['[TH]'] || 0)) ||
                                (parseInt(option.CostTriDes) > 0 && parseInt(option.CostTriDes) <= parseInt(results['[TR]']) - (spentResults['[TR]'] || 0)) ||
                                (parseInt(option.CostTriDes) < 0 && Math.abs(parseInt(option.CostTriDes)) <= parseInt(results['[DE]']) - (spentResults['[DE]'] || 0)))
                            && <TableRow key={option.Key}>
                                <TableCell>{option.Name}</TableCell>
                                <TableCell>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: MarkedText.renderer(option.Description)
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                    {option.CostAdvThr !== "0" && <Button
                                        size="small"
                                        sx={{ minWidth: "30px" }}
                                        onClick={() => handleOptionAdd(index, parseInt(option.CostAdvThr), "AdvThr")}
                                        disabled={
                                            (option.SelectCount && parseInt(option.SelectCount) === selectedOptions[index]) ||
                                            (option.HandlingChange && (parseInt(option.HandlingMax) <= parseInt(selectedTemplate.Droid.Handling))) ||
                                            (parseInt(option.CostAdvThr) > 0 && availableSymbol('[AD]') < parseInt(option.CostAdvThr)) ||
                                            (parseInt(option.CostAdvThr) < 0 && availableSymbol('[TH]') < Math.abs(parseInt(option.CostAdvThr)))
                                        }>

                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html: MarkedText.renderer(getCostString(option.CostAdvThr, 0, 0)
                                                )
                                            }}
                                        />
                                    </Button>}
                                    <Button
                                        size="small"
                                        sx={{ minWidth: "30px" }}
                                        onClick={() => handleOptionAdd(index, parseInt(option.CostTriDes), "TriDes")}
                                        disabled={
                                            (option.SelectCount && parseInt(option.SelectCount) === selectedOptions[index]) ||
                                            (option.HandlingChange && (parseInt(option.HandlingMax) <= parseInt(selectedTemplate.Droid.Handling))) ||
                                            (parseInt(option.CostTriDes) > 0 && availableSymbol('[TR]') < parseInt(option.CostTriDes)) ||
                                            (parseInt(option.CostTriDes) < 0 && availableSymbol('[DE]') < Math.abs(parseInt(option.CostTriDes)))

                                        }>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html: MarkedText.renderer(getCostString(0, option.CostTriDes, 0)
                                                )
                                            }}
                                        />
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Button size="small" sx={{ minWidth: "30px" }} onClick={() => handleOptionRemove(index)} disabled={!selectedOptions[index] > 0}>-</Button>
                                    {selectedOptions[index] || 0}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer >}
        </>
    )
}

const DroidCraftingModal = ({ open, handleClose, craftingTemplates, craftingResultTables, character, handleCharacterChange, setOwnedGear, talents, skills, gear }) => {
    const [chassis, setChassis] = useState('');
    const [baseChassis, setBaseChassis] = useState('');
    const [baseDirective, setBaseDirective] = useState('');
    const [directive, setDirective] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [resetKey, setResetKey] = useState(0);
    const [modularCyber, setModularCyber] = useState("")
    const [addedSkills, setAddedSkills] = useState({})
    const [incrementedSkills, setIncrementedSkills] = useState([])
    const [incrementedTalents, setIncrementedTalents] = useState([])

    const chassisList = craftingTemplates.filter(temp => temp.TempType === "Droid");
    const directives = craftingTemplates.filter(temp => temp.TempType === "DroidDirect");

    const chassisResults = craftingResultTables.filter(r => r.TempType === "Droid");
    const directiveResults = craftingResultTables.filter(r => r.TempType === "DroidDirect" && ((r.NoMinion ? "Rival Nemesis" : "Minion Rival Nemesis").includes(chassis.Droid?.Type)));

    const modularHardwareOptions = gear.filter((g) => g.Type === "Cybernetics" && g.Key !== "BIOFEEDREG").sort((a, b) => { return a.Name.localeCompare(b.Name); })

    const handleChassisChange = (event) => {
        setChassis(JSON.parse(JSON.stringify(event.target.value)))
        setBaseChassis(event.target.value);
        setName(event.target.value.Name)

        setDirective("")
        setBaseDirective("")

        setResetKey(prevKey => prevKey + 1);
    };

    const handleDirectiveChange = (event) => {
        let newDirective = event.target.value
        setDirective(JSON.parse(JSON.stringify(newDirective)));
        setBaseDirective(newDirective)

        if (name === chassis.Name) { setName(chassis.Name + " with " + newDirective.Name) }
        let newChassis = { ...chassis }
        if (directive.DroidDirect?.MakeNemesis) {
            newChassis.Droid.Type = baseChassis.Droid.Type
            if (baseChassis.Droid.Type !== "Nemesis") {
                newChassis.Droid.Attributes.StrainThreshold = ""
            }
        }
        if (newDirective.DroidDirect.MakeNemesis) {
            newChassis.Droid.Type = "Nemesis"
            if (baseChassis.Droid.Type !== "Nemesis") {
                newChassis.Droid.Attributes.StrainThreshold = newChassis.Droid.Attributes.WoundThreshold
            }
        }
        setChassis(newChassis)
        setResetKey(prevKey => prevKey + 1);
    };

    const handleSubmit = () => {
        let profile = { ...chassis.Droid }

        profile.Source = "Crafted Droid, see Special Modifications page 80"

        profile.Skills = chassis.Droid.Type === "Minion" ? Object.entries(getAllSkills(true)).map(([Key]) => (Key)) : getAllSkills(true)
        profile.Talents = (profile.Talents || []).concat(directive.DroidDirect.Talents || [])
        profile.AdversaryType = profile.Type

        if (modularCyber) { profile.Cyber = modularCyber }
        let object = { Name: name, Description: description, Profile: profile }
        object.Type = "Droids"

        if (chassis.Duplicate) {
            for (let i = 0; i < chassis.Duplicate; i++) {
                let guid = `${'craftedItem'}_${Date.now()}_${i}`
                let newItem = JSON.parse(JSON.stringify(object)); // Deep copy
                newItem.Key = guid
                newItem.inventoryID = guid
                newCategory.push(newItem)
            }
        }

        const UID = `${'craftedItem'}_${Date.now()}`;
        object.inventoryID = UID
        let newCategory = [...character.Gear]
        newCategory.push(object)
        let totalCost = parseInt(chassis.MaterialCost) + parseInt(directive.MaterialCost)

        handleCharacterChange({
            Gear: newCategory,
            Credits: character.Credits - totalCost
        });
        setOwnedGear(newCategory)

        setChassis('');
        setBaseChassis('')
        setName('')
        setDescription('');
        setDirective('')
        setBaseDirective('')
        setModularCyber('')
        setAddedSkills({})
        setIncrementedSkills([])
        setIncrementedTalents([])
        handleClose();
    };

    const getCostString = (costA, costB) => {
        let costString = ""
        if (parseInt(costA) > 0 || parseInt(costB) > 0) {
            costString += "[AD]".repeat(parseInt(costA))
            costString += "[TR]".repeat(parseInt(costB))

        }
        if (parseInt(costA) < 0 || parseInt(costB) < 0) {
            costString += "[TH]".repeat(Math.abs(parseInt(costA)))
            costString += "[DE]".repeat(Math.abs(parseInt(costB)))
        }
        return costString
    }


    const getSkill = (key) => {
        return skills.find((s) => s.Key === key)
    }

    const getAllSkills = (names) => {
        let allSkills = {}
        const addSkill = (key, count) => {
            let Key = key
            if (names) { Key = getSkill(key).skill }
            allSkills[Key] ? allSkills[Key] = parseInt(allSkills[Key]) + parseInt(count) : allSkills[Key] = count
        }
        if (chassis.Droid?.BaseMods) {
            if (Array.isArray(chassis.Droid.BaseMods)) {
                for (let m in chassis.Droid.BaseMods) {
                    let mod = chassis.Droid.BaseMods[m]
                    if (mod.Key && skills.find((s) => s.Key === mod.Key)) {
                        addSkill(mod.Key, mod.Count || 1)
                    }
                }
            } else {
                if (chassis.Droid.BaseMods.Key && skills.find((s) => s.Key === chassis.Droid.BaseMods.Key)) {
                    addSkill(chassis.Droid.BaseMods.Key, chassis.Droid.BaseMods.Count || 1)
                }
            }
        }
        if (directive.DroidDirect?.Skills) {
            for (let s in directive.DroidDirect.Skills) {
                let skill = directive.DroidDirect.Skills[s]
                addSkill(skill.Key, skill.Rank)
            }
        }
        Object.values(addedSkills).forEach(skill => {
            if (skill && skill.Key) {
                addSkill(skill.Key, 1);
            }
        });
        incrementedSkills?.forEach(skill => {
            allSkills[skill] = parseInt(allSkills[skill]) + 1
        });
        return allSkills
    }

    const getTalent = (key) => {
        return talents.find((t) => t.Key === key)
    }

    return (
        <Modal open={open} onClose={handleClose}
            sx={{
                overflow: "auto"
            }}>
            <Box sx={{
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4
            }}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h6" component="h2">
                            Craft Droid
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button onClick={() => handleClose()}>🞫</Button>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel>Chassis</InputLabel>
                            <Select value={baseChassis} onChange={handleChassisChange}>
                                {chassisList.map(c => (
                                    <MenuItem key={c.Key} value={c}>{c.Name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {chassis &&
                            <ResultsTable
                                filteredResults={chassisResults}
                                getCostString={getCostString}
                                selectedTemplate={chassis}
                                setSelectedTemplate={setChassis}
                                character={character}
                                handleCharacterChange={handleCharacterChange}
                                specificTemplate={chassis}
                                resetKey={resetKey}
                                setSpecificTemplate={setChassis}
                                setResetKey={setResetKey}
                                modularHardwareOptions={modularHardwareOptions}
                                modularCyber={modularCyber}
                                setModularCyber={setModularCyber}
                                skills={skills}
                                talents={talents}
                                addedSkills={addedSkills}
                                setAddedSkills={setAddedSkills}
                                setIncrementedSkills={setIncrementedSkills}
                                incrementedTalents={incrementedTalents}
                                setIncrementedTalents={setIncrementedTalents}
                                getAllSkills={getAllSkills}
                            />
                        }
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel>Directive</InputLabel>
                            <Select value={baseDirective} onChange={handleDirectiveChange} disabled={!chassis}>
                                {directives.map(d => (
                                    <MenuItem key={d.Key} value={d}>{d.Name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {directive &&
                            <ResultsTable
                                filteredResults={directiveResults}
                                getCostString={getCostString}
                                selectedTemplate={chassis}
                                setSelectedTemplate={setChassis}
                                character={character}
                                handleCharacterChange={handleCharacterChange}
                                specificTemplate={directive}
                                setSpecificTemplate={setDirective}
                                resetKey={resetKey}
                                setResetKey={setResetKey}
                                modularHardwareOptions={modularHardwareOptions}
                                modularCyber={modularCyber}
                                setModularCyber={setModularCyber}
                                skills={skills}
                                talents={talents}
                                addedSkills={addedSkills}
                                setAddedSkills={setAddedSkills}
                                setIncrementedSkills={setIncrementedSkills}
                                incrementedTalents={incrementedTalents}
                                setIncrementedTalents={setIncrementedTalents}
                                getAllSkills={getAllSkills}
                            />
                        }
                    </Grid>
                </Grid>
                {chassis && <Grid container>
                    <Grid item xs={12} sm={9}>
                        <TextField
                            fullWidth
                            label="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            sx={{ mt: 2 }}
                        />
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            label="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            sx={{ mt: 2 }}
                        />
                        <Button onClick={handleSubmit} sx={{ mt: 2 }} disabled={!chassis || !directive}>
                            Craft Droid
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        {chassis.Droid && <div style={{ padding: "15px", textAlign: "center" }}>
                            <Typography variant="h6">{chassis.Droid.Type}</Typography>
                            <Grid container sx={{ textAlign: "center" }}>
                                <Grid item xs={12} sm={2}>
                                    <Typography variant="h6">Brawn</Typography>
                                    <Typography variant="h5">
                                        {parseInt(chassis.Droid.Characteristics.BR) + (chassis.Droid.SuperiorHardware?.filter((a) => a === "BR").length || 0)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <Typography variant="h6">Agility</Typography>
                                    <Typography variant="h5">
                                        {parseInt(chassis.Droid.Characteristics.AG) + (chassis.Droid.SuperiorHardware?.filter((a) => a === "AG").length || 0)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <Typography variant="h6">Intellect</Typography>

                                    <Typography variant="h5">
                                        {parseInt(chassis.Droid.Characteristics.INT) + (chassis.Droid.SuperiorHardware?.filter((a) => a === "INT").length || 0)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <Typography variant="h6">Cunning</Typography>

                                    <Typography variant="h5">
                                        {parseInt(chassis.Droid.Characteristics.CUN) + (chassis.Droid.SuperiorHardware?.filter((a) => a === "CUN").length || 0)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <Typography variant="h6">Willpower</Typography>
                                    <Typography variant="h5">
                                        {parseInt(chassis.Droid.Characteristics.WIL) + (chassis.Droid.SuperiorHardware?.filter((a) => a === "WIL").length || 0)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <Typography variant="h6">Presence</Typography>

                                    <Typography variant="h5">
                                        {parseInt(chassis.Droid.Characteristics.PR) + (chassis.Droid.SuperiorHardware?.filter((a) => a === "PR").length || 0)}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Paper variant="outlined">
                                <Grid container sx={{ textAlign: "center", padding: "8px", display: "flex", alignItems: "center", minHeight: "100%" }}>
                                    <Grid item xs={12} sm={3}>
                                        <Typography variant="h8">Wound Threshold</Typography>
                                        <Typography variant="h5" sx={{ paddingTop: "5px" }}>{chassis.Droid.Attributes.WoundThreshold || "-"}</Typography>
                                    </Grid>
                                    {chassis.Droid.Attributes.StrainThreshold && <Grid item xs={12} sm={3}>
                                        <Typography variant="h8">Strain Threshold</Typography>
                                        <Typography variant="h5" sx={{ paddingTop: "5px" }}>{chassis.Droid.Attributes.StrainThreshold}</Typography>
                                    </Grid>}
                                    <Grid item xs={12} sm={chassis.Droid.Attributes.StrainThreshold ? 6 : 9}>
                                        <Grid container sx={{ textAlign: "center", display: "flex", alignItems: "center", minHeight: "100%" }}>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="h8">Soak</Typography>
                                                <Typography variant="h5">{chassis.Droid.Attributes.Soak}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="h8">Defense</Typography>
                                                <Grid container>
                                                    <Grid item xs={12} sm={6}>
                                                        Ranged
                                                        <Typography variant="h5">{chassis.Droid.Attributes.DefenseRanged || 0}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        Melee
                                                        <Typography variant="h5">{chassis.Droid.Attributes.DefenseMelee || 0}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                            <div style={{ textAlign: "left" }}>
                                {directive.DroidDirect?.Skills &&
                                    <Typography variant="h6" sx={{ paddintTop: "10px" }}>Skills:</Typography>}
                                {directive.DroidDirect?.Skills && <div>
                                    {Object.entries(getAllSkills()).map((skill, index) => (
                                        <React.Fragment key={skill.Key}>
                                            <Typography variant="h7">
                                                {getSkill(skill[0]).skill}
                                                {chassis.Droid.Type !== "Minion" ? ` ${skill[1]}` : ""}
                                                {index < Object.entries(getAllSkills()).map(([Key]) => (Key)).length - 1 ? ", " : ""}
                                            </Typography>
                                        </React.Fragment>
                                    ))}
                                </div>}
                                {(chassis.Droid.Talents || directive.DroidDirect?.Talents) && <Typography variant="h6" sx={{ paddintTop: "10px" }}>Talents:</Typography>}
                                {chassis.Droid.Talents && <div>
                                    {Object.entries(chassis.Droid.Talents).map(([talentKey, talentValue]) => (
                                        <React.Fragment key={talentKey}>
                                            <Typography variant="h7">
                                                {getTalent(talentKey).Name} {parseInt(talentValue) + (incrementedTalents.Key === talentKey ? 1 : 0)}
                                            </Typography>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: MarkedText.renderer(getTalent(talentKey).Description)
                                                }}
                                            />
                                        </React.Fragment>
                                    ))}
                                </div>}
                                {directive.DroidDirect?.Talents && <div>
                                    {directive.DroidDirect.Talents.map((talent, index) => (
                                        <React.Fragment key={talent.Key}>
                                            <Typography variant="h7">
                                                {getTalent(talent.Key).Name} {parseInt(talent.Ranks) + (incrementedTalents.Key === talent.Key ? 1 : 0)}
                                            </Typography>
                                            <div
                                                key={index}
                                                dangerouslySetInnerHTML={{
                                                    __html: MarkedText.renderer(getTalent(talent.Key).Description)
                                                }}
                                            />
                                        </React.Fragment>
                                    ))}
                                </div>}

                                {(chassis.Droid?.Abilities) && (
                                    <>
                                        <Typography variant="h6" sx={{ paddintTop: "10px" }}>Abilities:</Typography>
                                        <div>
                                            {chassis.Droid.Abilities?.map((mod, index) => (
                                                (
                                                    mod.Description && <div
                                                        key={index}
                                                        dangerouslySetInnerHTML={{
                                                            __html: MarkedText.renderer(mod.Name + ": " + mod.Description)
                                                        }}
                                                    />
                                                )
                                            ))}
                                        </div>
                                    </>
                                )}

                                {modularCyber && <>
                                    <Typography variant="h6" sx={{ paddintTop: "10px" }}>{modularCyber.Name}</Typography>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: MarkedText.renderer(modularCyber.Description)
                                        }}
                                    />
                                </>}
                                {chassis.Droid?.BaseMods && Array.isArray(chassis.Droid.BaseMods) && (
                                    <div>
                                        {chassis.Droid.BaseMods.map((mod, index) => (
                                            (
                                                mod.MiscDesc && <div
                                                    key={index}
                                                    dangerouslySetInnerHTML={{
                                                        __html: MarkedText.renderer(mod.MiscDesc)
                                                    }}
                                                />
                                            )
                                        ))}
                                    </div>
                                )}
                                {chassis.Droid?.BaseMods && chassis.Droid.BaseMods.MiscDesc &&
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: MarkedText.renderer(chassis.Droid.BaseMods.MiscDesc)
                                        }} />}

                                {(chassis.Droid.Silhouette === 0 || chassis.Droid.Silhouette === 2) && <Typography>Silhouette: {chassis.Droid.Silhouette}</Typography>}
                            </div>
                        </div>}
                    </Grid>
                </Grid >}
            </Box>
        </Modal >
    );
};

export default DroidCraftingModal;