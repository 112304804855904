import qualitiesData from "../data/qualities.json"

const qualitiesString = (weapon, VehicleWeapon) => {
    const qualitiesMap = new Map();

    const addQualities = (qualities) => {
        if (!qualities) return;

        const qualityArray = Array.isArray(qualities) ? qualities : [qualities];
        qualityArray.forEach(quality => {
            if (quality.Key) {
                qualitiesMap.set(quality.Key, quality);
            }
        });
    };

    addQualities(weapon);

    if (VehicleWeapon && VehicleWeapon.Qualities) {
        addQualities(VehicleWeapon.Qualities);
    } else {
        addQualities(VehicleWeapon)
    }

    let string = "Qualities: ";

    qualitiesMap.forEach((quality, key) => {
        const qualityData = qualitiesData.find(q => q.Key === key);
        if (qualityData && qualityData.ModDesc) {
            string += qualityData.ModDesc.replace(' {0}', '');
            if (quality.Count) {
                string += ' ' + quality.Count;
            }
            string += ", ";
        }
    });

    string = string.replace(/, $/, '');
    if (string === "Qualities: ") {
        return ""
    }
    return string;
};

export default qualitiesString