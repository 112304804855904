import { Paper, Typography } from "@mui/material";

const StatForm = ({ title, value, color, sizes }) => {
    if (!sizes) { sizes = ["h7", "h7"] }

    switch (color) {
        case "red":
            color = "#670d0d"
            break;
        case "blue":
            color = "#021c61"
            break;
        case "green":
            color = "#005700"
            break;
    }

    return (
        <Paper sx={{ margin: value ? "10px" : "0px", textAlign: "center" }} variant="outlined">
            <Paper sx={{ background: color }}>
                <Typography variant={sizes[0]} color="white">{title}</Typography>
            </Paper>
            {value && <Typography variant={sizes[1]}>
                {value}
            </Typography>}
        </Paper>
    )
}

export default StatForm