
let personalRanges = ["Engaged", "Short", "Medium", "Long", "Extreme"]
let planetaryRanges = ["Close", "Short", "Medium", "Long", "Extreme"]

const modStatChange = (item, mod, remove) => {
    let moddedItem = JSON.parse(JSON.stringify(item))
    let statChange = false

    if (mod.Key === "DAMADD" || mod.Key === "DAMADDCRYS") {
        if (remove) {
            moddedItem.Damage -= (1 * (mod.Count || 1))
        }
        else {
            moddedItem.Damage = parseInt(moddedItem.Damage) + (1 * (parseInt(mod.Count) || 1))
        }
        statChange = true
    }
    if (mod.Key === "DAMSUB" || mod.Key === "DAMSUBCRYS") {
        if (remove) {
            moddedItem.Damage += (1 * (mod.Count || 1))
        }
        else {
            moddedItem.Damage -= (1 * (mod.Count || 1))
            if (moddedItem.Damage < 1) { moddedItem.Damage = 1 }
        }
        statChange = true
    }
    if (mod.Key === "DAMSET") {
        if (remove) {
            moddedItem.Damage = "0"
        }
        else {
            moddedItem.Damage = mod.Count
        }
        statChange = true
    }
    if (mod.Key === "CRITSUB") {
        if (remove) {
            moddedItem.Crit += (1 * (mod.Count || 1))
        }
        else {
            moddedItem.Crit -= (1 * (mod.Count || 1))
            if (moddedItem.Crit < 1) { moddedItem.Crit = 1 }
        }
        statChange = true
    }
    if (mod.Key === "CRITSET") {
        if (remove) {
            moddedItem.Crit = undefined
        }
        else {
            moddedItem.Crit = mod.Count
        }
        statChange = true
    }
    if (mod.Key === "SUBQUALINACCURATE") {
        if (remove) {
            let inac = moddedItem.Qualities.find((q) => q.Key === "INACCURATE")
            inac.Count += 1
            if (!inac) {
                moddedItem.Qualities.push({ Key: "INACCURATE", Count: 1 })
            }
        }
        else {
            let inac = moddedItem.Qualities.find((q) => q.Key === "INACCURATE")
            inac.Count -= 1
            if (inac.Count === 0) {
                moddedItem.Qualities = moddedItem.Qualities.filter((q) => q.Key !== "INACCURATE")
            }
        }
        statChange = true
    }
    if (mod.Key === "ENCSUB") {
        if (remove) {
            moddedItem.Encumbrance += (1 * (mod.Count || 1))
        }
        else {
            moddedItem.Encumbrance -= (1 * (mod.Count || 1))
            if (moddedItem.Encumbrance < 0) { moddedItem.Encumbrance = 0 }
        }
        statChange = true
    }
    if (mod.Key === "ENCSUB2") {
        if (remove) {
            moddedItem.Encumbrance += (2 * (mod.Count || 1))
        }
        else {
            moddedItem.Encumbrance -= (2 * (mod.Count || 1))
            if (moddedItem.Encumbrance < 0) { moddedItem.Encumbrance = 0 }
        }
        statChange = true
    }
    if (mod.Key === "ENCADD") {
        if (remove) {
            moddedItem.Encumbrance -= (1 * (mod.Count || 1))
        }
        else {
            moddedItem.Encumbrance += (1 * (mod.Count || 1))
        }
        statChange = true
    }
    if (mod.Key === "HPADD") {
        if (remove) {
            moddedItem.HP -= (1 * (mod.Count || 1))
        }
        else {
            moddedItem.HP += (1 * (mod.Count || 1))
        }
        statChange = true
    }
    if (mod.Key === "HPSUB") {
        if (remove) {
            moddedItem.HP += (1 * (mod.Count || 1))
        }
        else {
            moddedItem.HP -= (1 * (mod.Count || 1))
            if (moddedItem.HP < 0) { moddedItem.HP = 0 }
        }
        statChange = true
    }
    if (mod.Key === "RANGEADD") {
        if (remove) {
            moddedItem.Range = personalRanges[parseInt(personalRanges.indexOf(item.Range)) - 1]
        }
        else {
            moddedItem.Range = personalRanges[Math.min(parseInt(personalRanges.indexOf(item.Range)) + 1, 4)]
        }
        statChange = true
    }
    if (mod.Key === "RANGESUB") {
        if (remove) {
            moddedItem.Range = personalRanges[parseInt(personalRanges.indexOf(item.Range)) + 1]
        }
        else {
            moddedItem.Range = personalRanges[Math.max(parseInt(personalRanges.indexOf(item.Range)) - 1, 0)]
        }
        statChange = true
    }
    if (mod.Key === "RANGEREDMED") {
        if (remove) {
            moddedItem.Range = personalRanges[parseInt(personalRanges.indexOf(item.Range)) + 1]
        }
        else {
            if (personalRanges[parseInt(personalRanges.indexOf(item.Range))] >= 2) {
                moddedItem.Range = "Medium"
            }
        }
        statChange = true
    }
    if (mod.Key === "SOAKADD") {
        if (remove) {
            moddedItem.Soak -= (1 * (mod.Count || 1))
        }
        else {
            moddedItem.Soak = parseInt(moddedItem.Soak) + (1 * (mod.Count || 1))
        }
        statChange = true
    }
    if (mod.Key === "SOAKSET") {
        if (remove) {
            moddedItem.Soak = 0
        }
        else {
            moddedItem.Soak = mod.Count
        }
        statChange = true
    }
    if (mod.Key === "DEFADD") {
        if (remove) {
            moddedItem.Defense -= (1 * (mod.Count || 1))
        }
        else {
            moddedItem.Defense += (1 * (mod.Count || 1))
        }
        statChange = true
    }
    if (mod.Key === "DEFSET") {
        if (remove) {
            moddedItem.Defense = 0
        }
        else {
            moddedItem.Defense = mod.Count
        }
        statChange = true
    }
    if (mod.Key === "MELEEDEFADD") {
        if (remove) {
            moddedItem.MeleeDefense -= (1 * (mod.Count || 1))
        }
        else {
            moddedItem.MeleeDefense = (moddedItem.MeleeDefense || 0) + (1 * (mod.Count || 1))
        }
        statChange = true
    }
    if (mod.Key === "RANGEDEFADD") {
        if (remove) {
            moddedItem.RangedDefense -= (1 * (mod.Count || 1))
        }
        else {
            moddedItem.RangedDefense = (moddedItem.RangedDefense || 0) + (1 * (mod.Count || 1))
        }
        statChange = true
    }
    if (mod.Key === "USERANGLT") {
        if (remove) {
            moddedItem.SkillKey = "RANGHVY"
        }
        else {
            moddedItem.SkillKey = "RANGLT"
        }
        statChange = true
    }
    if (mod.Key === "USERANGHVY") {
        if (remove) {
            moddedItem.SkillKey = "RANGLT"
        }
        else {
            moddedItem.SkillKey = "RANGHVY"
        }
        statChange = true
    }
    if (mod.Key === "SUPERIOR") {
        if (remove) {
            moddedItem.Damage -= 1
        }
        else {
            moddedItem.Damage += 1
        }
    }
    if (mod.Key === "INFERIOR") {
        if (remove) {
            moddedItem.Damage += 1
        }
        else {
            moddedItem.Damage -= 1
        }
    }
    if (mod.Key === "SPEEDADD") {
        if (remove) {
            moddedItem.Speed = parseInt(moddedItem.Speed) - mod.Count
        }
        else {
            moddedItem.Speed ? moddedItem.Speed = parseInt(moddedItem.Speed) + parseInt(mod.Count) : moddedItem.Speed = parseInt(mod.Count)
        }
        statChange = true
    }
    if (mod.Key === "SSTRAINADD") {
        if (remove) {
            moddedItem.SystemStrain = parseInt(moddedItem.SystemStrain) - mod.Count
        }
        else {
            moddedItem.SystemStrain ? moddedItem.SystemStrain = parseInt(moddedItem.SystemStrain) + parseInt(mod.Count) : moddedItem.SystemStrain = parseInt(mod.Count)
        }
        statChange = true
    }
    if (mod.Key === "SSTRAINADDSIL") {
        if (remove) {
            moddedItem.SystemStrain -= (parseInt(mod.Count) * moddedItem.Silhouette)
        }
        else {
            moddedItem.SystemStrain ? moddedItem.SystemStrain = parseInt(moddedItem.SystemStrain) + (parseInt(mod.Count) * moddedItem.Silhouette) : moddedItem.SystemStrain = (parseInt(mod.Count) * moddedItem.Silhouette)
        }
        statChange = true
    }
    if (mod.Key === "SSTRAINSUB") {
        if (remove) {
            moddedItem.SystemStrain = parseInt(moddedItem.SystemStrain) + parseInt(mod.Count)
        }
        else {
            moddedItem.SystemStrain ? moddedItem.SystemStrain = parseInt(moddedItem.SystemStrain) - parseInt(mod.Count) : moddedItem.SystemStrain = -parseInt(mod.Count)
        }
        statChange = true
    }
    if (mod.Key === "HANDLINGADD") {
        if (remove) {
            moddedItem.Handling = parseInt(moddedItem.Handling) - parseInt(mod.Count)
        }
        else {
            moddedItem.Handling ? moddedItem.Handling = parseInt(moddedItem.Handling) + parseInt(mod.Count) : moddedItem.Handling = parseInt(mod.Count)
        }
        statChange = true
    }
    if (mod.Key === "HANDLINGSUB") {
        if (remove) {
            moddedItem.Handling = parseInt(moddedItem.Handling) + parseInt(mod.Count)
        }
        else {
            moddedItem.Handling ? moddedItem.Handling = parseInt(moddedItem.Handling) - parseInt(mod.Count) : moddedItem.Handling = -parseInt(mod.Count)
        }
        statChange = true
    }
    if (mod.Key === "ARMORADD") {
        if (remove) {
            moddedItem.Armor = parseInt(moddedItem.Armor) - mod.Count
        }
        else {
            moddedItem.Armor ? moddedItem.Armor = parseInt(moddedItem.Armor) + parseInt(mod.Count) : moddedItem.Armor = parseInt(mod.Count)
        }
        statChange = true
    }
    if (mod.Key === "ENCCADD") {
        if (remove) {
            moddedItem.EncumbranceCapacity = parseInt(moddedItem.EncumbranceCapacity) - mod.Count
        }
        else {
            moddedItem.EncumbranceCapacity ? moddedItem.EncumbranceCapacity = parseInt(moddedItem.EncumbranceCapacity) + parseInt(mod.Count) : moddedItem.EncumbranceCapacity = parseInt(mod.Count)
        }
        statChange = true
    }
    if (mod.Key === "ENCTADD3") {
        if (remove) {
            moddedItem.EncumbranceCapacity = parseInt(moddedItem.EncumbranceCapacity) - (mod.Count * 3)
        }
        else {
            moddedItem.EncumbranceCapacity ? moddedItem.EncumbranceCapacity = parseInt(moddedItem.EncumbranceCapacity) + parseInt(mod.Count * 3) : moddedItem.EncumbranceCapacity = parseInt(mod.Count * 3)
        }
        statChange = true
    }
    if (mod.Key === "ENCADDSIL") {
        if (remove) {
            moddedItem.EncumbranceCapacity -= (parseInt(mod.Count) * moddedItem.Silhouette)
        }
        else {
            moddedItem.EncumbranceCapacity ? moddedItem.EncumbranceCapacity = parseInt(moddedItem.EncumbranceCapacity) + (parseInt(mod.Count) * moddedItem.Silhouette) : moddedItem.EncumbranceCapacity = (parseInt(mod.Count) * moddedItem.Silhouette)
        }
        statChange = true
    }
    if (mod.Key === "PASSADDSIL") {
        if (remove) {
            moddedItem.Passengers -= (parseInt(mod.Count) * moddedItem.Silhouette)
        }
        else {
            moddedItem.Passengers ? moddedItem.Passengers = parseInt(moddedItem.Passengers) + (parseInt(mod.Count) * moddedItem.Silhouette) : moddedItem.Passengers = (parseInt(mod.Count) * moddedItem.Silhouette)
        }
        statChange = true
    }
    if (mod.Key === "PASSADD2") {
        if (remove) {
            moddedItem.Passengers -= 2
        }
        else {
            moddedItem.Passengers ? moddedItem.Passengers = (parseInt(moddedItem.Passengers) + 2) : moddedItem.Passengers = 2
        }
        statChange = true
    }
    if (mod.Key === "HULLADD") {
        if (remove) {
            moddedItem.HullTrauma -= (parseInt(mod.Count))
        }
        else {
            moddedItem.HullTrauma ? moddedItem.HullTrauma = parseInt(moddedItem.HullTrauma) + (parseInt(mod.Count)) : moddedItem.HullTrauma = (parseInt(mod.Count))
        }
        statChange = true
    }
    if (mod.Key === "HULLADDSIL") {
        if (remove) {
            moddedItem.HullTrauma -= (parseInt(mod.Count) * moddedItem.Silhouette)
        }
        else {
            moddedItem.HullTrauma ? moddedItem.HullTrauma = parseInt(moddedItem.HullTrauma) + (parseInt(mod.Count) * moddedItem.Silhouette) : moddedItem.HullTrauma = (parseInt(mod.Count) * moddedItem.Silhouette)
        }
        statChange = true
    }
    if (mod.Key === "ADDDEFFORE") {
        if (remove) {
            moddedItem.DefFore -= mod.Count
        }
        else {
            moddedItem.DefFore ? moddedItem.DefFore = parseInt(moddedItem.DefFore) + parseInt(mod.Count) : moddedItem.DefFore = parseInt(mod.Count)
        }
        statChange = true
    }
    if (mod.Key === "ADDDEFPORT") {
        if (remove) {
            moddedItem.DefPort -= parseInt(mod.Count)
        }
        else {
            moddedItem.DefPort ? moddedItem.DefPort = parseInt(moddedItem.DefPort) + parseInt(mod.Count) : moddedItem.DefPort = parseInt(mod.Count)
        }
        statChange = true
    }
    if (mod.Key === "ADDDEFSTAR") {
        if (remove) {
            moddedItem.DefStar -= parseInt(mod.Count)
        }
        else {
            moddedItem.DefStar ? moddedItem.DefStar = parseInt(moddedItem.DefStar) + parseInt(mod.Count) : moddedItem.DefStar = parseInt(mod.Count)
        }
        statChange = true
    }
    if (mod.Key === "ADDDEFAFT") {
        if (remove) {
            moddedItem.DefAft -= parseInt(mod.Count)
        }
        else {
            moddedItem.DefAft ? moddedItem.DefAft = parseInt(moddedItem.DefAft) + parseInt(mod.Count) : moddedItem.DefAft = parseInt(mod.Count)
        }
        statChange = true
    }
    if (mod.Key === "SETARMOR") {
        if (remove) {
            moddedItem.Armor -= 1
        }
        else {
            moddedItem.Armor = parseInt(mod.Count)
        }
        statChange = true
    }
    if (mod.Key === "SETHAND") {
        if (remove) {
            moddedItem.Handling -= 1
        }
        else {
            moddedItem.Handling = parseInt(mod.Count)
        }
        statChange = true
    }
    if (mod.Key === "SETDEFAFT") {
        if (remove) {
            moddedItem.DefAft -= 1
        }
        else {
            moddedItem.DefAft = parseInt(mod.Count)
        }
        statChange = true
    }
    return [moddedItem, statChange]
}

export default modStatChange