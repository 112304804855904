import React, { useState } from 'react';
import {
    Modal, Box, Typography, Button, Select, MenuItem,
    TextField, FormControl, Paper,
    Grid, List, ListItem, Checkbox
} from '@mui/material';
import MarkedText from './MarkedText';
import qualitiesString from './QualitiesString';
import getModText from "./GetModText";


const VehicleWeaponModal = ({ open, handleClose, character, handleCharacterChange, vehicle, setOwnedVehicles, setPreview, weapons, qualities, talents, skills }) => {

    const [selectedWeapon, setSelectedWeapon] = useState("");
    const [count, setCount] = useState(1)
    const [location, setLocation] = useState("")
    const [fireArcs, setFireArcs] = useState({ Fore: false, Port: false, Starboard: false, Aft: false, Dorsal: false, Ventral: false, All: false })
    const [modalVehicle, setModalVehicle] = useState(vehicle)
    const [price, setPrice] = useState(0)

    const getWeapon = (key) => {
        return weapons.find((w) => w.Key === key);
    };

    const handleAddWeapon = (weapon) => {
        let newWeapon = { ...weapon, Added: true }
        if (location) {
            newWeapon.Location = location
        }
        if (count > 1) {
            if (newWeapon.Qualities) {
                newWeapon.Qualities[0] ? newWeapon.Qualities.push({ Key: "LINKED", Count: count - 1 }) : newWeapon.Qualities = [newWeapon.Qualities, { Key: "LINKED", Count: count - 1 }]
            } else {
                newWeapon.Qualities = [{ Key: "LINKED", Count: count - 1 }]
            }
        }
        if (parseInt(newWeapon.SizeLow) > parseInt(modalVehicle.Silhouette)) {
            newWeapon.Oversized = true
            setSelectedWeapon("")
        }
        setPrice(parseInt(price) + parseInt(newWeapon.Price) + (newWeapon.Price * (count - 1) * .5))
        newWeapon.FiringArcs = fireArcs
        let newVehicle = { ...modalVehicle }
        newVehicle.VehicleWeapons ? newVehicle.VehicleWeapons.push(newWeapon) : newVehicle.VehicleWeapons = [newWeapon]
        setModalVehicle(newVehicle)
    }

    const handleReplaceWeapon = (oldIndex, weapon) => {
        let newWeapon = { ...weapon }
        if (modalVehicle.VehicleWeapons[oldIndex].Added) { newWeapon.Added = true }
        if (location) {
            newWeapon.Location = location
        }
        if (count > 1) {
            if (newWeapon.Qualities) {
                newWeapon.Qualities[0] ? newWeapon.Qualities.push({ Key: "LINKED", Count: count - 1 }) : newWeapon.Qualities = [newWeapon.Qualities, { Key: "LINKED", Count: count - 1 }]
            } else {
                newWeapon.Qualities = [{ Key: "LINKED", Count: count - 1 }]
            }
        }
        if (parseInt(newWeapon.SizeLow) > parseInt(modalVehicle.Silhouette)) {
            newWeapon.Oversized = true
            setSelectedWeapon("")
        }
        setPrice(price + parseInt(newWeapon.Price) + (newWeapon.Price * (count - 1) * .5))
        newWeapon.FiringArcs = fireArcs

        let newVehicle = { ...modalVehicle }
        oldIndex === null ? newVehicle.VehicleWeapons = newWeapon : newVehicle.VehicleWeapons[oldIndex] = newWeapon
        setModalVehicle(newVehicle)
    };

    const handleRemoveWeapon = (index) => {
        let newVehicle = { ...modalVehicle }
        modalVehicle.VehicleWeapons.splice(index, 1)
        setModalVehicle(newVehicle)
    }

    const OEWMavailable = (vehicle) => {
        if (vehicle.Attachments?.find((a) => a.Key === "OEWM") && !vehicle.VehicleWeapons.find((w) => w.Oversized)) {
            return true
        }
        else { return false }
    }

    const vehicleWeapons = () => {
        let filteredWeapons = weapons.filter(
            (w) => (w.Type === "Vehicle") &&
                w.Price &&
                parseInt(w.SizeHigh) >= parseInt(modalVehicle.Silhouette) &&
                (parseInt(w.SizeLow) <= parseInt(modalVehicle.Silhouette) + (OEWMavailable(modalVehicle) ? 1 : 0))
        ).sort((a, b) => {
            return a.Name.localeCompare(b.Name);
        })
        return filteredWeapons
    }

    const handleSubmit = () => {
        let newVehicle = { ...modalVehicle }
        let newCategory = [...character.Vehicles]
        newCategory[character.Vehicles.findIndex((v) => v.inventoryID === vehicle.inventoryID)] = newVehicle

        setOwnedVehicles(newCategory)
        handleCharacterChange({
            Vehicles: newCategory,
            Credits: parseInt(character.Credits) - price
        });

        setPrice(0)
        setPreview(newVehicle)
    };

    const getHpUsed = () => {
        let attachmentHP = modalVehicle.Attachments?.reduce((sum, attachment) => sum + (parseInt(attachment.HP) || 0), 0) || 0
        let weaponHP = modalVehicle.VehicleWeapons?.reduce((sum, weapon) => sum + (weapon.Added ? 1 : 0), 0) || 0
        return (attachmentHP + weaponHP)
    }

    const WeaponPanel = ({ weaponObject, chosenWeapon }) => {
        let vehicleWeapon = weaponObject
        let weapon = ""
        chosenWeapon ? weapon = chosenWeapon : weapon = getWeapon(vehicleWeapon.Key);
        const qualitiesList = qualitiesString(
            weapon.Qualities || [],
            vehicleWeapon,
        );

        return (
            <ListItem dense>
                <Paper style={{ width: "100%" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} sx={{ padding: "5px" }}>
                            {vehicleWeapon && <Typography variant="h9">{weapon.Name}</Typography>}
                            <Typography>{weapon.Description}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ padding: "5px" }}>
                            {vehicleWeapon && <Typography variant="caption">
                                Fire Arc:{" "}
                                {Object.entries(vehicleWeapon.FiringArcs)
                                    .filter(([arcName, value]) => value === "true")
                                    .map(([arcName]) => arcName)
                                    .join(", ")}
                            </Typography>}
                            {!vehicleWeapon && <>
                                <Typography variant="caption">Price: {weapon.Price}</Typography>
                                <br />
                                <Typography variant="caption">Rarity: {weapon.Rarity}</Typography>
                            </>
                            }
                            <br />
                            <Typography variant="caption">Damage: {weapon.Damage || '-'}</Typography>
                            <br />
                            <Typography variant="caption">Crit: {weapon.Crit || '-'}</Typography>
                            <br />
                            <Typography variant="caption">Range: {weapon.Range}</Typography>
                            <br />
                            {qualitiesList && (
                                <Typography variant="caption">{qualitiesList}</Typography>
                            )}
                            {weapon.BaseMods && (<>
                                {weapon.BaseMods.MiscDesc && <Typography variant="caption"><div
                                    dangerouslySetInnerHTML={{
                                        __html: MarkedText.renderer(weapon.BaseMods.MiscDesc),
                                    }}
                                /></Typography>}
                                {weapon.BaseMods.Key && <Typography variant="caption"><div
                                    dangerouslySetInnerHTML={{
                                        __html: MarkedText.renderer(getModText(qualities, talents, skills, weapon.BaseMods)),
                                    }}
                                /></Typography>}
                            </>
                            )}
                            {weapon.BaseMods && weapon.BaseMods[0] && (
                                weapon.BaseMods.map((mod, index) => (
                                    <div key={weapon.Key + (mod.Key || "") + index}>
                                        {mod.MiscDesc && (<Typography variant="caption"><div
                                            style={{ paddingTop: "5px" }}
                                            dangerouslySetInnerHTML={{
                                                __html: MarkedText.renderer(mod.MiscDesc),
                                            }}
                                        /></Typography>)}
                                        {mod.Key && (
                                            <Typography variant="caption"><div
                                                style={{ paddingTop: "5px" }}
                                                dangerouslySetInnerHTML={{
                                                    __html: MarkedText.renderer(getModText(qualities, talents, skills, mod)),
                                                }}
                                            /></Typography>
                                        )}
                                    </div>
                                )))}
                        </Grid>
                    </Grid>
                </Paper>
            </ListItem >
        );

    };


    return (
        <Modal open={open} onClose={handleClose}
            sx={{
                overflow: "auto"
            }}>
            <Box sx={{
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4
            }}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h6" component="h2">
                            Vehicle Weapons
                        </Typography>
                        <Typography>HP Used: {getHpUsed()} / {modalVehicle.HP}</Typography>
                    </Grid>
                    <Grid item>
                        <Button onClick={() => handleClose()}>🞫</Button>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <Paper variant="outlined">
                            <Typography>Installed Weapons</Typography>
                            <List>
                                {modalVehicle.VehicleWeapons?.Key && <Grid container>
                                    <Grid item xs={12} sm={11}>
                                        <WeaponPanel weaponObject={modalVehicle.VehicleWeapons} />
                                    </Grid>
                                    <Grid item xs={12} sm={1}>
                                        {selectedWeapon && <Button onClick={() => handleReplaceWeapon(null, selectedWeapon)} >Replace</Button>}
                                        {modalVehicle.VehicleWeapons.Added && <Button>Remove</Button>}
                                    </Grid>
                                </Grid>}
                                {modalVehicle.VehicleWeapons && modalVehicle.VehicleWeapons[0] &&
                                    modalVehicle.VehicleWeapons.map((weap, index) => <Grid container>
                                        <Grid item xs={12} sm={11}>
                                            <WeaponPanel weaponObject={weap} /></Grid>
                                        <Grid item xs={12} sm={1}>
                                            {selectedWeapon && <Button onClick={() => handleReplaceWeapon(index, selectedWeapon)} >Replace</Button>}
                                            {weap.Added && <Button onClick={() => handleRemoveWeapon(index)}>Remove</Button>}
                                        </Grid>

                                    </Grid>)}
                            </List>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper variant="outlined">
                            <Typography>To Install</Typography>
                            <FormControl fullWidth>
                                <Select
                                    value={selectedWeapon}
                                    onChange={(e) => setSelectedWeapon(e.target.value)}
                                >
                                    {vehicleWeapons().map((weapon, index) => (
                                        <MenuItem key={index} value={weapon}>
                                            {weapon.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {selectedWeapon && (<>
                                    <Grid container>
                                        <Grid item xs={12} sm={11}>
                                            <WeaponPanel weapon="" chosenWeapon={selectedWeapon} />
                                        </Grid>
                                        <Grid item>
                                            {selectedWeapon &&
                                                <Button
                                                    disabled={!(getHpUsed() < parseInt(modalVehicle.HP))}
                                                    onClick={() => { handleAddWeapon(selectedWeapon) }}
                                                >
                                                    Add
                                                </Button>}
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems="center" spacing={2} style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        minHeight: '100%'
                                    }} sx={{ padding: "5px" }}>
                                        <Grid item>
                                            <Typography>Location:</Typography>
                                        </Grid>
                                        <Grid item>
                                            <TextField size="small" value={location} onChange={(e) => setLocation(e.target.value)} />
                                        </Grid>
                                        <Grid item>
                                            <Typography>Count:</Typography>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                size="small"
                                                sx={{ maxWidth: "50px" }}
                                                inputProps={{ style: { textAlign: 'center' } }}
                                                value={count}
                                                onChange={(e) => {
                                                    let num = e.target.value
                                                    if (isNaN(num)) {
                                                        return
                                                    }
                                                    setCount(num)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems="center" spacing={1} sx={{ padding: "5px" }}>
                                        <Grid item>
                                            <Typography >Firing Arcs:</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography >All</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Checkbox sx={{ paddingLeft: "0px" }} checked={fireArcs.All} onChange={(e) => setFireArcs({ ...fireArcs, All: !fireArcs.All })} />
                                        </Grid>
                                        <Grid item>
                                            <Typography >Fore</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Checkbox sx={{ paddingLeft: "0px" }} disabled={fireArcs.All} checked={fireArcs.All || fireArcs.Fore} onChange={(e) => setFireArcs({ ...fireArcs, Fore: !fireArcs.Fore })} />
                                        </Grid>
                                        <Grid item>
                                            <Typography >Port</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Checkbox sx={{ paddingLeft: "0px" }} disabled={fireArcs.All} checked={fireArcs.All || fireArcs.Port} onChange={(e) => setFireArcs({ ...fireArcs, Port: !fireArcs.Port })} />
                                        </Grid>
                                        <Grid item>
                                            <Typography >Starboard</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Checkbox sx={{ paddingLeft: "0px" }} disabled={fireArcs.All} checked={fireArcs.All || fireArcs.Starboard} onChange={(e) => setFireArcs({ ...fireArcs, Starboard: !fireArcs.Starboard })} />
                                        </Grid>
                                        <Grid item>
                                            <Typography>Aft</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Checkbox sx={{ paddingLeft: "0px" }} disabled={fireArcs.All} checked={fireArcs.All || fireArcs.Aft} onChange={(e) => setFireArcs({ ...fireArcs, Aft: !fireArcs.Aft })} />
                                        </Grid>
                                        <Grid item>
                                            <Typography >Dorsal</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Checkbox sx={{ paddingLeft: "0px" }} disabled={fireArcs.All} checked={fireArcs.All || fireArcs.Dorsal} onChange={(e) => setFireArcs({ ...fireArcs, Dorsal: !fireArcs.Dorsal })} />
                                        </Grid>
                                        <Grid item>
                                            <Typography >Ventral</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Checkbox sx={{ paddingLeft: "0px" }} disabled={fireArcs.All} checked={fireArcs.All || fireArcs.Ventral} onChange={(e) => setFireArcs({ ...fireArcs, Ventral: !fireArcs.Ventral })} />
                                        </Grid>
                                    </Grid>
                                </>
                                )}
                            </FormControl>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container alignItems="center">
                    <Grid item>
                        <Button onClick={() => handleSubmit()}>Save</Button>
                    </Grid>
                    <Grid item>
                        <Typography>Cost: {price}</Typography>
                    </Grid>
                </Grid>
            </Box>
        </Modal >
    );
};

export default VehicleWeaponModal;