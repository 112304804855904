import React, { useState, useEffect } from 'react';
import {
    Modal, Box, Typography, Button, Select, MenuItem,
    TextField, FormControl, InputLabel, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, Paper,
    Grid
} from '@mui/material';
import MarkedText from './MarkedText';
import qualitiesString from './QualitiesString';
import editQualities from './EditQualities';
import getModText from "./GetModText";
import { isArray } from 'lodash';

const categories = [
    'Armor', 'Brawl or Melee Weapon', 'Ranged Weapon', 'Lightsaber',
    'Gadget', 'Cybernetic', 'Potion', 'Talisman'
];

const CraftingModal = ({ open, handleClose, craftingTemplates, craftingResultTables, character, handleCharacterChange, setOwnedArmor, setOwnedWeapons, setOwnedGear, skills, attachments, qualities, talents }) => {
    const [category, setCategory] = useState('');
    const [template, setTemplate] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState({})
    const [templateSkill, setTemplateSkill] = useState('SELECT SKILL')
    const [embSkill, setEmbSkill] = useState('SELECT SKILL')
    const [restrictiveSkill, setRestrictiveSkill] = useState('SELECT SKILL')
    const [results, setResults] = useState({})
    const [spentResults, setSpentResults] = useState({})
    const [showIntAttach, setShowIntAttach] = useState(false)
    const [selectedIntAttach, setSelectedIntAttach] = useState({})
    const [itemCategory, setItemCategory] = useState("")
    const [inbuiltItem, setInbuiltItem] = useState({})

    const filteredTemplates = craftingTemplates.filter(temp => temp.TempType === category);

    const filteredResults = craftingResultTables.filter((r) => r.TempType === category)

    let personalRanges = ["Engaged", "Short", "Medium", "Long", "Extreme"]

    useEffect(() => {
        switch (category) {
            case 'Armor':
                setItemCategory(category)
                break;
            case "Brawl or Melee Weapon":
            case "Lightsaber":
            case "Ranged Weapon":
                setItemCategory("Weapons")
                break;
            default:
                setItemCategory("Gear")
        }
    }, [category]);

    const inbuiltOptions = category === "Lightsaber" ? [...character.Weapons.filter((w) => w.Encumbrance > 0)].concat([...character.Gear.filter((g) => g.Encumbrance > 0)]) : [...character.Weapons.filter((w) => w.Encumbrance < 3)]

    const integralAttachments = attachments.filter((a) => !a.ItemLimit && a.HP === "1" && a.Type === (itemCategory === "Armor" ? "Armor" : "Weapon") &&
        (a.CategoryLimit ? a.CategoryLimit === (selectedTemplate.ArmorProfile?.Categories || selectedTemplate.WeaponProfile?.Categories) : true)).sort((a, b) => a.Name.localeCompare(b.Name))

    useEffect(() => {
        if (template) {
            let newTemplate = craftingTemplates.find((temp) => temp.Key === template)
            setSelectedTemplate(newTemplate);
            if (newTemplate) {
                setName(newTemplate.Name);
                setDescription("");
            }
        }
    }, [template, craftingTemplates]);

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
        setName('')
        setTemplate('');
        setResults({})
        setSpentResults({})
        setSelectedOptions([])
        setShowIntAttach(false)
        setSelectedIntAttach({})
        setInbuiltItem({})
        setTemplateSkill("SELECT SKILL")
    };

    const handleTemplateChange = (event) => {
        setTemplate(event.target.value);
        setSpentResults({})
        setSelectedOptions([])
        setShowIntAttach(false)
        setSelectedIntAttach({})
        setInbuiltItem({})
        setTemplateSkill("SELECT SKILL")
    };

    const availableSymbol = (symbol) => {
        let available = 0
        available = parseInt(results[symbol] || 0) - parseInt(spentResults[symbol] || 0)
        return available
    }

    function stepCount(str, decrement) {

        let match = str.match(/\d+/);

        if (!match) {
            let regex = /\[BO\]+/;
            match = str.match(regex);

            if (!match) {
                let regex = /\[SE\]+/;
                match = str.match(regex);
                if (!match) { return decrement ? "0" : str; }
                if (decrement) {
                    const newStr = str.replace("[SE]", "");
                    return newStr.includes("[SE]") ? newStr : "0";
                } else {
                    return str.replace(regex, match[0] + "[SE]");
                }
            }

            if (decrement) {
                const newStr = str.replace("[BO]", "");
                return newStr.includes("[BO]") ? newStr : "0";
            } else {
                return str.replace(regex, match[0] + "[BO]");
            }
        }

        const number = parseInt(match[0], 10);
        const incrementedNumber = number + 1;
        const decrementNumber = number - 1;
        let returnNumber = decrement ? decrementNumber : incrementedNumber;

        return str.replace(match[0], returnNumber);
    }

    const handleOptionAdd = (index, count, type) => {
        let newSpentResults = { ...spentResults }
        if (type === "AdvThr") {
            if (count > 0) {
                newSpentResults["[AD]"] ? newSpentResults["[AD]"] += count : newSpentResults["[AD]"] = count
            }
            if (count < 0) {
                newSpentResults["[TH]"] ? newSpentResults["[TH]"] += Math.abs(count) : newSpentResults["[TH]"] = Math.abs(count)
            }
        }
        if (type === "TriDes") {
            if (count > 0) {
                newSpentResults["[TR]"] ? newSpentResults["[TR]"] += count : newSpentResults["[TR]"] = count
            }
            if (count < 0) {
                newSpentResults["[DE]"] ? newSpentResults["[DE]"] += Math.abs(count) : newSpentResults["[DE]"] = Math.abs(count)
            }
        }
        if (type === "LightDark") {
            if (count > 0) {
                newSpentResults["[LI]"] ? newSpentResults["[LI]"] += count : newSpentResults["[LI]"] = count
            }
            if (count < 0) {
                newSpentResults["[DA]"] ? newSpentResults["[DA]"] += Math.abs(count) : newSpentResults["[DA]"] = Math.abs(count)
            }
        }
        setSpentResults(newSpentResults)

        let newItem = JSON.parse(JSON.stringify(selectedTemplate))
        let newProfile = newItem.WeaponProfile || newItem.ArmorProfile || newItem.GearProfile
        if (filteredResults[index].EncumbranceChange) {
            newProfile.Encumbrance = Math.max(parseInt(newProfile.Encumbrance) + parseInt(filteredResults[index].EncumbranceChange), 0)
        }
        if (filteredResults[index].HPChange) {
            newProfile.HP ? newProfile.HP = parseInt(newProfile.HP) + parseInt(filteredResults[index].HPChange) : newProfile.HP = parseInt(filteredResults[index].HPChange)
        }
        if (filteredResults[index].SoakChange) {
            newProfile.Soak ? newProfile.Soak = parseInt(newProfile.Soak) + parseInt(filteredResults[index].SoakChange) : newProfile.Soak = parseInt(filteredResults[index].SoakChange)
        }
        if (filteredResults[index].CritChange) {
            newProfile.Crit ? newProfile.Crit = parseInt(newProfile.Crit) + parseInt(filteredResults[index].CritChange) : newProfile.Crit = parseInt(filteredResults[index].CritChange)
        }
        if (filteredResults[index].DamAddChange) {
            newProfile.Damage ? newProfile.Damage = parseInt(newProfile.Damage) + parseInt(filteredResults[index].DamAddChange) : newProfile.DamageAdd ? newProfile.DamageAdd = parseInt(newProfile.DamageAdd) + parseInt(filteredResults[index].DamAddChange) : 0
        }
        if (filteredResults[index].DamChange) {
            newProfile.Damage ? newProfile.Damage = parseInt(newProfile.Damage) + parseInt(filteredResults[index].DamChange) : newProfile.DamageAdd ? newProfile.DamageAdd = parseInt(newProfile.DamageAdd) + parseInt(filteredResults[index].DamChange) : 0
        }
        if (filteredResults[index].MaterialCostDiscount) {
            newItem.MaterialCost = Math.round(newItem.MaterialCost - ((filteredResults[index].MaterialCostDiscount / 100) * newItem.MaterialCost))
        }
        if (filteredResults[index].DuplicateItem) {
            newItem.Duplicate ? newItem.Duplicate += parseInt(filteredResults[index].DuplicateItem) : newItem.Duplicate = parseInt(filteredResults[index].DuplicateItem)
        }
        if (filteredResults[index].RangeChange) {
            newProfile.RangeValue = personalRanges[personalRanges.findIndex((r) => r === newProfile.RangeValue) + 1]
        }
        if (filteredResults[index].Schematic) {
            let newSchematics = character.Schematics || {}
            newSchematics[selectedTemplate.Key] ? newSchematics[selectedTemplate.Key] += 1 : newSchematics[selectedTemplate.Key] = 1
            handleCharacterChange({ Schematics: newSchematics })
        }
        if (filteredResults[index].Safety) {
            let safetyFeature = {
                MiscDesc: "Add [AD] to [templateSkill] checks.",
            }
            newProfile.BaseMods ? Array.isArray(newProfile.BaseMods) ? newProfile.BaseMods.push(safetyFeature) : newProfile.BaseMods = [newProfile.BaseMods, safetyFeature] : newProfile.BaseMods = [safetyFeature]
            newItem.TemplateSkillAdvantage = 1
        }
        if (filteredResults[index].Compact) {
            if (newItem.BaseMods?.find((m) => m.MiscDesc.includes("Gadget with encumbrance of 3 or less adds"))) {
                let mod = newItem.BaseMods.find((m) => m.MiscDesc.includes("Gadget with encumbrance of 3 or less adds"))
                mod.MiscDesc = mod.MiscDesc.substring(51)
                mod.MiscDesc = "Compact: Gadget with encumbrance of 3 or less adds [SE]" + mod.MiscDesc
            } else {
                newItem.BaseMods ? newItem.BaseMods.push({ "MiscDesc": filteredResults[index].Name + ": " + filteredResults[index].Description }) : newItem.BaseMods = [{ "MiscDesc": filteredResults[index].Name + ": " + filteredResults[index].Description }]
            }
        }
        if (filteredResults[index].Counterweight && newProfile.Qualities && newProfile.Qualities.find((q) => q.Key === "UNWIELDY")) {
            newProfile.Qualities.find((q) => q.Key === "UNWIELDY").Key = "CUMBERSOME"
        }
        if (filteredResults[index].DelicateBalance && newProfile.Qualities && newProfile.Qualities.find((q) => q.Key === "CUMBERSOME")) {
            newProfile.Qualities.find((q) => q.Key === "CUMBERSOME").Key = "UNWIELDY"
        }
        if (filteredResults[index].LimitedAmmoGood) {
            if (newProfile.Qualities && newProfile.Qualities.find((q) => q.Key === 'LIMITEDAMMO')) {
                const limitedAmmoQuality = newProfile.Qualities.find((q) => q.Key === 'LIMITEDAMMO');
                if (limitedAmmoQuality) {
                    limitedAmmoQuality.Count = (parseInt(limitedAmmoQuality.Count) || 0) + 1;
                }
            }
        }
        if (filteredResults[index].LimitedAmmoBad) {
            newProfile.Qualities
                ? (newProfile.Qualities.find((q) => q.Key === "LIMITEDAMMO")
                    ? newProfile.Qualities.find((q) => q.Key === "LIMITEDAMMO").Count -= 1
                    : newProfile.Qualities.push({ Key: "LIMITEDAMMO", Count: 3 }))
                : newProfile.Qualities = [{ Key: "LIMITEDAMMO", Count: 3 }];
        }
        if (filteredResults[index].IntegralAttachment) {
            setShowIntAttach(true)
            newProfile.HP ? newProfile.HP = parseInt(newProfile.HP) + 1 : newProfile.HP = 1
        }
        if (filteredResults[index].Mods) {
            let mods = []
            if (!isArray(filteredResults[index].Mods)) {
                mods = [{ ...filteredResults[index].Mods }]
            } else { mods = [...filteredResults[index].Mods] }
            mods.map((mod) => {
                if (!mod.MiscDesc && !mod.Key && filteredResults[index].Name && filteredResults[index].Description) {
                    mod.MiscDesc = filteredResults[index].Name + ": " + filteredResults[index].Description
                }
                if (newItem.TempType === "Lightsaber" && mod.Key === "DAMADD") {
                    if (newProfile.BaseMods) {
                        Array.isArray(newProfile.BaseMods) ? newProfile.BaseMods.push(mod) : newProfile.BaseMods = [newProfile.BaseMods, mod]
                    } else {
                        newProfile.BaseMods = mod
                    }
                } else {
                    newProfile = editQualities(newProfile, [mod])
                }
                newItem.WeaponProfile ? newItem.WeaponProfile = newProfile : newItem.ArmorProfile ? newItem.ArmorProfile = newProfile : newItem.GearProfile = newProfile
                if (mod.MiscDesc) {
                    if (filteredResults[index].Key === "IMPLTSABERDISG" && newItem.BaseMods?.find((m) => m.MiscDesc.includes("to checks to identify the lightsaber as such when it is not ignited."))) {
                        let mod = newItem.BaseMods.find((m) => m.MiscDesc.includes("to checks to identify the lightsaber as such when it is not ignited."))
                        mod.MiscDesc = mod.MiscDesc.substring(15)
                        mod.MiscDesc = "Disguised: Add [SE]" + mod.MiscDesc
                    } else if (filteredResults[index].TempType === "Potion" || filteredResults[index].TempType === "Talisman") {
                        mod.ResultKey = filteredResults[index].Key
                        if (!newItem.GearProfile.BaseMods) { newItem.GearProfile.BaseMods = [mod] } else if (!Array.isArray(newItem.GearProfile.BaseMods)) {
                            newItem.GearProfile.BaseMods = [newItem.GearProfile.BaseMods, mod]
                        } else if (Array.isArray(newItem.GearProfile.BaseMods)) {
                            let existingMod = newItem.GearProfile.BaseMods.findIndex(m => m.ResultKey && m.ResultKey === mod.ResultKey)
                            existingMod !== -1 ? newItem.GearProfile.BaseMods[existingMod] = { MiscDesc: stepCount(newItem.GearProfile.BaseMods[existingMod].MiscDesc), ResultKey: mod.ResultKey } : newItem.GearProfile.BaseMods.push(mod)
                        }
                    } else {
                        newItem.BaseMods ? newItem.BaseMods.push({ "MiscDesc": mod.MiscDesc }) : newItem.BaseMods = [{ "MiscDesc": mod.MiscDesc }]
                    }
                }
            })
        }
        setSelectedTemplate(newItem)

        let newOptions = [...selectedOptions]
        newOptions[index] = selectedOptions[index] ? selectedOptions[index] + 1 : 1
        setSelectedOptions(newOptions);
    };

    const handleOptionRemove = (index) => {
        let availableRefunds = {
            "AD": parseInt(spentResults["[AD]"] || 0),
            "TH": parseInt(spentResults["[TH]"] || 0),
            "TR": parseInt(spentResults["[TR]"] || 0),
            "DE": parseInt(spentResults["[DE]"] || 0),
            "LI": parseInt(spentResults["LI]"] || 0),
            "DA": parseInt(spentResults["[DA]"] || 0)
        }
        let newSpentResults = { ...spentResults }

        if (selectedOptions.reduce(function (s, v) { return s + (v || 0); }, 0) === 1) { newSpentResults = {} }
        else if (filteredResults[index].CostAdvThr && parseInt(filteredResults[index].CostAdvThr) > 0 && parseInt(filteredResults[index].CostAdvThr) <= availableRefunds.AD) {
            newSpentResults["[AD]"] -= parseInt(filteredResults[index].CostAdvThr)
        } else if (filteredResults[index].CostAdvThr && Math.abs(parseInt(filteredResults[index].CostAdvThr)) <= availableRefunds.TH && availableRefunds.TH > 0) {
            newSpentResults["[TH]"] += parseInt(filteredResults[index].CostAdvThr)
        } else if (parseInt(filteredResults[index].CostTriDes) > 0 && parseInt(filteredResults[index].CostTriDes) <= availableRefunds.TR) {
            newSpentResults["[TR]"] -= parseInt(filteredResults[index].CostTriDes)
        } else if (Math.abs(parseInt(filteredResults[index].CostTriDes)) <= availableRefunds.DE && availableRefunds.DE > 0) {
            newSpentResults["[DE]"] += parseInt(filteredResults[index].CostTriDes)
        } else if (parseInt(filteredResults[index].CostLightDark) > 0 && parseInt(filteredResults[index].CostLightDark) <= availableRefunds.LI) {
            newSpentResults["[LI]"] -= parseInt(filteredResults[index].CostLightDark)
        } else if (parseInt(filteredResults[index].CostLightDark) < 0 && Math.abs(parseInt(filteredResults[index].CostLightDark)) <= availableRefunds.DA && availableRefunds.DA > 0) {
            newSpentResults["[DA]"] += parseInt(filteredResults[index].CostLightDark)
        }
        setSpentResults(newSpentResults)

        let newItem = { ...selectedTemplate }
        let newProfile = newItem.WeaponProfile || newItem.ArmorProfile || newItem.GearProfile
        if (filteredResults[index].EncumbranceChange) {
            newProfile.Encumbrance = parseInt(newProfile.Encumbrance) - parseInt(filteredResults[index].EncumbranceChange)
        }
        if (filteredResults[index].HPChange) {
            newProfile.HP = parseInt(newProfile.HP) - parseInt(filteredResults[index].HPChange)
        }
        if (filteredResults[index].SoakChange) {
            newProfile.Soak = parseInt(newProfile.Soak) - parseInt(filteredResults[index].SoakChange)
        }
        if (filteredResults[index].CritChange) {
            newProfile.Crit = parseInt(newProfile.Crit) - parseInt(filteredResults[index].CritChange)
        }
        if (filteredResults[index].DamAddChange) {
            newProfile.Damage ? newProfile.Damage = parseInt(newProfile.Damage) - parseInt(filteredResults[index].DamAddChange) : newProfile.DamageAdd ? newProfile.DamageAdd = parseInt(newProfile.DamageAdd) - parseInt(filteredResults[index].DamAddChange) : 0
        }
        if (filteredResults[index].DamChange) {
            newProfile.Damage ? newProfile.Damage = parseInt(newProfile.Damage) - parseInt(filteredResults[index].DamChange) : newProfile.DamageAdd ? newProfile.DamageAdd = parseInt(newProfile.DamageAdd) - parseInt(filteredResults[index].DamChange) : 0
        }
        if (filteredResults[index].MaterialCostDiscount) {
            newItem.MaterialCost = Math.max(5, Math.round((newItem.MaterialCost / (1 - (filteredResults[index].MaterialCostDiscount / 100))) / 5) * 5);
        }
        if (filteredResults[index].DuplicateItem) {
            newItem.Duplicate -= filteredResults[index].DuplicateItem
        }
        if (filteredResults[index].Restrictive) {
            newProfile.BaseMods = newProfile.BaseMods.filter((m) => m.MiscDesc !== "Add [TH] to " + restrictiveSkill + " checks.")
        }
        if (filteredResults[index].SpecialEmb) {
            newProfile.BaseMods = newProfile.BaseMods.filter((m) => m.MiscDesc !== "Add [AD] to " + embSkill + " checks.")
        }
        if (filteredResults[index].LimitedAmmoGood) {
            newProfile.Qualities.find((q) => q.Key === "LIMITEDAMMO").Count -= 1
        }
        if (filteredResults[index].LimitedAmmoBad) {
            newProfile.Qualities.find((q) => q.Key === "LIMITEDAMMO").Count !== 3 ? newProfile.Qualities.find((q) => q.Key === "LIMITEDAMMO").Count += 1 : newProfile.Qualities = newProfile.Qualities.filter((q) => q.Key !== "LIMITEDAMMO")
        }
        if (filteredResults[index].RangeChange) {
            newProfile.RangeValue = personalRanges[personalRanges.findIndex((r) => r === newProfile.RangeValue) - 1]
        }
        if (filteredResults[index].Counterweight && newProfile.Qualities && newProfile.Qualities.find((q) => q.Key === "CUMBERSOME")) {
            newProfile.Qualities.find((q) => q.Key === "CUMBERSOME").Key = "UNWIELDY"
        }
        if (filteredResults[index].DelicateBalance && newProfile.Qualities && newProfile.Qualities.find((q) => q.Key === "UNWIELDY")) {
            newProfile.Qualities.find((q) => q.Key === "UNWIELDY").Key = "CUMBERSOME"
        }
        if (filteredResults[index].Schematic) {
            let newSchematics = character.Schematics || {}
            newSchematics[selectedTemplate.Key] -= 1
            handleCharacterChange({ Schematics: newSchematics })
        }
        if (filteredResults[index].InbuiltItem) {
            newProfile.BaseMods = newProfile.BaseMods.filter((m) => !m.MiscDesc?.includes("Inbuilt into"))
            if (newProfile.BaseMods.length === 0) { delete newProfile.BaseMods }
            setInbuiltItem({})
        }
        if (filteredResults[index].Safety) {
            newProfile.BaseMods = newProfile.BaseMods.filter((m) => m.MiscDesc !== ("Add [AD] to [templateSkill] checks."))
            delete newItem.TemplateSkillAdvantage
        }
        if (filteredResults[index].Compact) {
            let mod = newItem.BaseMods.find((m) => m.MiscDesc.includes("Gadget with encumbrance of 3 or less adds"))
            mod.MiscDesc = mod.MiscDesc.substring(55)
            mod.MiscDesc = "Compact: Gadget with encumbrance of 3 or less adds " + mod.MiscDesc
            if (mod.MiscDesc.includes('Compact: Gadget with encumbrance of 3 or less adds  to checks')) {
                newItem.BaseMods = newItem.BaseMods.filter((m) => !m.MiscDesc.includes("Gadget with encumbrance of 3 or less adds"))
            }
        }
        if (filteredResults[index].IntegralAttachment) {
            setShowIntAttach(false)
            setSelectedIntAttach({})
            newProfile.Attachments = []
            newProfile.HP = newProfile.HP - 1
        }
        if (filteredResults[index].Mods) {
            let mods = []
            if (!isArray(filteredResults[index].Mods)) {
                mods = [{ ...filteredResults[index].Mods }]
            } else { mods = [...filteredResults[index].Mods] }
            mods.map((mod) => {
                if (!mod.MiscDesc && !mod.Key && filteredResults[index].Name && filteredResults[index].Description) {
                    mod.MiscDesc = filteredResults[index].Name + ": " + filteredResults[index].Description
                }
                if (newItem.TempType === "Lightsaber" && mod.Key === "DAMADD") {
                    Array.isArray(newProfile.BaseMods) ? newProfile.BaseMods.filter((m) => m.Key !== "DAMADD") : newProfile.BaseMods = {}
                } else {
                    newProfile = editQualities(newProfile, [mod], true)
                }
                newItem.WeaponProfile ? newItem.WeaponProfile = newProfile : newItem.ArmorProfile ? newItem.ArmorProfile = newProfile : newItem.GearProfile = newProfile
                if (mod.MiscDesc) {
                    if (filteredResults[index].Key === "IMPLTSABERDISG") {
                        let mod = newItem.BaseMods.find((m) => m.MiscDesc.includes("to checks to identify the lightsaber as such when it is not ignited."))
                        mod.MiscDesc = mod.MiscDesc.substring(19)
                        mod.MiscDesc = "Disguised: Add " + mod.MiscDesc
                        if (mod.MiscDesc.length === 84) { newItem.BaseMods = newItem.BaseMods.filter((m) => !m.MiscDesc.includes("to checks to identify the lightsaber as such when it is not ignited.")) }
                    } else if (filteredResults[index].TempType === "Potion" || filteredResults[index].TempType === "Talisman") {
                        let existingMod = newItem.GearProfile.BaseMods.findIndex(m => m.MiscDesc && m.MiscDesc.substring(0, 6) === mod.MiscDesc.substring(0, 6))
                        newItem.GearProfile.BaseMods[existingMod] = { MiscDesc: stepCount(newItem.GearProfile.BaseMods[existingMod].MiscDesc, true) }
                        if (newItem.GearProfile.BaseMods[existingMod].MiscDesc.includes("0")) { newItem.GearProfile.BaseMods.splice(existingMod, 1) }
                    }
                } else {
                    newItem.BaseMods = newItem.BaseMods?.filter((m) => m.MiscDesc !== mod.MiscDesc)
                }
            })
        }
        setSelectedTemplate(newItem)

        let newOptions = [...selectedOptions]
        newOptions[index] = newOptions[index] - 1
        setSelectedOptions(newOptions);
    };

    const handleSubmit = () => {
        let profile = ""
        if (selectedTemplate.ArmorProfile) { profile = "ArmorProfile" }
        if (selectedTemplate.WeaponProfile) { profile = "WeaponProfile" }
        if (selectedTemplate.GearProfile) { profile = "GearProfile" }

        if (selectedTemplate[profile].BaseMods && Array.isArray(selectedTemplate[profile].BaseMods)) {
            for (let m in selectedTemplate[profile].BaseMods) {
                let mod = selectedTemplate[profile].BaseMods[m]
                if (mod.MiscDesc && mod.MiscDesc.includes("[templateSkill]")) {
                    mod.MiscDesc = mod.MiscDesc.replace("[templateSkill]", templateSkill)
                    let CountType = mod.MiscDesc.includes('[SU]') ? "SuccessCount" : mod.MiscDesc.includes('[SE]') ? "SetbackSubCount" : mod.MiscDesc.includes('[AD]') ? "AdvantageCount" : null
                    if (CountType) {
                        mod.DieModifiers = {
                            DieModifier: {
                                SkillKey: skills.find(s => s.skill === templateSkill).Key,
                                [CountType]: mod.MiscDesc.includes('[SE]') ? "2" : "1"
                            }
                        }
                    }
                }
            }
        }

        selectedTemplate[profile].Name = name
        selectedTemplate[profile].Description = description
        const UID = `${'craftedItem'}_${Date.now()}`;
        selectedTemplate[profile].Key = UID
        selectedTemplate[profile].inventoryID = UID
        let newCategory = [...character[itemCategory]]
        newCategory.push(selectedTemplate[profile])
        if (selectedTemplate.Duplicate) {
            if (category === "Talisman" || category === "Potion") {
                selectedTemplate.GearProfile.Quantity = selectedTemplate.Duplicate
            } else {
                for (let i = 0; i < selectedTemplate.Duplicate; i++) {
                    let guid = `${'craftedItem'}_${Date.now()}_${i}`
                    let newItem = JSON.parse(JSON.stringify(selectedTemplate[profile])); // Deep copy
                    newItem.Key = guid
                    newItem.inventoryID = guid
                    newCategory.push(newItem)
                }
            }
        }
        handleCharacterChange({
            [itemCategory]: newCategory,
            Credits: character.Credits - selectedTemplate.MaterialCost,
        });
        switch (itemCategory) {
            case 'Armor':
                setOwnedArmor(newCategory)
                break;
            case "Weapons":
                setOwnedWeapons(newCategory)
                break;
            case "Gear":
                setOwnedGear(newCategory)
                break;
            default:
                console.log("Error: No item category registered")
        }
        setCategory('');
        setName('')
        setTemplate('');
        setResults({})
        setSpentResults({})
        setSelectedOptions([])
        handleClose();
        setTemplateSkill("SELECT SKILL")
    };

    const getCostString = (costA, costB, costF) => {
        let costString = ""
        if (parseInt(costA) > 0 || parseInt(costB) > 0 || parseInt(costF) > 0) {
            costString += "[AD]".repeat(parseInt(costA))
            costString += "[TR]".repeat(parseInt(costB))
            costString += "[LI]".repeat(parseInt(costF))

        }
        if (parseInt(costA) < 0 || parseInt(costB) < 0 || parseInt(costF) < 0) {
            costString += "[TH]".repeat(Math.abs(parseInt(costA)))
            costString += "[DE]".repeat(Math.abs(parseInt(costB)))
            costString += "[DA]".repeat(Math.abs(parseInt(costF)))
        }
        return costString
    }

    const skillsString = (skillArray) => {
        let skillString = ""
        if (Array.isArray(skillArray)) {
            for (let s in skillArray) {
                skillString += skills.find((sk) => sk.Key === skillArray[s]).skill + " "
                if (s < (skillArray.length - 1)) {
                    skillString += "or "
                }
            }
        }
        else { skillString = skills.find((sk) => sk.Key === skillArray).skill + " " }
        skillString += "check"
        return skillString
    }

    const diffString = () => {
        let diff = ""
        let repeat = Math.max(selectedTemplate.CheckDifficulty - ((character.Schematics && character.Schematics[selectedTemplate.Key]) ? character.Schematics[selectedTemplate.Key] : 0), 0)
        diff += "[DI]".repeat(repeat)
        if (diff === "") {
            diff = "(-)"
        }
        return diff
    }

    const schematicString = () => {
        let string = ""
        string += ((character.Schematics && character.Schematics[selectedTemplate.Key] && character.Schematics[selectedTemplate.Key] > 0) ? " with " + character.Schematics[selectedTemplate.Key] + " schematics" : "")
        return string
    }

    const adjustResults = (symbol, number) => {
        let newResults = { ...results }
        newResults[symbol] = number
        setResults(newResults)
    }

    const skillModString = (string) => {
        let newString = string.replace("[templateSkill]", templateSkill)
        return newString
    }

    const handleEmbeleshment = (skill) => {
        setEmbSkill(skill)
        let newTemplate = { ...selectedTemplate }
        let embellishmentObject = { MiscDesc: "Add [AD] to " + skill + " checks.", Count: "1", DieModifiers: { DieModifier: { SkillKey: skills.find((s) => s.skill === skill).Key, AdvantageCount: "1" } } }

        if (newTemplate.ArmorProfile.BaseMods && newTemplate.ArmorProfile.BaseMods !== "") {
            if (Array.isArray(newTemplate.ArmorProfile.BaseMods)) {
                newTemplate.ArmorProfile.BaseMods.push(embellishmentObject)
            } else {
                newTemplate.ArmorProfile.BaseMods = [newTemplate.ArmorProfile.BaseMods, embellishmentObject]
            }
        } else {
            newTemplate.ArmorProfile.BaseMods = [embellishmentObject]
        }
        setSelectedTemplate(newTemplate)
    }

    const handleTool = (skill) => {
        let newTemplate = { ...selectedTemplate }
        let toolDesc = {
            MiscDesc: "Allows character to make checks with " + skill + ". It can also count as the right tool for the job."
        }

        if (newTemplate.GearProfile.BaseMods && newTemplate.GearProfile.BaseMods !== "") {
            if (Array.isArray(newTemplate.GearProfile.BaseMods)) {
                if (newTemplate.GearProfile.BaseMods.find(m => m.MiscDesc && m.MiscDesc.includes("Allows character to make checks with"))) {
                    newTemplate.GearProfile.BaseMods = newTemplate.GearProfile.BaseMods.filter(m => m.MiscDesc && !m.MiscDesc.includes("Allows character to make checks with"))
                }
                newTemplate.GearProfile.BaseMods.push(toolDesc)
            } else {
                newTemplate.GearProfile.BaseMods = [newTemplate.GearProfile.BaseMods, toolDesc]
            }
        } else {
            newTemplate.GearProfile.BaseMods = [toolDesc]
        }
        setSelectedTemplate(newTemplate)
    }

    const handleRestrictive = (skill) => {
        setRestrictiveSkill(skill)
        let newTemplate = { ...selectedTemplate }
        let restrictedObject = { MiscDesc: "Add [TH] to " + skill + " checks.", Count: "1", Restrictive: true, DieModifiers: { DieModifier: { SkillKey: skills.find((s) => s.skill === skill).Key, ThreatCount: "1" } } }

        if (newTemplate.ArmorProfile.BaseMods && newTemplate.ArmorProfile.BaseMods !== "") {
            if (Array.isArray(newTemplate.ArmorProfile.BaseMods)) {
                newTemplate.ArmorProfile.BaseMods = newTemplate.ArmorProfile.BaseMods.filter(m => !m.Restrictive)
                newTemplate.ArmorProfile.BaseMods.push(restrictedObject)
            } else {
                newTemplate.ArmorProfile.BaseMods = [newTemplate.ArmorProfile.BaseMods, restrictedObject]
            }
        } else {
            newTemplate.ArmorProfile.BaseMods = [restrictedObject]
        }
        setSelectedTemplate(newTemplate)
    }

    const handleSupremeCraft = (skill) => {
        let newTemplate = { ...selectedTemplate }
        let supremeDesc = { MiscDesc: "Upgrade " + skill + " checks made with this tool once.", SupremeCraft: true, DieModifiers: { DieModifier: { SkillKey: skills.find((s) => s.skill === skill).Key, UpgradeAbilityCount: "1" } } }

        if (newTemplate.GearProfile.BaseMods && newTemplate.GearProfile.BaseMods !== "") {
            if (Array.isArray(newTemplate.GearProfile.BaseMods)) {
                newTemplate.GearProfile.BaseMods = newTemplate.GearProfile.BaseMods.filter(m => !m.SupremeCraft)
                newTemplate.GearProfile.BaseMods.push(supremeDesc)
            } else {
                newTemplate.GearProfile.BaseMods = [newTemplate.GearProfile.BaseMods, supremeDesc]
            }
        } else {
            newTemplate.GearProfile.BaseMods = [supremeDesc]
        }
        setSelectedTemplate(newTemplate)
    }

    const handleIntegratedAttachment = (attachment) => {
        setSelectedIntAttach(attachment)
        let attachmentTemplate = { ...selectedTemplate }
        let attachmentProfile = (attachmentTemplate.WeaponProfile || attachmentTemplate.ArmorProfile)
        attachmentProfile.Attachments = [attachment]
        setSelectedTemplate(attachmentTemplate)
    }

    const handleInbuilt = (item) => {
        setInbuiltItem(item)
        let newInbuiltTemp = { ...selectedTemplate }
        let inbuiltProfile = (newInbuiltTemp.WeaponProfile || newInbuiltTemp.GearProfile)
        if (category === "Lightsaber") {
            let inbuiltObj = { MiscDesc: "Inbuilt into " + item.Name, Inbuilt: true }
            if (inbuiltProfile.BaseMods) {
                if (Array.isArray(inbuiltProfile.BaseMods)) {
                    inbuiltProfile.BaseMods = inbuiltProfile.BaseMods.filter(m => !m.Inbuilt)
                    inbuiltProfile.BaseMods.push(inbuiltObj)
                } else inbuiltProfile.BaseMods = [inbuiltProfile.BaseMods, inbuiltObj]
            } else {
                inbuiltProfile.BaseMods = [inbuiltObj]
            }
        } else {
            let inbuiltObj = { MiscDesc: item.Name + " is built into this " + category.toLowerCase() + ".", Inbuilt: true }
            if (inbuiltProfile.BaseMods) {
                if (Array.isArray(inbuiltProfile.BaseMods)) {
                    inbuiltProfile.BaseMods = inbuiltProfile.BaseMods.filter(m => !m.Inbuilt)
                    inbuiltProfile.BaseMods.push(inbuiltObj)
                } else {
                    inbuiltProfile.BaseMods = [inbuiltProfile.BaseMods, inbuiltObj]
                }
            } else {
                inbuiltProfile.BaseMods = [inbuiltObj]
            }
        }
    }

    const handleTemplateSkill = (skill) => {
        setTemplateSkill(skill)
        if (category === "Cybernetic") {
            selectedTemplate.GearProfile.BaseMods[0].Key = skills.find(s => s.skill === skill).Key
            delete selectedTemplate.GearProfile.BaseMods[0].MiscDesc
        }
    }


    return (
        <Modal open={open} onClose={handleClose}
            sx={{
                overflow: "auto"
            }}>
            <Box sx={{
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4
            }}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h6" component="h2">
                            Craft Item
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button onClick={() => handleClose()}>🞫</Button>
                    </Grid>
                </Grid>

                <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel>Category</InputLabel>
                    <Select value={category} onChange={handleCategoryChange}>
                        {categories.map(cat => (
                            <MenuItem key={cat} value={cat}>{cat}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {category && (
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel>Template</InputLabel>
                        <Select value={template} onChange={handleTemplateChange}>
                            {filteredTemplates.map(temp => (
                                <MenuItem key={temp.Key} value={temp.Key}>{temp.Name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}

                {template && (
                    <>
                        <Grid container sx={{ paddingTop: "15px" }}>
                            <Grid item>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: MarkedText.renderer(diffString() + " " + skillsString(selectedTemplate.CheckSkills || "MECH") + schematicString())
                                    }}
                                />
                                <Typography>Material Cost: {selectedTemplate.MaterialCost} Credits | Rarity: {selectedTemplate.MaterialRarity} | Crafting Time: {selectedTemplate.TimeHours} hours</Typography>
                            </Grid>
                            <Grid item sx={{ paddingLeft: "30px" }}>
                                <Typography sx={{ paddingBottom: "5px" }}>Results: </Typography>
                                <Typography>Available: </Typography>
                            </Grid>
                            <Grid item sx={{ paddingLeft: "5px" }}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: MarkedText.renderer(" [AD] ")
                                    }}
                                />
                            </Grid>
                            <Grid item sx={{ paddingLeft: "5px" }}>
                                <TextField
                                    type="text"
                                    value={results["[AD]"]}
                                    onChange={(event) => adjustResults("[AD]", event.target.value)}
                                    inputProps={{
                                        style: {
                                            padding: '4px 8px',
                                            width: '20px',
                                            textAlign: 'center'
                                        },
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*'
                                    }}
                                    variant="outlined"
                                    size="small"
                                />
                                <Typography sx={{ textAlign: 'center' }}>{(parseInt(results['[AD]']) || 0) - (parseInt(spentResults['[AD]']) || 0)}</Typography>
                            </Grid>
                            <Grid item sx={{ paddingLeft: "5px" }}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: MarkedText.renderer(" [TR] ")
                                    }}
                                />
                            </Grid>
                            <Grid item sx={{ paddingLeft: "5px" }}>
                                <TextField
                                    type="text"
                                    value={results["[TR]"]}
                                    onChange={(event) => adjustResults("[TR]", event.target.value)}
                                    inputProps={{
                                        style: {
                                            padding: '4px 8px',
                                            width: '20px',
                                            textAlign: 'center'
                                        },
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*'
                                    }}
                                    variant="outlined"
                                    size="small"
                                />
                                <Typography sx={{ textAlign: 'center' }}>{(parseInt(results['[TR]']) || 0) - (parseInt(spentResults['[TR]']) || 0)}</Typography>
                            </Grid>
                            <Grid item sx={{ paddingLeft: "5px" }}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: MarkedText.renderer(" [TH] ")
                                    }}
                                />
                            </Grid>
                            <Grid item sx={{ paddingLeft: "5px" }}>
                                <TextField
                                    type="text"
                                    value={results["[TH]"]}
                                    onChange={(event) => adjustResults("[TH]", event.target.value)}
                                    inputProps={{
                                        style: {
                                            padding: '4px 8px',
                                            width: '20px',
                                            textAlign: 'center'
                                        },
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*'
                                    }}
                                    variant="outlined"
                                    size="small"
                                />
                                <Typography sx={{ textAlign: 'center' }}>{(parseInt(results['[TH]']) || 0) - (parseInt(spentResults['[TH]']) || 0)}</Typography>
                            </Grid>
                            <Grid item sx={{ paddingLeft: "5px" }}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: MarkedText.renderer(" [DE] ")
                                    }}
                                />
                            </Grid>
                            <Grid item sx={{ paddingLeft: "5px" }}>
                                <TextField
                                    type="text"
                                    value={results["[DE]"]}
                                    onChange={(event) => adjustResults("[DE]", event.target.value)}
                                    inputProps={{
                                        style: {
                                            padding: '4px 8px',
                                            width: '20px',
                                            textAlign: 'center'
                                        },
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*'
                                    }}
                                    variant="outlined"
                                    size="small"
                                />
                                <Typography sx={{ textAlign: 'center' }}>{(parseInt(results['[DE]']) || 0) - (parseInt(spentResults['[DE]']) || 0)}</Typography>
                            </Grid>
                            {(selectedTemplate.TempType === "Potion" || selectedTemplate.TempType === "Talisman") && <>
                                <Grid item sx={{ paddingLeft: "5px" }}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: MarkedText.renderer(" [LI] ")
                                        }}
                                    />
                                </Grid>
                                <Grid item sx={{ paddingLeft: "5px" }}>
                                    <TextField
                                        type="text"
                                        value={results["[LI]"]}
                                        onChange={(event) => adjustResults("[LI]", event.target.value)}
                                        inputProps={{
                                            style: {
                                                padding: '4px 8px',
                                                width: '20px',
                                                textAlign: 'center'
                                            },
                                            inputMode: 'numeric',
                                            pattern: '[0-9]*'
                                        }}
                                        variant="outlined"
                                        size="small"
                                    />
                                    <Typography sx={{ textAlign: 'center' }}>{(parseInt(results['[LI]']) || 0) - (parseInt(spentResults['[LI]']) || 0)}</Typography>
                                </Grid>
                                <Grid item sx={{ paddingLeft: "5px" }}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: MarkedText.renderer(" [DA] ")
                                        }}
                                    />
                                </Grid>
                                <Grid item sx={{ paddingLeft: "5px" }}>
                                    <TextField
                                        type="text"
                                        value={results["[DA]"]}
                                        onChange={(event) => adjustResults("[DA]", event.target.value)}
                                        inputProps={{
                                            style: {
                                                padding: '4px 8px',
                                                width: '20px',
                                                textAlign: 'center'
                                            },
                                            inputMode: 'numeric',
                                            pattern: '[0-9]*'
                                        }}
                                        variant="outlined"
                                        size="small"
                                    />
                                    <Typography sx={{ textAlign: 'center' }}>{(parseInt(results['[DA]']) || 0) - (parseInt(spentResults['[DA]']) || 0)}</Typography>
                                </Grid>
                            </>}
                            {selectedTemplate.TemplateSkill && <Grid item>
                                <FormControl fullWidth sx={{ mt: 2, minWidth: "200px", paddingLeft: "20px", marginTop: "0px" }}>
                                    <InputLabel>Skill</InputLabel>
                                    <Select value={templateSkill} onChange={(e) => handleTemplateSkill(e.target.value)}>
                                        {skills.filter((s) => s.type === "General").map(skill => (
                                            <MenuItem key={skill.Key} value={skill.skill}>{skill.skill}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>}
                            {(() => {
                                const index = filteredResults.findIndex((o) => o.Key === "IMPARMSPECEMB");
                                return (
                                    index !== -1 &&
                                    selectedOptions[index] !== undefined &&
                                    selectedOptions[index] > 0 && (
                                        <Grid item>
                                            <FormControl fullWidth sx={{ mt: 2, minWidth: "200px", paddingLeft: "20px", marginTop: "0px" }}>
                                                <InputLabel>Special Embellishment Skill</InputLabel>
                                                <Select value={embSkill} onChange={(e) => handleEmbeleshment(e.target.value)}>
                                                    {skills
                                                        .filter((s) =>
                                                            filteredResults[index].SpecialEmb.SkillList.includes(s.Key)
                                                        )
                                                        .map(skill => (
                                                            <MenuItem key={skill.Key} value={skill.skill}>{skill.skill}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    )
                                );
                            })()}
                            {(() => {
                                const index = filteredResults.findIndex((o) => o.Key === "IMPCYBERINTTOOL");
                                return (
                                    index !== -1 &&
                                    selectedOptions[index] !== undefined &&
                                    selectedOptions[index] > 0 && (
                                        <Grid item>
                                            <FormControl fullWidth sx={{ mt: 2, minWidth: "200px", paddingLeft: "20px", marginTop: "0px" }}>
                                                <InputLabel>Integrated Tool Skill</InputLabel>
                                                <Select value={embSkill} onChange={(e) => handleTool(e.target.value)}>
                                                    {skills
                                                        .filter((s) => s.type === "General")
                                                        .map(skill => (
                                                            <MenuItem key={skill.Key} value={skill.skill}>{skill.skill}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    )
                                );
                            })()}
                            {(() => {
                                const index = filteredResults.findIndex((o) => o.Key === "IMPARMRESTR");
                                return (
                                    index !== -1 &&
                                    selectedOptions[index] !== undefined &&
                                    selectedOptions[index] > 0 && (
                                        <Grid item>
                                            <FormControl fullWidth sx={{ mt: 2, minWidth: "200px", paddingLeft: "20px", marginTop: "0px" }}>
                                                <InputLabel>Restrictive Skill</InputLabel>
                                                <Select value={restrictiveSkill} onChange={(e) => handleRestrictive(e.target.value)}>
                                                    {skills
                                                        .filter((s) =>
                                                            filteredResults[index].Restrictive.SkillList.includes(s.Key)
                                                        )
                                                        .map(skill => (
                                                            <MenuItem key={skill.Key} value={skill.skill}>{skill.skill}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    )
                                );
                            })()}
                            {(() => {
                                const index = filteredResults.findIndex((o) => o.Key === "IMPGADGCRAFT");
                                return (
                                    index !== -1 &&
                                    selectedOptions[index] !== undefined &&
                                    selectedOptions[index] > 0 && (
                                        <Grid item>
                                            <FormControl fullWidth sx={{ mt: 2, minWidth: "200px", paddingLeft: "20px", marginTop: "0px" }}>
                                                <InputLabel>Supreme Craftsmanship Skill</InputLabel>
                                                <Select value={restrictiveSkill} onChange={(e) => handleSupremeCraft(e.target.value)}>
                                                    {skills
                                                        .filter((s) => s.type === "General")
                                                        .map(skill => (
                                                            <MenuItem key={skill.Key} value={skill.skill}>{skill.skill}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    )
                                );
                            })()}
                            {(() => {
                                const index = filteredResults.findIndex((o) => o.Key === "IMPLTSABERINBUILT" || o.Key === "IMPGADGINWEAP" || o.Key === "IMPCYBERINWEAP");
                                return (
                                    index !== -1 &&
                                    selectedOptions[index] !== undefined &&
                                    selectedOptions[index] > 0 && (
                                        <Grid item>
                                            <FormControl fullWidth sx={{ mt: 2, minWidth: "200px", paddingLeft: "20px", marginTop: "0px" }}>
                                                <InputLabel>Inbuilt Item</InputLabel>
                                                <Select value={inbuiltItem} onChange={(e) => handleInbuilt(e.target.value)}>
                                                    {inbuiltOptions
                                                        .map(item => (
                                                            <MenuItem key={item.Key} value={item}>{item.Name}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    )
                                );
                            })()}
                            {showIntAttach && <Grid item>
                                <FormControl fullWidth sx={{ mt: 2, minWidth: "200px", paddingLeft: "20px", marginTop: "0px" }}>
                                    <InputLabel>Integrated Attachment</InputLabel>
                                    <Select value={selectedIntAttach} onChange={(e) => handleIntegratedAttachment(e.target.value)}>
                                        {integralAttachments.filter((a) => a.Type === (itemCategory === "Armor" ? "Armor" : "Weapon")).map(attach => (
                                            <MenuItem key={attach.Key} value={attach}>{attach.Name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>}
                        </Grid>
                        <TableContainer
                            component={Paper}
                            style={{
                                maxHeight: "calc(100vh - 40.5vh)",
                                overflowY: "auto",
                            }}
                        >
                            <Table stickyHeader size="small">
                                <TableHead>
                                    <TableRow sx={{ padding: "1px" }}>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell sx={{ textAlign: "center" }}>Cost</TableCell>
                                        <TableCell sx={{ textAlign: "center" }}>Count</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredResults.map((option, index) => (
                                        (selectedOptions[index] > 0 ||
                                            (parseInt(option.CostAdvThr) > 0 && parseInt(option.CostAdvThr) <= parseInt(results['[AD]']) - (spentResults['[AD]'] || 0)) ||
                                            (parseInt(option.CostAdvThr) < 0 && Math.abs(parseInt(option.CostAdvThr)) <= results['[TH]'] - (spentResults['[TH]'] || 0)) ||
                                            (parseInt(option.CostTriDes) > 0 && parseInt(option.CostTriDes) <= parseInt(results['[TR]']) - (spentResults['[TR]'] || 0)) ||
                                            (parseInt(option.CostTriDes) < 0 && Math.abs(parseInt(option.CostTriDes)) <= parseInt(results['[DE]']) - (spentResults['[DE]'] || 0)) ||
                                            (parseInt(option.CostLightDark) > 0 && parseInt(option.CostLightDark) <= parseInt(results['[LI]']) - (spentResults['[LI]'] || 0)) ||
                                            (parseInt(option.CostLightDark) < 0 && Math.abs(parseInt(option.CostLightDark)) <= parseInt(results['[DA]']) - (spentResults['[DA]'] || 0)))
                                        && <TableRow key={option.Key}>
                                            <TableCell>{option.Name}</TableCell>
                                            <TableCell>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: MarkedText.renderer(option.Description)
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ textAlign: "center" }}>
                                                {option.CostAdvThr !== "0" && <Button
                                                    size="small"
                                                    onClick={() => handleOptionAdd(index, parseInt(option.CostAdvThr), "AdvThr")}
                                                    disabled={
                                                        (option.SelectCount && parseInt(option.SelectCount) === selectedOptions[index]) ||
                                                        (parseInt(option.CostAdvThr) > 0 && availableSymbol('[AD]') < parseInt(option.CostAdvThr)) ||
                                                        (parseInt(option.CostAdvThr) < 0 && availableSymbol('[TH]') < Math.abs(parseInt(option.CostAdvThr))) ||
                                                        option.LimitedAmmoGood && !selectedTemplate.WeaponProfile.Qualities?.find(q => q.Key === "LIMITEDAMMO")
                                                    }>

                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: MarkedText.renderer(getCostString(option.CostAdvThr, 0, 0)
                                                            )
                                                        }}
                                                    />
                                                </Button>}
                                                <Button
                                                    size="small"
                                                    onClick={() => handleOptionAdd(index, parseInt(option.CostTriDes), "TriDes")}
                                                    disabled={
                                                        (option.SelectCount && parseInt(option.SelectCount) === selectedOptions[index]) ||
                                                        (parseInt(option.CostTriDes) > 0 && availableSymbol('[TR]') < parseInt(option.CostTriDes)) ||
                                                        (parseInt(option.CostTriDes) < 0 && availableSymbol('[DE]') < Math.abs(parseInt(option.CostTriDes))) ||
                                                        option.LimitedAmmoGood && !selectedTemplate.WeaponProfile.Qualities?.find(q => q.Key === "LIMITEDAMMO")

                                                    }>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: MarkedText.renderer(getCostString(0, option.CostTriDes, 0)
                                                            )
                                                        }}
                                                    />
                                                </Button>
                                                {option.CostLightDark !== undefined && <Button
                                                    variant="contained"
                                                    size="small"
                                                    onClick={() => handleOptionAdd(index, parseInt(option.CostLightDark), "LightDark")}
                                                    disabled={
                                                        (option.SelectCount && parseInt(option.SelectCount) === selectedOptions[index]) ||
                                                        (parseInt(option.CostLightDark) > 0 && availableSymbol('[LI]') < parseInt(option.CostLightDark)) ||
                                                        (parseInt(option.CostLightDark) < 0 && availableSymbol('[DA]') < Math.abs(parseInt(option.CostLightDark)))

                                                    }>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: MarkedText.renderer(getCostString(0, 0, (option.CostLightDark || "0"))
                                                            )
                                                        }}
                                                    />
                                                </Button>}
                                            </TableCell>
                                            <TableCell>
                                                <Button size="small" onClick={() => handleOptionRemove(index)} disabled={!selectedOptions[index] > 0}>-</Button>
                                                {selectedOptions[index] || 0}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}
                {template && <Grid container>
                    <Grid item xs={12} sm={9}>
                        <TextField
                            fullWidth
                            label="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            sx={{ mt: 2 }}
                        />
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            label="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            sx={{ mt: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        {selectedTemplate.ArmorProfile && <div style={{ padding: "15px" }}>
                            <Typography>Encumbrance: {selectedTemplate.ArmorProfile.Encumbrance}</Typography>
                            <Typography>Hard Points: {selectedTemplate.ArmorProfile.HP}</Typography>
                            <Typography>Soak: {selectedTemplate.ArmorProfile.Soak}</Typography>
                            <Typography>Defense: {selectedTemplate.ArmorProfile.Defense}</Typography>
                        </div>}
                        {selectedTemplate.WeaponProfile && <div style={{ padding: "15px" }}>
                            <Typography>Encumbrance: {selectedTemplate.WeaponProfile.Encumbrance}</Typography>
                            <Typography>Hard Points: {selectedTemplate.WeaponProfile.HP}</Typography>
                            <Typography>Skill: {skills.find((s) => s.Key === selectedTemplate.WeaponProfile.SkillKey).skill}</Typography>
                            <Typography>Damage: {selectedTemplate.WeaponProfile.Damage || "+" + selectedTemplate.WeaponProfile.DamageAdd}</Typography>
                            <Typography>Crit: {selectedTemplate.WeaponProfile.Crit}</Typography>
                            <Typography>Range: {selectedTemplate.WeaponProfile.RangeValue}</Typography>
                            {selectedTemplate.WeaponProfile.Qualities?.length > 0 && <Typography>{qualitiesString(selectedTemplate.WeaponProfile.Qualities)}</Typography>}
                        </div>}
                        {selectedTemplate.GearProfile && <div style={{ padding: "15px" }}>
                            <Typography>Encumbrance: {selectedTemplate.GearProfile.Encumbrance}</Typography>
                        </div>}
                        {selectedTemplate.GearProfile?.BaseMods && Array.isArray(selectedTemplate.GearProfile.BaseMods) && (
                            <div style={{ padding: "15px" }}>
                                {selectedTemplate.GearProfile.BaseMods.map((mod, index) => (
                                    (
                                        mod.MiscDesc && <div
                                            key={index}
                                            dangerouslySetInnerHTML={{
                                                __html: MarkedText.renderer(skillModString(mod.MiscDesc))
                                            }}
                                        />
                                    )
                                ))}
                            </div>
                        )}
                        {selectedTemplate.GearProfile?.BaseMods && selectedTemplate.GearProfile.BaseMods.MiscDesc && <div style={{ padding: "15px" }}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: MarkedText.renderer(skillModString(selectedTemplate.GearProfile.BaseMods.MiscDesc))
                                }} />
                        </div>}
                        {selectedTemplate.GearProfile?.Mod && selectedTemplate.GearProfile.Mod.MiscDesc && <div style={{ padding: "15px" }}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: MarkedText.renderer(skillModString(selectedTemplate.GearProfile.Mod.MiscDesc))
                                }}
                            />
                        </div>}
                        {selectedTemplate.BaseMods && <div style={{ padding: "15px" }}>
                            {selectedTemplate.BaseMods.map((mod, index) => (
                                <div key={mod.Key + index}>
                                    {mod.MiscDesc && (<div
                                        style={{ padding: "15px" }}
                                        dangerouslySetInnerHTML={{
                                            __html: MarkedText.renderer(mod.MiscDesc),
                                        }}
                                    />)}
                                    {mod.Key && (
                                        <div
                                            style={{ padding: "15px" }}
                                            dangerouslySetInnerHTML={{
                                                __html: MarkedText.renderer(getModText(qualities, talents, skills, mod)),
                                            }}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>}
                        {(() => {
                            const profile = selectedTemplate.ArmorProfile || selectedTemplate.WeaponProfile;
                            if (!profile || (!profile.Attachments && !profile.BaseMods)) return null;
                            if (profile.BaseMods && !Array.isArray(profile.BaseMods)) { profile.BaseMods = [profile.BaseMods] }
                            return (
                                <>
                                    {profile.BaseMods && (
                                        <Typography sx={{ paddingLeft: "15px" }}>
                                            Base Modifiers:{" "}
                                            {profile.BaseMods.map(
                                                (mod, index) => (
                                                    <div key={index}>
                                                        <div
                                                            style={{ padding: "5px" }}
                                                            dangerouslySetInnerHTML={{
                                                                __html: MarkedText.renderer(
                                                                    mod.MiscDesc,
                                                                ),
                                                            }}
                                                        />
                                                        {mod.Key && (
                                                            <div
                                                                style={{ padding: "5px" }}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: MarkedText.renderer(
                                                                        qualities.find(
                                                                            (q) => q.Key === mod.Key,
                                                                        )
                                                                            ? (Math.abs(mod.Count) || "") +
                                                                            " " +
                                                                            qualities.find(
                                                                                (q) => q.Key === mod.Key,
                                                                            ).ModDesc +
                                                                            (mod.Count < 0
                                                                                ? " -1"
                                                                                : "") +
                                                                            (mod.Count > 1 ||
                                                                                mod.Count < -1
                                                                                ? " mods"
                                                                                : " mod")
                                                                            : talents.find(
                                                                                (t) => t.Key === mod.Key,
                                                                            )
                                                                                ? "Innate Talent: " +
                                                                                talents.find(
                                                                                    (t) => t.Key === mod.Key,
                                                                                ).Name
                                                                                : skills.find(
                                                                                    (s) =>
                                                                                        s.Key === mod.Key,
                                                                                )
                                                                                    ? mod.Count +
                                                                                    " Skill: " +
                                                                                    skills.find(
                                                                                        (s) =>
                                                                                            s.Key === mod.Key,
                                                                                    ).skill +
                                                                                    (mod.Count > 1
                                                                                        ? " mods"
                                                                                        : " mod")
                                                                                    : "",
                                                                    ),
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                ),
                                            )}
                                        </Typography>
                                    )}
                                    {profile.Attachments && profile.Attachments[0] && (
                                        <Typography variant="h6">Attachments</Typography>
                                    )}
                                    {profile.Attachments && profile.Attachments.map((attachment, attachI) => (
                                        <Paper key={attachment.Key + attachI} variant="outlined">
                                            <Typography sx={{ mt: "5px" }}>
                                                {attachment.Name}
                                            </Typography>
                                            <Typography sx={{ mt: "5px" }}>
                                                HP: {attachment.HP}
                                            </Typography>
                                            {attachment.BaseMods && (
                                                <Typography sx={{ mt: "5px" }}>
                                                    Base Modifiers:{" "}
                                                    {attachment.BaseMods.map((mod, index) => (
                                                        <div key={mod.Key + index}>
                                                            <div
                                                                style={{ padding: "5px" }}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: MarkedText.renderer(mod.MiscDesc),
                                                                }}
                                                            />
                                                            {mod.Key && (
                                                                <div
                                                                    style={{ padding: "5px" }}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: MarkedText.renderer(getModText(qualities, talents, skills, mod)),
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                    ))}
                                                </Typography>
                                            )}
                                            {attachment.AddedMods && (
                                                <Typography sx={{ mt: "5px" }}>
                                                    Modification Options:{" "}
                                                    {attachment.AddedMods.map((mod, index) => (
                                                        <div key={mod.Key + index}>
                                                            <div
                                                                style={{ padding: "5px" }}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: MarkedText.renderer(mod.MiscDesc),
                                                                }}
                                                            />
                                                            {mod.Key && (
                                                                <div
                                                                    style={{ padding: "5px" }}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: MarkedText.renderer(getModText(qualities, talents, skills, mod)),
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                    ))}
                                                </Typography>
                                            )}
                                        </Paper>
                                    ))}
                                </>
                            );
                        })()}
                    </Grid>
                </Grid>}
                <Button onClick={handleSubmit} sx={{ mt: 2 }} disabled={!template}>
                    Craft Item
                </Button>
            </Box>
        </Modal >
    );
};

export default CraftingModal;