const formatSource = (source) => {
    if (typeof source === 'string') {
        return `Source: ${source}`;
    } else if (Array.isArray(source)) {
        const formattedSources = source.map(s => {
            if (typeof s === 'string') {
                return s;
            } else if (typeof s === 'object' && s !== null) {
                return `${s.__text || ''}${s._Page ? `, Page ${s._Page}` : ''}`;
            }
            return 'Unknown';
        });
        return `Sources: ${formattedSources.join('; ')}`;
    } else if (typeof source === 'object' && source !== null) {
        return `Source: ${source.__text || ''}${source._Page ? `, Page ${source._Page}` : ''}`;
    }
    return 'Source: Unknown';
};

export default formatSource;
