import React, { useState, useEffect } from "react";
import {
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Autocomplete,
  TextField,
  Button,
  Checkbox
} from "@mui/material";
import speciesData from "./data/species.json";
import talents from "./data/talents.json";
import skills from "./data/skills.json";
import MarkedText from "./Util/MarkedText";
import formatSource from "./Util/FormatSource";
import StatForm from "./Util/StatForm";

let speciesList = speciesData

function DisplaySkillTraining({ skillTraining, character, handleCharacterChange }) {
  const [selectedSkills, setSelectedSkills] = useState(character.Species.SelectedSkills || []);

  useEffect(() => {
    // Load saved skills when component mounts
    if (character.Species.SelectedSkills) {
      setSelectedSkills(character.Species.SelectedSkills);
    }
  }, [character.Species.SelectedSkills]);

  let trainingCategory = [...skills];
  if (skillTraining.Requirement) {
    if (skillTraining.Requirement.SkillType && skillTraining.Requirement.SkillType !== "All") {
      trainingCategory = [...skills].filter((skill) => skill.type === skillTraining.Requirement.SkillType);
    }
    let careerSkills = character.CareerSkills.concat(character.SpecSkills, character.ExtraCareerSkills);
    if (skillTraining.Requirement.Career) {
      trainingCategory = [...skills].filter((skill) => careerSkills.includes(skill.skill));
    }
    if (skillTraining.Requirement.NonCareer) {
      trainingCategory = [...skills].filter((skill) => !careerSkills.includes(skill.skill));
    }
  }

  const handleCheckboxChange = (skillName) => {
    let updatedSkills;
    if (selectedSkills.includes(skillName)) {
      updatedSkills = selectedSkills.filter(skill => skill !== skillName);
    } else {
      if (selectedSkills.length < parseInt(skillTraining.SkillCount)) {
        updatedSkills = [...selectedSkills, skillName];
      } else {
        return; // Don't add if we've reached the limit
      }
    }
    setSelectedSkills(updatedSkills);
    handleCharacterChange({
      Species: {
        ...character.Species,
        SelectedSkills: updatedSkills
      }
    });
  };

  return (
    <>
      {character.Species.Key !== "DROID" && trainingCategory.map((skill) => (
        <Grid container key={skill.skill}>
          <Grid item>
            <Checkbox
              size="small"
              sx={{ padding: "1px" }}
              checked={selectedSkills.includes(skill.skill)}
              onChange={() => handleCheckboxChange(skill.skill)}
              disabled={!selectedSkills.includes(skill.skill) && selectedSkills.length >= parseInt(skillTraining.SkillCount)}
            />
          </Grid>
          <Grid item>
            <Typography>{skill.skill}</Typography>
          </Grid>
        </Grid>
      ))}
    </>
  );
}

function DisplayOptions({
  character,
  handleCharacterChange,
  OptionChoices,
  SubSpeciesOption,
  SubSpecies,
}) {
  let optionState = "";
  let characterOptions = [];
  if (SubSpeciesOption) {

    characterOptions = character.Species.SubSpeciesList.find(
      (ss) => ss.Key === SubSpecies,
    ).OptionChoices;
  } else {
    characterOptions = character.Species.OptionChoices;
  }
  if (!OptionChoices) {
  } else if (Array.isArray(characterOptions)) {
    optionState = [];
    for (let o in characterOptions) {
      if (characterOptions[o].Selected !== undefined) {
        optionState.push(characterOptions[o].Selected);
      } else {
        optionState.push("");
      }
    }
  } else if (characterOptions && Array.isArray(characterOptions.Options)) {
    if (characterOptions.Selected !== undefined) {
      optionState = characterOptions.Selected;
    } else {
      optionState = "";
    }
  }
  const [selectedSpeciesOption, setSelectedSpeciesOption] =
    useState(optionState);
  const handleSpeciesOptionChange = (index, selectedValue) => {
    let xpAdjust = 0;
    let xpAdjustRefund = -character.Species.StartingXPAdjust || 0;
    if (Array.isArray(characterOptions)) {
      if (Array.isArray(characterOptions[index].Options) && characterOptions[index].Options.find((o) => o.Key === selectedValue).StartingAttributes) {
        xpAdjust += parseInt(characterOptions[index].Options.find((o) => o.Key === selectedValue).StartingAttributes.Experience) || 0;
      }
      setSelectedSpeciesOption((prevSelectedOptions) => ({
        ...prevSelectedOptions,
        [index]: selectedValue,
      }));
    } else {
      if (Array.isArray(characterOptions.Options) && characterOptions.Options.find((o) => o.Key === selectedValue).StartingAttributes) {
        xpAdjust += parseInt(characterOptions.Options.find((o) => o.Key === selectedValue).StartingAttributes.Experience) || 0;
      }
      setSelectedSpeciesOption(selectedValue);
    }
    let Species;
    if (!SubSpeciesOption) {
      if (characterOptions.Name) {
        Species = {
          ...character.Species,
          OptionChoices: {
            ...character.Species.OptionChoices,
            Selected: selectedValue,
          },
          StartingXPAdjust: xpAdjust,
          SelectedSkills: []
        };
      } else if (characterOptions[1]) {
        const updatedOptionChoices = characterOptions.map(
          (choice, i) => {
            if (index === i) {
              return { ...choice, Selected: selectedValue };
            }
            return choice;
          },
        );
        Species = {
          ...character.Species,
          OptionChoices: updatedOptionChoices,
          StartingXPAdjust: xpAdjust,
          SelectedSkills: []
        };
      } else if (characterOptions.Options.Name) {
        Species = {
          ...character.Species,
          OptionChoices: {
            ...character.Species.OptionChoices,
            Options: {
              ...character.Species.OptionChoices.Options,
              Selected: selectedValue,
            },
          },
          StartingXPAdjust: xpAdjust,
          SelectedSkills: []
        };
      }
    } else {
      const updatedOptionChoices = character.Species.SubSpeciesList.find(
        (ss) => ss.Key === SubSpecies,
      ).OptionChoices.map((option, i) => {
        if (index === i) {
          return { ...option, Selected: selectedValue };
        }
        return option;
      });
      Species = {
        ...character.Species,
        SubSpeciesList: [
          ...character.Species.SubSpeciesList.map((ss, i) => {
            if (SubSpecies === character.Species.SubSpeciesList[i].Key) {
              return { ...ss, OptionChoices: updatedOptionChoices };
            }
            return ss;
          }),
        ],
        SelectedSkills: []
      };
    }
    handleCharacterChange({
      Species,
      XP: character.XP + xpAdjustRefund + xpAdjust
    });
  };
  let descriptions = [];
  if (!OptionChoices) {
    return;
  } else if (Array.isArray(OptionChoices)) {
    descriptions = OptionChoices.map((item, index) => (
      <div key={item.Key}>
        <Typography key={index}>
          <b>{item.Options.Name}</b>
          <br />
        </Typography>
        <span
          dangerouslySetInnerHTML={{
            __html: MarkedText.renderer(item.Options.Description),
          }}
        />
        {item.Options.StartingSkillTraining && (
          <DisplaySkillTraining skillTraining={item.Options.StartingSkillTraining.SkillTraining} character={character} handleCharacterChange={handleCharacterChange} />
        )}
        {item.Options[1] && (
          <FormControl fullWidth>
            <InputLabel>{item.Name}</InputLabel>
            <Select
              value={selectedSpeciesOption[index] || ""}
              name={item.Name}
              onChange={(e) => handleSpeciesOptionChange(index, e.target.value)}
            >
              {item.Options.map((subItem, subIndex) => (
                <MenuItem key={subItem.Key} value={subItem.Key}>
                  <b>{subItem.Name}</b>
                </MenuItem>
              ))}
            </Select>
            {character.Species.OptionChoices[0] && item.Options.find((o) => o.Key === character.Species.OptionChoices[0].Selected) && (
              <>
                <span
                  dangerouslySetInnerHTML={{
                    __html: MarkedText.renderer(
                      item.Options.find((o) => o.Key === character.Species.OptionChoices[0].Selected).Description,
                    ),
                  }}
                />
                {item.Options.find((o) => o.Key === character.Species.OptionChoices[0].Selected).StartingSkillTraining && (
                  <DisplaySkillTraining skillTraining={item.Options.find((o) => o.Key === character.Species.OptionChoices[0].Selected).StartingSkillTraining.SkillTraining} character={character} handleCharacterChange={handleCharacterChange} />
                )}
              </>)}
          </FormControl>
        )}
      </div>
    ));
  } else if (Array.isArray(OptionChoices.Options)) {
    let chosen = {}
    if (selectedSpeciesOption) {
      chosen = OptionChoices.Options.find(
        (option) => option.Key === selectedSpeciesOption
      )
    }
    descriptions = (
      <FormControl fullWidth>
        <InputLabel>Select Species Option</InputLabel>
        <Select
          value={selectedSpeciesOption || ""}
          onChange={(e) =>
            handleSpeciesOptionChange(OptionChoices.Key, e.target.value)
          }
        >
          {OptionChoices.Options.map((item, index) => (
            <MenuItem key={item.Key} value={item.Key}>
              <b>{item.Name}</b>
            </MenuItem>
          ))}
        </Select>
        {chosen && (
          <>
            <span
              dangerouslySetInnerHTML={{
                __html: MarkedText.renderer(
                  chosen.Description
                ),
              }}
            />
            {chosen.StartingSkillTraining && (
              <DisplaySkillTraining
                skillTraining={chosen.StartingSkillTraining.SkillTraining}
                character={character}
                handleCharacterChange={handleCharacterChange}
              />
            )}
          </>
        )}
      </FormControl>
    );
  } else if (OptionChoices.Description) {
    descriptions = (
      <>
        <Typography>
          <b>{OptionChoices.Name}:</b>
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: MarkedText.renderer(OptionChoices.Description),
            }}
          />
        </Typography>
        {OptionChoices.StartingSkillTraining && (
          <DisplaySkillTraining skillTraining={OptionChoices.StartingSkillTraining.SkillTraining} character={character} handleCharacterChange={handleCharacterChange} />
        )}
      </>
    );
  } else if (OptionChoices.Options.Description) {
    descriptions = (<>
      <Typography>
        <b>{OptionChoices.Options.Name}:</b>
        <br />
        <span
          dangerouslySetInnerHTML={{
            __html: MarkedText.renderer(OptionChoices.Options.Description),
          }}
        />
      </Typography>
      {OptionChoices.Options.StartingSkillTraining && (
        <DisplaySkillTraining skillTraining={OptionChoices.Options.StartingSkillTraining.SkillTraining} character={character} handleCharacterChange={handleCharacterChange} />
      )}
    </>);
  }
  return <div>{descriptions}</div>;
}

function DisplaySubSpecies({
  SubSpeciesList,
  character,
  handleCharacterChange,
  setSelectedSpecies
}) {
  const handleSubSpeciesChange = (event) => {
    let newSpecies = { ...character.Species, SubSpecies: event.target.value }
    setSelectedSpecies(newSpecies)
    handleCharacterChange({
      Species: newSpecies,
    });
  };
  let descriptions = [];
  if (SubSpeciesList === undefined) {
    return;
  } else if (Array.isArray(SubSpeciesList)) {
    descriptions = (
      <div>
        <FormControl fullWidth>
          <InputLabel>Select Sub-Species</InputLabel>
          <Select
            value={character.Species.SubSpecies || ""}
            onChange={handleSubSpeciesChange}
          >
            {SubSpeciesList.map((subSpecies, index) => (
              <MenuItem key={subSpecies.Key} value={subSpecies.Key}>
                {subSpecies.Name}
              </MenuItem>
            ))}
          </Select>
          {character.Species.SubSpecies && (
            <Typography>
              {
                SubSpeciesList.find(
                  (ss) => ss.Key === character.Species.SubSpecies,
                ).Description
              }
            </Typography>
          )}
          {SubSpeciesList.find(
            (ss) => ss.Key === character.Species.SubSpecies,
          ) && (
              <DisplayOptions
                character={character}
                handleCharacterChange={handleCharacterChange}
                OptionChoices={
                  character.Species.SubSpeciesList.find(
                    (ss) => ss.Key === character.Species.SubSpecies,
                  ).OptionChoices
                }
                SubSpeciesOption={true}
                SubSpecies={character.Species.SubSpecies}
              />
            )}
        </FormControl>
        {SubSpeciesList.find(
          (ss) => ss.Key === character.Species.SubSpecies,
        ) && (
            <div>
              <DisplaySkills
                SkillModifiers={
                  SubSpeciesList.find(
                    (ss) => ss.Key === character.Species.SubSpecies,
                  ).SkillModifiers
                }
              />
              <DisplayTalents
                TalentModifiers={
                  SubSpeciesList.find(
                    (ss) => ss.Key === character.Species.SubSpecies,
                  ).TalentModifiers
                }
              />{" "}
            </div>
          )}
      </div>
    );
  }
  return <div>{descriptions}</div>;
}

function DisplaySkills({ SkillModifiers }) {
  let descriptions = [];
  if (!SkillModifiers) {
    return;
  } else if (SkillModifiers.Key) {
    descriptions = (
      <Typography>
        Begins with {SkillModifiers.RankStart} rank in{" "}
        {skills.find((s) => s.Key === SkillModifiers.Key).skill}.{" "}
        They may not train this skill above {SkillModifiers.RankLimit || 2} ranks at
        creation.{" "}
      </Typography>
    );
  } else if (Array.isArray(SkillModifiers)) {
    descriptions = SkillModifiers.map((item, index) => (
      <div key={item.Key}>
        {item.Key && (
          <Typography>
            Begins with {item.RankStart} rank in{" "}
            {skills.find((s) => s.Key === item.Key).skill}. They may not train
            this skill above {item.RankLimit} ranks at creation.
          </Typography>
        )}
        {item.isCareer && (
          <Typography>
            Begin with all {item.SkillType} skills as career skills
          </Typography>
        )}
      </div>
    ));
  }
  return descriptions;
}

function DisplayTalents({ TalentModifiers }) {
  let descriptions = [];
  if (!TalentModifiers) {
    return;
  }
  if (TalentModifiers.Key) {
    descriptions = (
      <Typography>
        Begins with {TalentModifiers.RankAdd} rank in{" "}
        {talents.find((t) => t.Key === TalentModifiers.Key).Name}.
      </Typography>
    );
  } else if (Array.isArray(TalentModifiers)) {
    descriptions = TalentModifiers.map((item, index) => (
      <div key={item.Key}>
        <Typography>
          Begins with {item.RankAdd} rank in{" "}
          {talents.find((t) => t.Key === item.Key).Name}.
        </Typography>
      </div>
    ));
  }
  return descriptions;
}
function getSpecies(key, speciesList) {
  return speciesList.findIndex((s) => s.Key === key);
}
function App({ character, handleCharacterChange, appFiles }) {
  if (appFiles.species) {
    speciesList = JSON.parse(appFiles.species)
  }
  const [selectedSpecies, setSelectedSpecies] = useState(
    getSpecies(character.Species.Key, speciesList),
  );
  const [startingXp, setStartingXp] = useState(
    character.Species.StartingAttrs
      ? character.Species.StartingAttrs.Experience
      : 0,
  );
  const [selectedSpeciesData, setSelectedSpeciesData] = useState(
    speciesList[getSpecies(character.Species.Species, speciesList)],
  );
  const [minChars, setMinChars] = useState({
    Brawn: 0,
    Agility: 0,
    Intellect: 0,
    Cunning: 0,
    Willpower: 0,
    Presence: 0,
  });

  const handleChangeMinimum = (characteristic, value) => {
    let newMin = value >= 0 ? value : 0;
    newMin = newMin <= 4 ? newMin : 4;
    setMinChars({
      ...minChars,
      [characteristic]: newMin,
    });
  };

  const handleFilterOptions = (options, { inputValue }) => {
    return options.filter((option) => {
      if (!option.Name.toLowerCase().includes(inputValue.toLowerCase())) {
        return false;
      }
      for (const characteristic in minChars) {
        if (
          !option.StartingChars ||
          parseInt(option.StartingChars[characteristic], 10) <
          minChars[characteristic]
        ) {
          return false;
        }
      }
      return true;
    });
  };

  const handleSpeciesChange = (key) => {
    let charRebate = 0
    let currentChars = character.Characteristics
    let speciesChars = character.Species.StartingChars

    charRebate -= character.Species.StartingXPAdjust ? parseInt(character.Species.StartingXPAdjust) : 0
    for (const [key, value] of Object.entries(currentChars)) {

      if (key in speciesChars) {
        let currentCharValue = parseInt(value, 10);
        const speciesCharValue = parseInt(speciesChars[key], 10);

        while (currentCharValue > speciesCharValue) {
          charRebate += currentCharValue * 10;
          currentCharValue--;
        }
      }
    }

    if (!key) {
      handleCharacterChange({
        XP: parseInt(character.XP, 10) + parseInt(0) - startingXp,
        Species: {
          Species: "",
          StartingAttrs: "",
        },
        Characteristics: {},
        UsedStartingXP: character.Extras?.ShortPath ? 30 : 0
      });
      setSelectedSpecies("");
      setSelectedSpeciesData("");
      setStartingXp(0);
    } else {
      var index = getSpecies(key, speciesList);
      setSelectedSpecies(key);
      let data = speciesList[index];
      setSelectedSpeciesData(data);
      setStartingXp(data === undefined ? 0 : data.StartingAttrs.Experience);
      handleCharacterChange({
        XP:
          charRebate +
          parseInt(character.XP, 10) +
          parseInt(data === undefined ? 0 : data.StartingAttrs.Experience, 10) -
          startingXp,
        Species: {
          ...data,
          Species: key,
          SubSpecies: "",
        },
        Characteristics: data.StartingChars,
        UsedStartingXP: character.Extras?.ShortPath ? 30 : 0
      });
    }
  };

  return (
    <Container>
      <Typography variant="h3">Species</Typography>
      <Grid container spacing={2} alignItems="center" sx={{ mt: 2, mb: 2 }}>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Typography>Minimum Characteristics Filter: </Typography>
        </Grid>
        {Object.keys(minChars).map((characteristic) => (
          <Grid item key={characteristic} xs={4} sm={4} md={1} lg={1}>
            <TextField
              sx={{ width: '100%', maxWidth: "120px" }}
              label={characteristic}
              type="number"
              value={minChars[characteristic]}
              onChange={(e) =>
                handleChangeMinimum(
                  characteristic,
                  parseInt(e.target.value, 10),
                )
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        ))}
        <Grid item xs={12} sm={12} md={1} lg={1}>
          <Button
            fullWidth
            onClick={() =>
              setMinChars({
                Brawn: 0,
                Agility: 0,
                Intellect: 0,
                Cunning: 0,
                Willpower: 0,
                Presence: 0,
              })
            }
          >
            Clear
          </Button>
        </Grid>
      </Grid>
      <FormControl fullWidth>
        <Autocomplete
          disablePortal
          disabled={character.GMGrantMode}
          sx={{ paddingBottom: "10px" }}
          value={
            speciesList.some((option) => option.Key === selectedSpecies)
              ? selectedSpecies
              : null
          }
          onChange={(event, newValue, reason) => {
            handleSpeciesChange(newValue === null ? "" : newValue.Key);
            if (reason === 'selectOption') {
              const input = document.querySelector('.MuiAutocomplete-input');
              if (input) {
                input.blur();
              }
            }
          }}
          options={speciesList}
          getOptionLabel={(option) => option.Name || ""}
          isOptionEqualToValue={(option, value) => option.Key === value}
          renderInput={(params) => (
            <TextField {...params} label="Select Species" />
          )}
          filterOptions={handleFilterOptions}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <MenuItem
                selected={selected}
                component="div"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "1rem", // Add some spacing between the name and stats
                }}
              >
                <Grid container>
                  <Grid item xs={12} sm={2}>
                    {option.Name}
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <Card>
                      <CardMedia
                        height="30"
                        component="img"
                        className="speciesImage"
                        image={option.imageUrl}
                        title={option.Name}
                        sx={{ objectFit: "contain" }}
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {" "}
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Typography>
                      | {option.StartingChars.Brawn} |{" "}
                      {option.StartingChars.Agility} |{" "}
                      {option.StartingChars.Intellect} |{" "}
                      {option.StartingChars.Cunning} |{" "}
                      {option.StartingChars.Willpower} |{" "}
                      {option.StartingChars.Presence} |
                    </Typography>
                  </Grid>
                </Grid>
              </MenuItem>
            </li>
          )}
        />
      </FormControl>
      {selectedSpeciesData && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Card>
              <CardContent>
                <Typography variant="h4">{selectedSpeciesData.Name}</Typography>
                <Grid container>
                  <Grid item xs={4} lg={2}>
                    <StatForm title="Brawn" color="red" value={selectedSpeciesData.StartingChars.Brawn} sizes={["h6", "h4"]} />
                  </Grid>
                  <Grid item xs={4} lg={2}>
                    <StatForm title="Agility" color="red" value={selectedSpeciesData.StartingChars.Agility} sizes={["h6", "h4"]} />
                  </Grid>
                  <Grid item xs={4} lg={2}>
                    <StatForm title="Intellect" color="red" value={selectedSpeciesData.StartingChars.Intellect} sizes={["h6", "h4"]} />
                  </Grid>
                  <Grid item xs={4} lg={2}>
                    <StatForm title="Cunning" color="red" value={selectedSpeciesData.StartingChars.Cunning} sizes={["h6", "h4"]} />
                  </Grid>
                  <Grid item xs={4} lg={2}>
                    <StatForm title="Willpower" color="red" value={selectedSpeciesData.StartingChars.Willpower} sizes={["h6", "h4"]} />
                  </Grid>
                  <Grid item xs={4} lg={2}>
                    <StatForm title="Presence" color="red" value={selectedSpeciesData.StartingChars.Presence} sizes={["h6", "h4"]} />
                  </Grid>
                </Grid>
              </CardContent>
              <CardContent>
                <Typography variant="h7">
                  <b>Starting Experience:</b>{" "}
                  {selectedSpeciesData.StartingAttrs.Experience}
                </Typography>{" "}
                <br />
                <Typography variant="h7">
                  <b>Wound Threshold:</b>{" "}
                  {selectedSpeciesData.StartingAttrs.WoundThreshold} + Brawn
                </Typography>{" "}
                <br />
                <Typography variant="h7">
                  <b>Strain Threshold:</b>{" "}
                  {selectedSpeciesData.StartingAttrs.StrainThreshold} +
                  Willpower
                </Typography>
              </CardContent>
              <CardContent>
                <DisplaySkills
                  SkillModifiers={selectedSpeciesData.SkillModifiers}
                />
                <DisplayTalents
                  TalentModifiers={selectedSpeciesData.TalentModifiers}
                />
                <DisplayOptions
                  OptionChoices={selectedSpeciesData.OptionChoices}
                  character={character}
                  handleCharacterChange={handleCharacterChange}
                />
                <DisplaySubSpecies
                  SubSpeciesList={selectedSpeciesData.SubSpeciesList}
                  character={character}
                  handleCharacterChange={handleCharacterChange}
                  setSelectedSpecies={setSelectedSpecies}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardMedia
                height="600"
                component="img"
                className="speciesImage"
                image={selectedSpeciesData.imageUrl}
                title={selectedSpeciesData.Name}
                sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Card>
              <CardContent>
                <div
                  style={{ padding: "15px", fontSize: "0.8em" }}
                  dangerouslySetInnerHTML={{
                    __html: MarkedText.renderer(
                      selectedSpeciesData.Description,
                    ),
                  }}
                />
                <Typography>{formatSource(selectedSpeciesData.Source)}</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

export default App;
