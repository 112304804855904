import React, { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Typography,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  Button,
  Box,
  Switch,
  FormControlLabel,
  Autocomplete,
  TextField
} from "@mui/material";
import talentsData from "./data/talents.json"
import warriorRewards from "./data/warriorRewards.json"
import MarkedText from "./Util/MarkedText";
import formatSource from "./Util/FormatSource";

let talents = talentsData

function App({ character, handleCharacterChange, appFiles }) {
  const [shortPath, setShortPath] = useState(character.Extras?.ShortPath || false);
  const [battleScar, setBattleScar] = useState('');
  const [difficulty, setDifficulty] = useState('');
  const [battleScarCost, setBattleScarCost] = useState(0);
  const [filterScars, setFilterScars] = useState(true)
  const [reputation, setReputation] = useState(character.Extras?.Reputation || '0')
  const [ally, setAlly] = useState(character.Ally || "")
  const [apprentice, setApprentice] = useState(character.Apprentice || "")
  const [follower, setFollower] = useState(character.Follower || "")
  const [school, setSchool] = useState(character.School || "")
  const [title, setTitle] = useState(character.Title || {})

  useEffect(() => {
    if (appFiles.talents) {
      talents = JSON.parse(appFiles.talents)
    }
  }, [appFiles.talents]);

  const startingXP = () => {
    return (parseInt(character.Species.StartingAttrs?.Experience) || 0) +
      (character.Obligations[0].XP10 ? 10 : 0) +
      (character.Obligations[0].XP5 ? 5 : 0) +
      (character.Duties[0].XP10 ? 10 : 0) +
      (character.Duties[0].XP5 ? 5 : 0) +
      (character.Morality.XP10 ? 10 : 0) +
      (character.Morality.XPC ? 5 : 0)
  }

  const handleShortPathToggle = () => {
    let newExtras = { ...character.Extras || {} }
    newExtras.ShortPath = !shortPath
    setShortPath(!shortPath)
    handleCharacterChange({
      Extras: newExtras,
      XP: parseInt(character.XP) + (shortPath ? 30 : -30),
      UsedStartingXP: character.UsedStartingXP + (shortPath ? -30 : 30)
    })
  }

  const scarFilterToggle = () => {
    setFilterScars(!filterScars)
  }

  const handleBattleScarSelect = (key) => {
    setBattleScar(key);
  };

  const handleDifficultySelect = (event) => {
    setDifficulty(event.target.value);
    switch (event.target.value) {
      case 'Easy': setBattleScarCost(20); break;
      case 'Average': setBattleScarCost(15); break;
      case 'Hard': setBattleScarCost(10); break;
      case 'Daunting': setBattleScarCost(5); break;
      default: setBattleScarCost(0);
    }
  };

  const reputationSelect = (event) => {
    setReputation(event.target.value)
    let newExtras = { ...character.Extras || {} }
    newExtras.Reputation = event.target.value
    handleCharacterChange({ Extras: newExtras })
  }

  const confirmBattleScar = () => {
    if (battleScar && (difficulty || character.GMGrantMode)) {
      const updatedCharacter = { ...character };
      if (!updatedCharacter.Extras) updatedCharacter.Extras = {};
      if (!updatedCharacter.Extras.BattleScars) updatedCharacter.Extras.BattleScars = [];
      updatedCharacter.Extras.BattleScars.push({ key: battleScar, difficulty, cost: battleScarCost });
      updatedCharacter.XP -= battleScarCost
      handleCharacterChange(updatedCharacter);
      setBattleScar('');
      setDifficulty('');
      setBattleScarCost(0);
    }
  };

  const handleClearScar = (cost, index) => {
    let newExtras = { ...character.Extras }
    let newScars = [...character.Extras.BattleScars]
    newScars.splice(index, 1)
    newExtras.BattleScars = newScars
    handleCharacterChange({
      Extras: newExtras,
      XP: parseInt(character.XP || 0) + parseInt(cost)
    })
  }

  const getBattleScarTalents = () => {
    let validTalents = [...talents]
    if (filterScars) {
      validTalents = validTalents.filter((t) => ['DURA', 'COMMPRES', 'CONF', 'INTIM', 'STNERV', 'STRSMART', 'BLO', 'RAPREC', 'TOUGH', 'SECWIND', 'DEFSTA', 'DODGE', 'JUMP', 'SPARECL', 'TIME2GO'].includes(t.Key))
    }
    validTalents.sort((a, b) => a.Name.localeCompare(b.Name));
    return validTalents
  }

  const handleAllySelect = (event) => {
    const newAlly = event.target.value;
    let xpChange = ally ? newAlly.Cost - ally.Cost : parseInt(newAlly.Cost)
    setAlly(newAlly);
    handleCharacterChange({
      Ally: newAlly,
      XP: character.XP - xpChange
    });
  }

  const handleClearAlly = () => {
    setAlly("")
    handleCharacterChange({
      XP: character.XP + ally.Cost,
      Ally: ""
    })
  }

  const handleApprenticeSelect = (event) => {
    const newApprentice = event.target.value;
    let xpChange = apprentice ? newApprentice.Cost - apprentice.Cost : newApprentice.Cost
    setApprentice(newApprentice);
    handleCharacterChange({
      Apprentice: newApprentice,
      XP: character.XP - xpChange
    });
  }

  const handleClearApprentice = () => {
    setApprentice("")
    handleCharacterChange({
      XP: character.XP + apprentice.Cost,
      Apprentice: ""
    })
  }

  const handleFollowerSelect = (event) => {
    const newFollower = event.target.value;
    let xpChange = follower ? newFollower.Cost - follower.Cost : newFollower.Cost
    setFollower(newFollower);
    handleCharacterChange({
      Follower: newFollower,
      XP: character.XP - xpChange
    });
  }

  const handleClearFollower = () => {
    setFollower("")
    handleCharacterChange({
      XP: character.XP + follower.Cost,
      Follower: ""
    })
  }

  const handleSchoolSelect = (event) => {
    const newSchool = event.target.value;
    let xpChange = school ? newSchool.Cost - school.Cost : newSchool.Cost
    setSchool(newSchool);
    handleCharacterChange({
      School: newSchool,
      XP: character.XP - xpChange
    });
  }

  const handleClearSchool = () => {
    setSchool("")
    handleCharacterChange({
      XP: character.XP + school.Cost,
      School: ""
    })
  }

  const handleTitleTierSelect = (event) => {
    let newTitle = { ...title }
    newTitle.Tier = event.target.value
    let xpChange = title.Tier ? newTitle.Tier.Cost - title.Tier.Cost : newTitle.Tier.Cost
    setTitle(newTitle);
    handleCharacterChange({
      Title: newTitle,
      XP: character.XP - xpChange
    });
  }

  const handleTitleTypeSelect = (event) => {
    let newTitle = { ...title }
    newTitle.Type = event.target.value
    setTitle(newTitle);
    handleCharacterChange({ Title: newTitle });
  }

  const handleClearTitle = () => {
    setTitle({})
    handleCharacterChange({
      XP: character.XP + title.Tier.Cost,
      Title: ""
    })
  }

  const toggleGMGrant = () => {
    if (character.GMGrantMode) {
      handleCharacterChange({
        GMGrantMode: false
      })
    } else {
      handleCharacterChange({
        GMGrantMode: character.XP
      })
    }
  }

  return (
    <Container maxWidth="xl" sx={{ margin: "16px", width: '100%' }}>
      <Typography variant="h3" sx={{ padding: "15px" }}>
        Miscellaneous
      </Typography>
      <Typography>Optional, supplementary mechanics from across the product line.</Typography>
      <Grid container spacing={2} sx={{
        display: "flex",
        alignItems: "flex-start",
        width: '100%'
      }}>
        <Grid item xs={12} sm={6}>
          <Paper>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h5">Battle Scars</Typography>
              <Checkbox checked={filterScars} onChange={() => scarFilterToggle()} />
              <Typography>Use recommended talents</Typography>
            </Box>
            <Typography>Source: Forged in Battle, Page 95</Typography>
            <Autocomplete
              style={{ width: "100%" }}
              value={battleScar ? getBattleScarTalents().find(talent => talent.Key === battleScar) : null}
              disablePortal
              options={getBattleScarTalents()}
              getOptionLabel={(option) => option.Name || ""}
              renderInput={(params) => (
                <TextField {...params} label="Battle Scar Talent" />
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.Key}>
                  <MenuItem key={option.Key} selected={selected} component="div">
                    {option.Name}
                  </MenuItem>
                </li>
              )}
              onChange={(event, newValue) => handleBattleScarSelect(newValue ? newValue.Key : '')}
              isOptionEqualToValue={(option, value) => option.Key === value.Key}
            />
            {battleScar && <div
              style={{ padding: "15px" }}
              dangerouslySetInnerHTML={{
                __html: MarkedText.renderer(talents.find(t => t.Key === battleScar)?.Description),
              }}
            />}
            <FormControl fullWidth sx={{ mt: 1, paddingBottom: "10px" }}>
              <InputLabel>Critical Injury Difficulty</InputLabel>
              <Select
                value={difficulty}
                onChange={handleDifficultySelect}
                sx={{ minWidth: { xs: "100%", sm: "300px" } }}
              >
                <MenuItem value="Easy">Easy (20 XP)</MenuItem>
                <MenuItem value="Average">Average (15 XP)</MenuItem>
                <MenuItem value="Hard">Hard (10 XP)</MenuItem>
                <MenuItem value="Daunting">Daunting (5 XP)</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" onClick={confirmBattleScar} disabled={!battleScar || (!difficulty && !character.GMGrantMode)} >
              Confirm Battle Scar
            </Button>
            {character.Extras?.BattleScars && character.Extras?.BattleScars[0] && < Typography variant="h6">Acquired Scars:</Typography>}
            {character.Extras?.BattleScars && character.Extras.BattleScars.map((scar, index) => (
              <Paper variant="outlined">
                <Box sx={{ display: 'flex', alignItems: 'center', padding: '15px' }}>
                  <Typography>{talents.find((t) => t.Key === scar.key).Name}</Typography>
                  <Button onClick={() => handleClearScar(scar.cost, index)}>🞫</Button>
                </Box>
                <div
                  style={{ padding: "15px" }}
                  dangerouslySetInnerHTML={{
                    __html: MarkedText.renderer(talents.find((t) => t.Key === scar.key).Description),
                  }}
                />
              </Paper>
            ))}
          </Paper>
          <div style={{ height: "20px" }} />
          <Paper>
            <Typography variant="h5">Warrior Rewards</Typography>
            <Typography>{formatSource(warriorRewards.Source)}</Typography>
            <FormControl fullWidth sx={{ mt: 1, paddingBottom: "10px" }}>
              <InputLabel>Ally</InputLabel>
              <Select
                value={ally}
                onChange={handleAllySelect}
                sx={{ minWidth: { xs: "100%", sm: "300px" } }}
              >
                {warriorRewards.Allies.Tiers.map((allyTier, index) => (
                  <MenuItem key={index} value={allyTier}>
                    {allyTier.Name}, XP: {allyTier.Cost}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {ally &&
              <>
                <div
                  style={{ padding: "5px" }}
                  dangerouslySetInnerHTML={{
                    __html: MarkedText.renderer(warriorRewards.Allies.Benefit + ally.Dice),
                  }}
                />
                <Button onClick={handleClearAlly}>Clear</Button>
              </>
            }
            <FormControl fullWidth sx={{ mt: 1, paddingBottom: "10px" }}>
              <InputLabel>Apprentice</InputLabel>
              <Select
                value={apprentice}
                onChange={handleApprenticeSelect}
                sx={{ minWidth: { xs: "100%", sm: "300px" } }}
              >
                {warriorRewards.Apprentices.Tiers.map((apprenticeTier, index) => (
                  <MenuItem key={index} value={apprenticeTier}>
                    {apprenticeTier.Name}, XP: {apprenticeTier.Cost}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {apprentice &&
              <>
                <div
                  style={{ padding: "5px" }}
                  dangerouslySetInnerHTML={{
                    __html: MarkedText.renderer(apprentice.Benefit),
                  }}
                />
                <Button onClick={handleClearApprentice}>Clear</Button>
              </>
            }
            <FormControl fullWidth sx={{ mt: 1, paddingBottom: "10px" }}>
              <InputLabel>Follower</InputLabel>
              <Select
                value={follower}
                onChange={handleFollowerSelect}
                sx={{ minWidth: { xs: "100%", sm: "300px" } }}
              >
                {warriorRewards.Followers.Tiers.map((followerTier, index) => (
                  <MenuItem key={index} value={followerTier}>
                    {followerTier.Name}, XP: {followerTier.Cost}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {follower &&
              <>
                <div
                  style={{ padding: "5px" }}
                  dangerouslySetInnerHTML={{
                    __html: MarkedText.renderer(warriorRewards.Followers.Benefit + follower.Dice),
                  }}
                />
                <Button onClick={handleClearFollower}>Clear</Button>
              </>
            }
            <FormControl fullWidth sx={{ mt: 1, paddingBottom: "10px" }}>
              <InputLabel>School</InputLabel>
              <Select
                value={school}
                onChange={handleSchoolSelect}
                sx={{ minWidth: { xs: "100%", sm: "300px" } }}
              >
                {warriorRewards.Schools.Tiers.map((schoolTier, index) => (
                  <MenuItem key={index} value={schoolTier}>
                    {schoolTier.Name}, XP: {schoolTier.Cost}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {school &&
              <>
                <div
                  style={{ padding: "5px" }}
                  dangerouslySetInnerHTML={{
                    __html: MarkedText.renderer(school.Benefit),
                  }}
                />
                <Button onClick={handleClearSchool}>Clear</Button>
              </>
            }
            <FormControl fullWidth sx={{ mt: 1, paddingBottom: "10px" }}>
              <InputLabel>Honored Title</InputLabel>
              <Select
                value={title.Tier || ''}
                onChange={handleTitleTierSelect}
                sx={{ minWidth: { xs: "100%", sm: "300px" } }}
              >
                {warriorRewards.Titles.Tiers.map((titleTier, index) => (
                  <MenuItem key={index} value={titleTier}>
                    {titleTier.Name}, XP: {titleTier.Cost}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {title && <FormControl fullWidth sx={{ mt: 1, paddingBottom: "10px" }}>
              <InputLabel>Title Type</InputLabel>
              <Select
                value={title.Type || ''}
                onChange={handleTitleTypeSelect}
                sx={{ minWidth: { xs: "100%", sm: "300px" } }}
              >
                {warriorRewards.Titles.Types.map((titleType, index) => (
                  <MenuItem key={index} value={titleType}>
                    {titleType.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>}
            {title.Tier && title.Type &&
              <>
                <div
                  style={{ padding: "5px" }}
                  dangerouslySetInnerHTML={{
                    __html: MarkedText.renderer(title.Tier.Benefit.replace("applicable Social skill", title.Type.Skills)),
                  }}
                />
                <Button onClick={handleClearTitle}>Clear</Button>
              </>
            }
          </Paper>
        </Grid>
        <Grid item xs={12} sm="auto">
          <Paper>
            <Typography variant="h5">Short Path to Power</Typography>
            <Typography>Source: Collapse of the Republic, page 17</Typography>
            {(character.UsedStartingXP > startingXP()) && <Typography color="red" sx={{ paddingLeft: '30px' }}>Starting XP limit exceeded by {character.UsedStartingXP - startingXP()}</Typography>}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                checked={shortPath}
                onChange={handleShortPathToggle}
              />
              <Typography>Force Rating +1, 30 XP</Typography>
            </Box>
          </Paper>
          <div style={{ height: "20px" }} />
          <Paper>
            <Typography variant="h5">Reputation</Typography>
            <Typography>Source: Keeping the Peace, page 92 and Fly Casual, page 95</Typography>
            <FormControl fullWidth sx={{ mt: 1, paddingBottom: "10px" }}>
              <Select
                value={reputation}
                onChange={reputationSelect}
                sx={{ minWidth: { xs: "100%", sm: "300px" } }}
              >
                <MenuItem value='3'>{' '}3 - Beloved</MenuItem>
                <MenuItem value="2">{' '}2 - Renowned</MenuItem>
                <MenuItem value="1">{' '}1 - Respected</MenuItem>
                <MenuItem value="0">{' '}0 - Nuetral</MenuItem>
                <MenuItem value="-1">-1 - Feared</MenuItem>
                <MenuItem value="-2">-2 - Notorious</MenuItem>
                <MenuItem value="-3">-3 - Dreaded</MenuItem>
              </Select>
            </FormControl>
          </Paper>
          <div style={{ height: "20px" }} />
          <Paper>
            <Typography variant="h5">GM Grant Mode</Typography>
            <FormControlLabel control={<Switch disabled={!character.Species.Name} checked={character.GMGrantMode} onChange={() => toggleGMGrant()} />} label="Freeze all XP expenditures" />
          </Paper>
        </Grid>
      </Grid>
    </Container >
  );
};


export default App;
