
import characteristics from "../data/characteristics.json"
import findNestedProperty from "./FindNestedProperty"

const getCharacterModifiers = (character, skills, qualities) => {

    let modifiers = { Skills: {}, Chars: {}, Dice: {}, Stats: {}, Weapons: [] }

    const getSkillMod = (mod) => {
        if (modifiers.Skills[mod.Key]) {
            modifiers.Skills[mod.Key] = parseInt(modifiers.Skills[mod.Key]) + parseInt(mod.Count || 1)
        } else {
            modifiers.Skills[mod.Key] = (mod.Count || 1)
        }
    }

    const getDieModifiers = (mod) => {
        if (mod.DieModifier) {
            const dieModifiers = Array.isArray(mod.DieModifier)
                ? mod.DieModifier
                : [mod.DieModifier];

            dieModifiers.forEach(dieModifier => {
                const skillKey = dieModifier.SkillKey || dieModifier.SkillChar;

                if (!modifiers.Dice[skillKey]) {
                    modifiers.Dice[skillKey] = {};
                }

                Object.keys(dieModifier).forEach(key => {
                    if (key !== 'SkillKey' && key !== 'SkillChar') {
                        if (modifiers.Dice[skillKey][key]) {
                            modifiers.Dice[skillKey][key] = parseInt(modifiers.Dice[skillKey][key]) + parseInt(dieModifier[key] || 1);
                        } else {
                            modifiers.Dice[skillKey][key] = parseInt(dieModifier[key] || 1);
                        }
                    }
                });
            });
        }
    };

    const getQualityMod = (mod) => {
        if (modifiers.Stats[mod.Key]) {
            modifiers.Stats[mod.Key] = parseInt(modifiers.Stats[mod.Key]) + parseInt(mod.Count || 1)
        } else {
            modifiers.Stats[mod.Key] = (mod.Count || 1)
        }
    }

    const getCharMod = (mod) => {
        if (modifiers.Chars[mod.Key]) {
            modifiers.Chars[mod.Key] = parseInt(modifiers.Chars[mod.Key]) + parseInt(mod.Count || 1)
        } else {
            modifiers.Chars[mod.Key] = (mod.Count || 1)
        }
    }

    const modStats = (item) => {
        let statMods = findNestedProperty(item, 'BaseMods')
        let dieMods = findNestedProperty(item, 'DieModifiers')
        let integratedWeapon = findNestedProperty(item, 'WeaponModifiers')

        if (statMods) {
            for (let m in statMods) {
                let mod = statMods[m]
                if (mod.Key) {
                    if (skills.find((s) => s.Key === mod.Key)) {
                        getSkillMod(mod)
                    }
                    if (qualities.find((q) => q.Key === mod.Key)) {
                        getQualityMod(mod)
                    }
                    if (characteristics.find((c) => c.Key === mod.Key)) {
                        getCharMod(mod)
                    }
                }
                if (mod[0]) {
                    for (let sm in mod) {
                        let sMod = mod[sm]
                        if (sMod.Key) {
                            if (skills.find((s) => s.Key === sMod.Key)) {
                                getSkillMod(sMod)
                            }
                            if (qualities.find((q) => q.Key === sMod.Key)) {
                                getQualityMod(sMod)
                            }
                            if (characteristics.find((c) => c.Key === sMod.Key)) {
                                getCharMod(sMod)
                            }
                        }
                    }
                }
            }
        }

        if (dieMods) {
            for (let m in dieMods) {
                getDieModifiers(dieMods[m])
            }

        }

        if (integratedWeapon) { modifiers.Weapons = modifiers.Weapons.concat(integratedWeapon) }
    }

    for (let g in character.Gear) {
        let item = character.Gear[g]
        if (item.Equipped) { modStats(item) }
    }
    for (let a in character.Armor) {
        let item = character.Armor[a]
        if (item.Equipped) { modStats(item) }
    }
    for (let w in character.Weapons) {
        let item = character.Weapons[w]
        if (item.Equipped) { modStats(item) }
    }

    return modifiers
}

export default getCharacterModifiers