import React, { useState, useEffect } from 'react';
import {
    Modal, Box, Typography, Button, Select, MenuItem,
    TextField, FormControl, InputLabel, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, Paper,
    Grid
} from '@mui/material';
import MarkedText from './MarkedText';
import qualitiesString from './QualitiesString';
import editQualities from './EditQualities';
import attachments from '../data/attachments.json'
import getModText from "./GetModText";
import { isArray } from 'lodash';


const ResultsTable = ({ filteredResults, getCostString, selectedTemplate, setSelectedTemplate, character, handleCharacterChange, specificTemplate, resetKey, setResetKey, chosenEnvironment, setChosenEnvironment }) => {
    const [results, setResults] = useState({})
    const [spentResults, setSpentResults] = useState({})
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [integratedImprovement, setIntegratedImprovement] = useState("")
    const [enhPowerZone, setEnhPowerZone] = useState("")
    const [enhPower2, setEnhPower2] = useState("")
    const [currentTemplate, setCurrentTemplate] = useState("")
    const [intSys, setIntSys] = useState({})

    let integratedSystemOptions = [...attachments.filter((a) => a.HP === "1" && a.Type === "Vehicle")]

    useEffect(() => {
        setCurrentTemplate(specificTemplate.Key)
    }, [specificTemplate]);

    if ((!specificTemplate.Key || specificTemplate.Key !== currentTemplate) && (selectedOptions.length > 0 || JSON.stringify(results) !== "{}" || JSON.stringify(spentResults) !== "{}")) {
        setSelectedOptions([])
        setResults({})
        setSpentResults({})
        setCurrentTemplate(specificTemplate.Key)
        setResetKey(prevKey => prevKey + 1);
    }

    function editNumberString(inputString, multiplier) {
        const pattern = /\d+(\.\d+)?/;

        const match = inputString.match(pattern);

        if (match) {
            const number = parseInt(match[0]);
            const multipliedNumber = Math.round(number * multiplier);

            return inputString.replace(pattern, multipliedNumber.toString());
        }

        return inputString;
    }

    const handleEnhPower = (zone) => {
        let newTemplate = { ...selectedTemplate }
        let newProfile = newTemplate.VehicleProfile

        if (enhPowerZone === "All") {
            newProfile.DefFore -= 1
            newProfile.DefStar -= 1
            newProfile.DefPort -= 1
            newProfile.DefAft -= 1
        }
        if (zone === "All") {
            if (enhPower2 !== "") {
                newProfile[enhPower2] -= 2
                setEnhPower2("")
            }
            newProfile.DefFore ? newProfile.DefFore += 1 : newProfile.DefFore = 1
            newProfile.DefStar ? newProfile.DefStar += 1 : newProfile.DefStar = 1
            newProfile.DefPort ? newProfile.DefPort += 1 : newProfile.DefPort = 1
            newProfile.DefAft ? newProfile.DefAft += 1 : newProfile.DefAft = 1
        }

        setEnhPowerZone(zone)
        newTemplate.VehicleProfile = newProfile
        setSelectedTemplate(newTemplate)
    }

    const handleEnhPower2 = (zone) => {
        let newTemplate = { ...selectedTemplate }
        let newProfile = newTemplate.VehicleProfile
        if (enhPower2 !== "") {
            newProfile[enhPower2] -= 2
        }
        newProfile[zone] ? newProfile[zone] += 2 : newProfile[zone] = 2

        setEnhPower2(zone)
        newTemplate.VehicleProfile = newProfile
        setSelectedTemplate(newTemplate)
    }

    const handleIntegratedSystem = (attachment) => {
        setIntSys(attachment)
        let attachmentTemplate = { ...selectedTemplate }
        let attachmentProfile = (attachmentTemplate.VehicleProfile)
        attachmentProfile.Attachments.push(attachment)
        setSelectedTemplate(attachmentTemplate)
    }

    const handleIntImprove = (type) => {
        let newTemplate = { ...selectedTemplate }
        setIntegratedImprovement(type)
        switch (integratedImprovement) {
            case "Pass+":
                newTemplate.VehicleProfile.Passengers = editNumberString(selectedTemplate.VehicleProfile.Passengers, 2 / 3)
                break;
            case "Pass-":
                newTemplate.VehicleProfile.Passengers = editNumberString(selectedTemplate.VehicleProfile.Passengers, 2)
                break;
            case "Crew+":
                newTemplate.VehicleProfile.Crew = editNumberString(selectedTemplate.VehicleProfile.Crew, 2 / 3)
                break;
            case "Crew-":
                newTemplate.VehicleProfile.Crew = editNumberString(selectedTemplate.VehicleProfile.Crew, 2)
                break;
        }
        switch (type) {
            case "Pass+":
                newTemplate.VehicleProfile.Passengers = editNumberString(selectedTemplate.VehicleProfile.Passengers, 1.5)
                break;
            case "Pass-":
                newTemplate.VehicleProfile.Passengers = editNumberString(selectedTemplate.VehicleProfile.Passengers, .5)
                break;
            case "Crew+":
                newTemplate.VehicleProfile.Crew = editNumberString(selectedTemplate.VehicleProfile.Crew, 1.5)
                break;
            case "Crew-":
                newTemplate.VehicleProfile.Crew = editNumberString(selectedTemplate.VehicleProfile.Crew, .5)
                break;
        }
        setSelectedTemplate(newTemplate)
    }

    const handleOptionAdd = (index, count, type) => {
        let newSpentResults = { ...spentResults }
        if (type === "AdvThr") {
            if (count > 0) {
                newSpentResults["[AD]"] ? newSpentResults["[AD]"] += count : newSpentResults["[AD]"] = count
            }
            if (count < 0) {
                newSpentResults["[TH]"] ? newSpentResults["[TH]"] += Math.abs(count) : newSpentResults["[TH]"] = Math.abs(count)
            }
        }
        if (type === "TriDes") {
            if (count > 0) {
                newSpentResults["[TR]"] ? newSpentResults["[TR]"] += count : newSpentResults["[TR]"] = count
            }
            if (count < 0) {
                newSpentResults["[DE]"] ? newSpentResults["[DE]"] += Math.abs(count) : newSpentResults["[DE]"] = Math.abs(count)
            }
        }
        setSpentResults(newSpentResults)

        let newItem = JSON.parse(JSON.stringify(selectedTemplate))
        let newProfile = newItem.VehicleProfile
        if (filteredResults[index].EncumbranceChange) {
            newProfile.EncumbranceCapacity = parseInt(newProfile.EncumbranceCapacity) + parseInt(filteredResults[index].EncumbranceChange)
        }
        if (filteredResults[index].CargoPods) {
            newProfile.EncumbranceCapacity = parseInt(newProfile.EncumbranceCapacity) + (newProfile.Silhouette * ((newItem.Key === "VEHFRFREIGHTER") ? 2 : 1))
        }
        if (filteredResults[index].HPChange) {
            newProfile.HP ? newProfile.HP = parseInt(newProfile.HP) + parseInt(filteredResults[index].HPChange) : newProfile.HP = parseInt(filteredResults[index].HPChange)
        }
        if (filteredResults[index].SilhouetteChange) {
            newProfile.Silhouette ? newProfile.Silhouette = parseInt(newProfile.Silhouette) + parseInt(filteredResults[index].SilhouetteChange) : newProfile.Silhouette = parseInt(filteredResults[index].SilhouetteChange)
        }
        if (filteredResults[index].SpeedChange) {
            newProfile.Speed = parseInt(newProfile.Speed) + parseInt(filteredResults[index].SpeedChange)
        }
        if (filteredResults[index].HandlingChange) {
            newProfile.Handling = parseInt(newProfile.Handling) + parseInt(filteredResults[index].HandlingChange)
        }
        if (filteredResults[index].SystemStrainChange) {
            newProfile.SystemStrain = parseInt(newProfile.SystemStrain) + parseInt(filteredResults[index].SystemStrainChange)
        }
        if (filteredResults[index].MaterialCostDiscount) {
            specificTemplate.MaterialCost = Math.round(specificTemplate.MaterialCost - ((filteredResults[index].MaterialCostDiscount / 100) * specificTemplate.MaterialCost))
        }
        if (filteredResults[index].HullTraumaChange) {
            newProfile.HullTrauma = parseInt(newProfile.HullTrauma) + parseInt(filteredResults[index].HullTraumaChange)
        }
        if (filteredResults[index].ArmorChange) {
            newProfile.Armor = parseInt(newProfile.Armor) + parseInt(filteredResults[index].ArmorChange)
        }
        if (filteredResults[index].TimeDiscount) {
            specificTemplate.TimeHours = Math.round(specificTemplate.TimeHours - ((filteredResults[index].TimeDiscount / 100) * specificTemplate.TimeHours))
        }
        if (filteredResults[index].Schematic) {
            let newSchematics = character.Schematics || {}
            newSchematics[specificTemplate.Key] ? newSchematics[specificTemplate.Key] += 1 : newSchematics[specificTemplate.Key] = 1
            handleCharacterChange({ Schematics: newSchematics })
        }
        if (filteredResults[index].MassiveChange) {
            if (newProfile.BaseMods && newProfile.BaseMods.find((m) => m.MiscDesc === "Massive 1")) {
                newProfile.BaseMods.find((m) => m.MiscDesc === "Massive 1").MiscDesc = "Massive 2"
            } else {
                filteredResults[index].Mods = { MiscDesc: "Massive 1" }
            }
        }
        if (filteredResults[index].IntegralAttachment) {
            newProfile.HP = newProfile.HP - 1
        }
        if (filteredResults[index].Key === "IMPVEHFRHARDWORK") {
            let newDuties = [...character.Duties]
            newDuties[0].Total += 2
            handleCharacterChange({ Duty: newDuties })
        }
        if (filteredResults[index].Mods) {
            let mods = []
            if (!isArray(filteredResults[index].Mods)) {
                mods = [{ ...filteredResults[index].Mods }]
            } else { mods = [...filteredResults[index].Mods] }
            mods.map((mod) => {
                if (!mod.MiscDesc && !mod.Key && filteredResults[index].Name && filteredResults[index].Description) {
                    mod.MiscDesc = filteredResults[index].Name + ": " + filteredResults[index].Description
                }
                newProfile = editQualities(newProfile, [mod])
                if (mod.MiscDesc) {
                    newProfile.BaseMods ? newProfile.BaseMods.push({ "MiscDesc": mod.MiscDesc }) : newProfile.BaseMods = [{ "MiscDesc": mod.MiscDesc }]
                }
            })
        }
        newItem.VehicleProfile = newProfile
        setSelectedTemplate(newItem)

        let newOptions = [...selectedOptions]
        newOptions[index] = selectedOptions[index] ? selectedOptions[index] + 1 : 1
        setSelectedOptions(newOptions);
    };

    const handleOptionRemove = (index) => {
        let availableRefunds = {
            "AD": parseInt(spentResults["[AD]"] || 0),
            "TH": parseInt(spentResults["[TH]"] || 0),
            "TR": parseInt(spentResults["[TR]"] || 0),
            "DE": parseInt(spentResults["[DE]"] || 0),
            "LI": parseInt(spentResults["LI]"] || 0),
            "DA": parseInt(spentResults["[DA]"] || 0)
        }
        let newSpentResults = { ...spentResults }

        if (selectedOptions.reduce(function (s, v) { return s + (v || 0); }, 0) === 1) { newSpentResults = {} }
        else if (filteredResults[index].CostAdvThr && parseInt(filteredResults[index].CostAdvThr) > 0 && parseInt(filteredResults[index].CostAdvThr) <= availableRefunds.AD) {
            newSpentResults["[AD]"] -= parseInt(filteredResults[index].CostAdvThr)
        } else if (filteredResults[index].CostAdvThr && Math.abs(parseInt(filteredResults[index].CostAdvThr)) <= availableRefunds.TH && availableRefunds.TH > 0) {
            newSpentResults["[TH]"] += parseInt(filteredResults[index].CostAdvThr)
        } else if (parseInt(filteredResults[index].CostTriDes) > 0 && parseInt(filteredResults[index].CostTriDes) <= availableRefunds.TR) {
            newSpentResults["[TR]"] -= parseInt(filteredResults[index].CostTriDes)
        } else if (Math.abs(parseInt(filteredResults[index].CostTriDes)) <= availableRefunds.DE && availableRefunds.DE > 0) {
            newSpentResults["[DE]"] += parseInt(filteredResults[index].CostTriDes)
        } else if (parseInt(filteredResults[index].CostLightDark) > 0 && parseInt(filteredResults[index].CostLightDark) <= availableRefunds.LI) {
            newSpentResults["[LI]"] -= parseInt(filteredResults[index].CostLightDark)
        } else if (parseInt(filteredResults[index].CostLightDark) < 0 && Math.abs(parseInt(filteredResults[index].CostLightDark)) <= availableRefunds.DA && availableRefunds.DA > 0) {
            newSpentResults["[DA]"] += parseInt(filteredResults[index].CostLightDark)
        }
        setSpentResults(newSpentResults)

        let newItem = { ...selectedTemplate }
        let newProfile = newItem.VehicleProfile
        if (filteredResults[index].EncumbranceChange) {
            newProfile.EncumbranceCapacity = parseInt(newProfile.EncumbranceCapacity) - parseInt(filteredResults[index].EncumbranceChange)
        }
        if (filteredResults[index].CargoPods) {
            newProfile.EncumbranceCapacity = parseInt(newProfile.EncumbranceCapacity) - (newProfile.Silhouette * ((newItem.Key === "VEHFRFREIGHTER") ? 2 : 1))
        }
        if (filteredResults[index].HPChange) {
            newProfile.HP = parseInt(newProfile.HP) - parseInt(filteredResults[index].HPChange)
        }
        if (filteredResults[index].SilhouetteChange) {
            newProfile.Silhouette = parseInt(newProfile.Silhouette) - parseInt(filteredResults[index].SilhouetteChange)
        }
        if (filteredResults[index].SpeedChange) {
            newProfile.Speed = parseInt(newProfile.Speed) - parseInt(filteredResults[index].SpeedChange)
        }
        if (filteredResults[index].HandlingChange) {
            newProfile.Handling = parseInt(newProfile.Handling) - parseInt(filteredResults[index].HandlingChange)
        }
        if (filteredResults[index].SystemStrainChange) {
            newProfile.SystemStrain = parseInt(newProfile.SystemStrain) - parseInt(filteredResults[index].SystemStrainChange)
        }
        if (filteredResults[index].HullTraumaChange) {
            newProfile.HullTrauma = newProfile.HullTrauma - filteredResults[index].HullTraumaChange
        }
        if (filteredResults[index].ArmorChange) {
            newProfile.Armor = parseInt(newProfile.Armor) - parseInt(filteredResults[index].ArmorChange)
        }

        if (filteredResults[index].Schematic) {
            let newSchematics = character.Schematics || {}
            newSchematics[specificTemplate.Key] -= 1
            handleCharacterChange({ Schematics: newSchematics })
        }
        if (filteredResults[index].EnhancedDeflector) {
            if (enhPowerZone === "All") {
                newProfile.DefFore -= 1
                newProfile.DefStar -= 1
                newProfile.DefPort -= 1
                newProfile.DefAft -= 1
            }
            if (enhPower2 !== "") {
                newProfile[enhPower2] -= 2
            }
            setEnhPowerZone("")
            setEnhPower2("")
        }
        if (filteredResults[index].MassiveChange) {
            if (newProfile.BaseMods && newProfile.BaseMods.find((m) => m.MiscDesc === "Massive 2")) {
                newProfile.BaseMods.find((m) => m.MiscDesc === "Massive 2").MiscDesc = "Massive 1"
            } else {
                newProfile.BaseMods = newProfile.BaseMods.filter((m) => m.MiscDesc !== "Massive 1")
            }
        }
        if (filteredResults[index].Key === "IMPVEHFRHARDWORK") {
            let newDuties = [...character.Duties]
            newDuties[0].Total -= 2
            handleCharacterChange({ Duty: newDuties })
        }
        if (filteredResults[index].IntegratedImprovement) {
            let newTemplate = { ...selectedTemplate }
            switch (integratedImprovement) {
                case "Pass+":
                    newTemplate.VehicleProfile.Passengers = editNumberString(selectedTemplate.VehicleProfile.Passengers, 2 / 3)
                    break;
                case "Pass-":
                    newTemplate.VehicleProfile.Passengers = editNumberString(selectedTemplate.VehicleProfile.Passengers, 2)
                    break;
                case "Crew+":
                    newTemplate.VehicleProfile.Crew = editNumberString(selectedTemplate.VehicleProfile.Crew, 2 / 3)
                    break;
                case "Crew-":
                    newTemplate.VehicleProfile.Crew = editNumberString(selectedTemplate.VehicleProfile.Crew, 2)
                    break;
            }
            setSelectedTemplate(newTemplate)
            setIntegratedImprovement("")
        }
        if (filteredResults[index].MaterialCostDiscount) {
            specificTemplate.MaterialCost = Math.max(5, Math.round((specificTemplate.MaterialCost / (1 - (filteredResults[index].MaterialCostDiscount / 100))) / 5) * 5);
        }
        if (filteredResults[index].TimeDiscount) {
            specificTemplate.TimeHours = Math.max(5, Math.round((specificTemplate.TimeHours / (1 - (filteredResults[index].TimeDiscount / 100))) / 5) * 5);
        }
        if (filteredResults[index].IntegralAttachment) {
            setIntSys({})
            newProfile.Attachments = newProfile.Attachments.filter((a) => a.Key.includes("CRAFT"))
            newProfile.HP = newProfile.HP - 1
        }
        if (filteredResults[index].Mods && !filteredResults[index].MassiveChange) {
            let mods = []
            if (!isArray(filteredResults[index].Mods)) {
                mods = [{ ...filteredResults[index].Mods }]
            } else { mods = [...filteredResults[index].Mods] }
            mods.map((mod) => {
                if (!mod.MiscDesc && !mod.Key && filteredResults[index].Name && filteredResults[index].Description) {
                    mod.MiscDesc = filteredResults[index].Name + ": " + filteredResults[index].Description
                }
                newProfile = editQualities(newProfile, [mod], true)
                if (mod.MiscDesc && newProfile.BaseMods) {
                    newProfile.BaseMods = newProfile.BaseMods.filter((m) => m.MiscDesc !== mod.MiscDesc)
                }
            })
        }
        if (newProfile.BaseMods?.length === 0) {
            delete newProfile.BaseMods
        }
        newItem.VehicleProfile = newProfile
        setSelectedTemplate(newItem)

        let newOptions = [...selectedOptions]
        newOptions[index] = newOptions[index] - 1
        setSelectedOptions(newOptions);
    };


    const availableSymbol = (symbol) => {
        let available = 0
        available = parseInt(results[symbol] || 0) - parseInt(spentResults[symbol] || 0)
        return available
    }

    const adjustResults = (symbol, number) => {
        if (isNaN(number)) { return }
        let newResults = { ...results }
        newResults[symbol] = number
        setResults(newResults)
    }

    const diffString = (temp) => {
        let count = parseInt(temp.CheckDifficulty)
        count -= ((character.Schematics && character.Schematics[temp.Key]) ? character.Schematics[temp.Key] : 0)
        count = Math.max(count, 0)
        let string = ""
        string += "[DI]".repeat(count)
        if (string === "") {
            string = "(-)"
        }
        return string
    }

    const schematicString = (temp) => {
        let string = ""
        string += ((character.Schematics && character.Schematics[temp.Key] && character.Schematics[temp.Key] > 0) ? " with " + character.Schematics[temp.Key] + " schematics" : "")
        return string
    }

    return (
        specificTemplate.Key && <>
            <div
                dangerouslySetInnerHTML={{
                    __html: MarkedText.renderer(diffString(specificTemplate) + " " + "Mechanics check" + schematicString(specificTemplate))
                }}
            />
            <Typography key={resetKey}>Material Cost: {specificTemplate.MaterialCost} Credits | Rarity: {specificTemplate.MaterialRarity} | Crafting Time: {specificTemplate.TimeHours} hours</Typography>
            <Grid container sx={{ paddingTop: "15px" }}>
                <Grid item sx={{ paddingLeft: "30px" }}>
                    <Typography sx={{ paddingBottom: "5px" }}>Results: </Typography>
                    <Typography>Available: </Typography>
                </Grid>
                <Grid item sx={{ paddingLeft: "5px" }}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: MarkedText.renderer(" [AD] ")
                        }}
                    />
                </Grid>
                <Grid item sx={{ paddingLeft: "5px" }}>
                    <TextField
                        key={resetKey}
                        type="text"
                        value={results["[AD]"]}
                        onChange={(event) => adjustResults("[AD]", event.target.value)}
                        inputProps={{
                            style: {
                                padding: '4px 8px',
                                width: '20px',
                                textAlign: 'center'
                            },
                            inputMode: 'numeric',
                            pattern: '[0-9]*'
                        }}
                        variant="outlined"
                        size="small"
                    />
                    <Typography sx={{ textAlign: 'center' }}>{(parseInt(results['[AD]']) || 0) - (parseInt(spentResults['[AD]']) || 0)}</Typography>
                </Grid>
                <Grid item sx={{ paddingLeft: "5px" }}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: MarkedText.renderer(" [TR] ")
                        }}
                    />
                </Grid>
                <Grid item sx={{ paddingLeft: "5px" }}>
                    <TextField
                        key={resetKey}
                        type="text"
                        value={results["[TR]"]}
                        onChange={(event) => adjustResults("[TR]", event.target.value)}
                        inputProps={{
                            style: {
                                padding: '4px 8px',
                                width: '20px',
                                textAlign: 'center'
                            },
                            inputMode: 'numeric',
                            pattern: '[0-9]*'
                        }}
                        variant="outlined"
                        size="small"
                    />
                    <Typography sx={{ textAlign: 'center' }}>{(parseInt(results['[TR]']) || 0) - (parseInt(spentResults['[TR]']) || 0)}</Typography>
                </Grid>
                <Grid item sx={{ paddingLeft: "5px" }}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: MarkedText.renderer(" [TH] ")
                        }}
                    />
                </Grid>
                <Grid item sx={{ paddingLeft: "5px" }}>
                    <TextField
                        key={resetKey}
                        type="text"
                        value={results["[TH]"]}
                        onChange={(event) => adjustResults("[TH]", event.target.value)}
                        inputProps={{
                            style: {
                                padding: '4px 8px',
                                width: '20px',
                                textAlign: 'center'
                            },
                            inputMode: 'numeric',
                            pattern: '[0-9]*'
                        }}
                        variant="outlined"
                        size="small"
                    />
                    <Typography sx={{ textAlign: 'center' }}>{(parseInt(results['[TH]']) || 0) - (parseInt(spentResults['[TH]']) || 0)}</Typography>
                </Grid>
                <Grid item sx={{ paddingLeft: "5px" }}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: MarkedText.renderer(" [DE] ")
                        }}
                    />
                </Grid>
                <Grid item sx={{ paddingLeft: "5px" }}>
                    <TextField
                        key={resetKey}
                        type="text"
                        value={results["[DE]"]}
                        onChange={(event) => adjustResults("[DE]", event.target.value)}
                        inputProps={{
                            style: {
                                padding: '4px 8px',
                                width: '20px',
                                textAlign: 'center'
                            },
                            inputMode: 'numeric',
                            pattern: '[0-9]*'
                        }}
                        variant="outlined"
                        size="small"
                    />
                    <Typography sx={{ textAlign: 'center' }}>{(parseInt(results['[DE]']) || 0) - (parseInt(spentResults['[DE]']) || 0)}</Typography>
                </Grid>
            </Grid>
            {(() => {
                const index = filteredResults.findIndex((o) => o.Key === "IMPVEHFINTIMP");
                return (
                    index !== -1 &&
                    selectedOptions[index] !== undefined &&
                    selectedOptions[index] > 0 && (
                        <Grid item>
                            <FormControl fullWidth sx={{ mt: 2, minWidth: "200px", paddingLeft: "20px", marginTop: "0px" }}>
                                <InputLabel sx={{ paddingLeft: "15px" }}>Integrated Improvement</InputLabel>
                                <Select value={integratedImprovement} onChange={(e) => handleIntImprove(e.target.value)}>
                                    <MenuItem key="Crew+" value={"Crew+"}>Increase Crew by 50%</MenuItem>
                                    <MenuItem key="Crew-" value={"Crew-"}>Decrease Crew by 50%</MenuItem>
                                    <MenuItem key="Pass+" value={"Pass+"}>Increase Passengers by 50%</MenuItem>
                                    <MenuItem key="Pass-" value={"Pass-"}>Decrease Passengers by 50%</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    )
                );
            })()}
            <Grid container>
                {(() => {
                    const index = filteredResults.findIndex((o) => o.Key === "IMPVEHASSPECIAL");
                    return (
                        index !== -1 &&
                        selectedOptions[index] !== undefined &&
                        selectedOptions[index] > 0 && (
                            <Grid item xs={12} sm={6}>
                                <TextField value={chosenEnvironment} onChange={(e) => setChosenEnvironment(e.target.value)} />
                            </Grid>
                        )
                    );
                })()}
                {(() => {
                    const index = filteredResults.findIndex((o) => o.Key === "IMPVEHHULLINTSYSTEM");
                    return (
                        index !== -1 &&
                        selectedOptions[index] !== undefined &&
                        selectedOptions[index] > 0 && (
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth sx={{ mt: 2, minWidth: "200px", paddingLeft: "20px", marginTop: "0px" }}>
                                    <InputLabel sx={{ paddingLeft: "15px" }}>Integrated System</InputLabel>
                                    <Select value={intSys} onChange={(e) => handleIntegratedSystem(e.target.value)}>
                                        {integratedSystemOptions.map((attach, index) => <MenuItem key={index} value={attach}>{attach.Name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )
                    );
                })()}
                {(() => {
                    const index = filteredResults.findIndex((o) => o.Key === "IMPVEHENGENHPOWERREFLZONE");
                    return (
                        index !== -1 &&
                        selectedOptions[index] !== undefined &&
                        selectedOptions[index] > 0 && (
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth sx={{ mt: 2, minWidth: "200px", paddingLeft: "20px", marginTop: "0px" }}>
                                    <InputLabel sx={{ paddingLeft: "15px" }}>Enhanced Power to Deflectors</InputLabel>
                                    <Select value={enhPowerZone} onChange={(e) => handleEnhPower(e.target.value)}>
                                        <MenuItem key="All" value={"All"}>All zones by 1</MenuItem>
                                        <MenuItem key="One" value={"One2"}>One zone by 2</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        )
                    );
                })()}
                {(enhPowerZone === "One2") &&
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth sx={{ mt: 2, minWidth: "200px", paddingLeft: "20px", marginTop: "0px" }}>
                            <InputLabel sx={{ paddingLeft: "15px" }}>Choose Zone</InputLabel>
                            <Select value={enhPower2} onChange={(e) => handleEnhPower2(e.target.value)}>
                                <MenuItem key="Fore" value={"DefFore"}>Fore</MenuItem>
                                <MenuItem key="Port" value={"DefPort"}>Port</MenuItem>
                                <MenuItem key="Star" value={"DefStar"}>Starboard</MenuItem>
                                <MenuItem key="Aft" value={"DefAft"}>Aft</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                }
            </Grid>
            {Object.keys(results).length > 0 && < TableContainer
                component={Paper}
                style={{
                    maxHeight: "calc(100vh - 40.5vh)",
                    overflowY: "auto",
                }}
            >
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow sx={{ padding: "1px" }}>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell sx={{ textAlign: "center" }}>Cost</TableCell>
                            <TableCell sx={{ textAlign: "center" }}>Count</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredResults && filteredResults.map((option, index) => (
                            (selectedOptions[index] > 0 ||
                                (parseInt(option.CostAdvThr) > 0 && parseInt(option.CostAdvThr) <= parseInt(results['[AD]']) - (spentResults['[AD]'] || 0)) ||
                                (parseInt(option.CostAdvThr) < 0 && Math.abs(parseInt(option.CostAdvThr)) <= results['[TH]'] - (spentResults['[TH]'] || 0)) ||
                                (parseInt(option.CostTriDes) > 0 && parseInt(option.CostTriDes) <= parseInt(results['[TR]']) - (spentResults['[TR]'] || 0)) ||
                                (parseInt(option.CostTriDes) < 0 && Math.abs(parseInt(option.CostTriDes)) <= parseInt(results['[DE]']) - (spentResults['[DE]'] || 0)))
                            && <TableRow key={option.Key}>
                                <TableCell>{option.Name}</TableCell>
                                <TableCell>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: MarkedText.renderer(option.Description)
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                    {option.CostAdvThr !== "0" && <Button
                                        size="small"
                                        sx={{ minWidth: "30px" }}
                                        onClick={() => handleOptionAdd(index, parseInt(option.CostAdvThr), "AdvThr")}
                                        disabled={
                                            (option.SelectCount && parseInt(option.SelectCount) === selectedOptions[index]) ||
                                            (option.HandlingChange && (parseInt(option.HandlingMax) <= parseInt(selectedTemplate.VehicleProfile.Handling))) ||
                                            (parseInt(option.CostAdvThr) > 0 && availableSymbol('[AD]') < parseInt(option.CostAdvThr)) ||
                                            (parseInt(option.CostAdvThr) < 0 && availableSymbol('[TH]') < Math.abs(parseInt(option.CostAdvThr)))
                                        }>

                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html: MarkedText.renderer(getCostString(option.CostAdvThr, 0, 0)
                                                )
                                            }}
                                        />
                                    </Button>}
                                    <Button
                                        size="small"
                                        sx={{ minWidth: "30px" }}
                                        onClick={() => handleOptionAdd(index, parseInt(option.CostTriDes), "TriDes")}
                                        disabled={
                                            (option.SelectCount && parseInt(option.SelectCount) === selectedOptions[index]) ||
                                            (option.HandlingChange && (parseInt(option.HandlingMax) <= parseInt(selectedTemplate.VehicleProfile.Handling))) ||
                                            (parseInt(option.CostTriDes) > 0 && availableSymbol('[TR]') < parseInt(option.CostTriDes)) ||
                                            (parseInt(option.CostTriDes) < 0 && availableSymbol('[DE]') < Math.abs(parseInt(option.CostTriDes)))

                                        }>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html: MarkedText.renderer(getCostString(0, option.CostTriDes, 0)
                                                )
                                            }}
                                        />
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Button size="small" sx={{ minWidth: "30px" }} onClick={() => handleOptionRemove(index)} disabled={!selectedOptions[index] > 0}>-</Button>
                                    {selectedOptions[index] || 0}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer >}
        </>
    )
}

const VehicleCraftingModal = ({ open, handleClose, craftingTemplates, craftingResultTables, character, handleCharacterChange, setOwnedVehicles, qualities, talents, skills }) => {
    const [frame, setFrame] = useState('');
    const [baseFrame, setBaseFrame] = useState('');
    const [engine, setEngine] = useState('');
    const [hull, setHull] = useState('');
    const [assembly, setAssembly] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [resetKey, setResetKey] = useState(0);

    const frames = craftingTemplates.filter(temp => temp.TempType === "VehicleFrame");
    const engines = craftingTemplates.filter(temp => temp.TempType === "VehicleEngine");
    const hulls = craftingTemplates.filter(temp => temp.TempType === "VehicleHull");
    const assemblies = craftingTemplates.filter(temp => temp.TempType === "VehicleAssembly");

    const frameResults = craftingResultTables.filter(r => r.TempType === "VehicleFrame");
    const engineResults = craftingResultTables.filter(r => r.TempType === "VehicleEngine");
    const hullResults = craftingResultTables.filter(r => r.TempType === "VehicleHull");
    const assemblyResults = craftingResultTables.filter(r => r.TempType === "VehicleAssembly");

    const [chosenEnvironment, setChosenEnvironment] = useState("chosen environment")

    const skillModString = (string) => {
        let newString = string.replace("chosen environment", chosenEnvironment)
        return newString
    }

    const handleFrameChange = (event) => {
        setFrame(event.target.value);
        setBaseFrame(event.target.value)
        setName(event.target.value.Name)

        setEngine('')
        setHull('')
        setResetKey(prevKey => prevKey + 1);
    };

    const handleEngineChange = (event) => {
        let newEngine = event.target.value
        setEngine(newEngine);
        let newFrame = { ...frame }
        if (frame.VehicleProfile.Attachments && frame.VehicleProfile.Attachments.find((a) => a.Key.includes("CRAFTENGINE"))) {
            let oldAttachment = frame.VehicleProfile.Attachments.find((a) => a.Key.includes("CRAFTENGINE"))
            newFrame.VehicleProfile = editQualities(newFrame.VehicleProfile, oldAttachment.BaseMods, true)
            newFrame.VehicleProfile.Attachments = frame.VehicleProfile.Attachments.filter((a) => !a.Key.includes("CRAFTENGINE"))
        }

        let newAttachment = attachments.find((a) => a.Key === newEngine.AttachmentKey)
        if (newFrame.VehicleProfile.Attachments) {
            newFrame.VehicleProfile.Attachments.push(newAttachment)
        } else {
            newFrame.VehicleProfile.Attachments = [newAttachment]
        }

        newFrame.VehicleProfile = editQualities(newFrame.VehicleProfile, newAttachment.BaseMods)

        setFrame(newFrame)
        setHull('')
        setResetKey(prevKey => prevKey + 1);
    };

    const handleHullChange = (event) => {
        let newHull = event.target.value
        setHull(newHull);
        let newFrame = { ...frame }
        if (frame.VehicleProfile.Attachments && frame.VehicleProfile.Attachments.find((a) => a.Key.includes("CRAFTHULL"))) {
            let oldAttachment = frame.VehicleProfile.Attachments.find((a) => a.Key.includes("CRAFTHULL"))
            newFrame.VehicleProfile = editQualities(newFrame.VehicleProfile, oldAttachment.BaseMods, true)
            newFrame.VehicleProfile.Attachments = frame.VehicleProfile.Attachments.filter((a) => !a.Key.includes("CRAFTHULL"))
        }

        let newAttachment = attachments.find((a) => a.Key === newHull.AttachmentKey)
        if (newFrame.VehicleProfile.Attachments) {
            newFrame.VehicleProfile.Attachments.push(newAttachment)
        } else {
            newFrame.VehicleProfile.Attachments = [newAttachment]
        }

        newFrame.VehicleProfile = editQualities(newFrame.VehicleProfile, newAttachment.BaseMods)

        setFrame(newFrame)
        setResetKey(prevKey => prevKey + 1);
    };

    const handleAssemblyChange = (index) => {
        if (assembly.Key !== assemblies[index].Key) {
            setAssembly(assemblies[index]);
        }
    };

    let currentSil = parseInt(frame.VehicleProfile?.Silhouette)
    if (currentSil === 0 || currentSil === 1) { handleAssemblyChange(0) }
    if (currentSil === 2) { handleAssemblyChange(1) }
    if (currentSil === 3) { handleAssemblyChange(2) }
    if (currentSil === 4) { handleAssemblyChange(3) }
    if (currentSil === 5 || currentSil === 6) { handleAssemblyChange(4) }
    if (parseInt(currentSil) > 6) { handleAssemblyChange(5) }

    function stepCount(str, decrement) {

        let match = str.match(/\d+/);

        if (!match) {
            let regex = /\[BO\]+/;
            match = str.match(regex);

            if (!match) {
                let regex = /\[SE\]+/;
                match = str.match(regex);
                if (!match) { return decrement ? "0" : str; }
                if (decrement) {
                    const newStr = str.replace("[SE]", "");
                    return newStr.includes("[SE]") ? newStr : "0";
                } else {
                    return str.replace(regex, match[0] + "[SE]");
                }
            }

            if (decrement) {
                const newStr = str.replace("[BO]", "");
                return newStr.includes("[BO]") ? newStr : "0";
            } else {
                return str.replace(regex, match[0] + "[BO]");
            }
        }

        const number = parseInt(match[0], 10);
        const incrementedNumber = number + 1;
        const decrementNumber = number - 1;
        let returnNumber = decrement ? decrementNumber : incrementedNumber;

        return str.replace(match[0], returnNumber);
    }

    const speedForSilhouette = (profile) => {
        if (profile.Key === "VEHFRSTATION") { return 0 }
        let max = 0
        switch (parseInt(profile.Silhouette)) {
            case 0:
            case 5:
            case 6:
            case 7:
                max = 3;
                break;
            case 1:
            case 4:
                max = 4;
                break;
            case 2:
                max = 5;
                break;
            case 3:
                max = 6;
                break;
            case 8:
            case 9:
            case 10:
                max = 2;
                break;
            default:
                max = 0;
                break;
        }
        return (Math.min(profile.Speed || 0, max))
    }

    const handleSubmit = () => {
        let profile = { ...frame.VehicleProfile }

        if (profile.BaseMods && Array.isArray(profile.BaseMods)) {
            for (let m in profile.BaseMods) {
                let mod = profile.BaseMods[m]
                if (mod.MiscDesc && mod.MiscDesc.includes("chosen environment")) {
                    mod.MiscDesc = mod.MiscDesc.replace("chosen environment", chosenEnvironment)
                }
            }
        }

        if (profile.Silhouette < 5) {
            delete profile.DefPort
            delete profile.DefStar
        }

        profile.Source = "Crafted Vehicle, see Fully Operational page 76"
        profile.Speed = speedForSilhouette(profile)

        profile.Name = name
        profile.Description = description
        const UID = `${'craftedItem'}_${Date.now()}`;
        profile.inventoryID = UID
        let newCategory = [...character.Vehicles]
        newCategory.push(profile)
        let totalCost = parseInt(frame.MaterialCost) + parseInt(engine.MaterialCost) + parseInt(hull.MaterialCost) + parseInt(assembly.MaterialCost)

        handleCharacterChange({
            Vehicles: newCategory,
            Credits: character.Credits - totalCost
        });
        setOwnedVehicles(newCategory)

        setFrame('');
        setName('')
        setDescription('');
        setHull('')
        setEngine('')
        setAssembly('')
        handleClose();
    };

    const getCostString = (costA, costB) => {
        let costString = ""
        if (parseInt(costA) > 0 || parseInt(costB) > 0) {
            costString += "[AD]".repeat(parseInt(costA))
            costString += "[TR]".repeat(parseInt(costB))

        }
        if (parseInt(costA) < 0 || parseInt(costB) < 0) {
            costString += "[TH]".repeat(Math.abs(parseInt(costA)))
            costString += "[DE]".repeat(Math.abs(parseInt(costB)))
        }
        return costString
    }

    return (
        <Modal open={open} onClose={handleClose}
            sx={{
                overflow: "auto"
            }}>
            <Box sx={{
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4
            }}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h6" component="h2">
                            Craft Vehicle
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button onClick={() => handleClose()}>🞫</Button>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={3}>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel>Frame</InputLabel>
                            <Select value={baseFrame} onChange={handleFrameChange}>
                                {frames.map(f => (
                                    <MenuItem key={f.Key} value={f}>{f.Name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {frame &&
                            <ResultsTable filteredResults={frameResults} getCostString={getCostString} selectedTemplate={frame} setSelectedTemplate={setFrame} character={character} handleCharacterChange={handleCharacterChange} specificTemplate={frame} resetKey={resetKey} setResetKey={setResetKey} chosenEnvironment={chosenEnvironment} setChosenEnvironment={setChosenEnvironment} />
                        }
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel>Engine</InputLabel>
                            <Select value={engine} onChange={handleEngineChange} disabled={!frame}>
                                {engines.map(e => (
                                    <MenuItem key={e.Key} value={e}>{e.Name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {engine &&
                            <ResultsTable filteredResults={engineResults} getCostString={getCostString} selectedTemplate={frame} setSelectedTemplate={setFrame} character={character} handleCharacterChange={handleCharacterChange} specificTemplate={engine} resetKey={resetKey} setResetKey={setResetKey} chosenEnvironment={chosenEnvironment} setChosenEnvironment={setChosenEnvironment} />
                        }
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel>Hull</InputLabel>
                            <Select value={hull} onChange={handleHullChange} disabled={!engine}>
                                {hulls.map(h => (
                                    <MenuItem key={h.Key} value={h}>{h.Name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {hull &&
                            <ResultsTable filteredResults={hullResults} getCostString={getCostString} selectedTemplate={frame} setSelectedTemplate={setFrame} character={character} handleCharacterChange={handleCharacterChange} specificTemplate={hull} resetKey={resetKey} setResetKey={setResetKey} chosenEnvironment={chosenEnvironment} setChosenEnvironment={setChosenEnvironment} />
                        }
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel>Assembly</InputLabel>
                            <Select value={assembly} onChange={handleAssemblyChange} disabled={true}>
                                {assemblies.map(a => (
                                    <MenuItem key={a.Key} value={a}>{a.Name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {assembly &&
                            <ResultsTable filteredResults={assemblyResults} getCostString={getCostString} selectedTemplate={frame} setSelectedTemplate={setFrame} character={character} handleCharacterChange={handleCharacterChange} specificTemplate={assembly} resetKey={resetKey} setResetKey={setResetKey} chosenEnvironment={chosenEnvironment} setChosenEnvironment={setChosenEnvironment} />
                        }
                    </Grid>
                </Grid>
                {frame && <Grid container>
                    <Grid item xs={12} sm={9}>
                        <TextField
                            fullWidth
                            label="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            sx={{ mt: 2 }}
                        />
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            label="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            sx={{ mt: 2 }}
                        />
                        <Button onClick={handleSubmit} sx={{ mt: 2 }} disabled={!frame || !engine || !hull || !assembly}>
                            Craft Item
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        {frame.VehicleProfile && <div style={{ padding: "15px" }}>
                            <Typography>Silhouette: {frame.VehicleProfile.Silhouette}</Typography>
                            <Typography>Speed: {speedForSilhouette(frame.VehicleProfile) || 0}</Typography>
                            <Typography>Handling: {frame.VehicleProfile.Handling || 0}</Typography>
                            <Typography>Hard Points: {frame.VehicleProfile.HP}</Typography>
                            <Typography>Hull Trauma: {frame.VehicleProfile.HullTrauma}</Typography>
                            <Typography>System Strain: {frame.VehicleProfile.SystemStrain || 0}</Typography>
                            <Typography>Armor: {frame.VehicleProfile.Armor || 0}</Typography>
                            {frame.VehicleProfile.MaxAltitude && <Typography>Max Altitude: {frame.VehicleProfile.MaxAltitude}</Typography>}
                            <Typography>Defense: {frame.VehicleProfile.DefFore || 0}|{(frame.VehicleProfile.Silhouette > 4 ? ((frame.VehicleProfile.DefPort || "0") + "|" + (frame.VehicleProfile.DefStar || "0")) : "-|-") + "|"}{frame.VehicleProfile.DefAft || 0}</Typography>
                            <Typography>Sensor Range: {frame.VehicleProfile.SensorRangeValue}</Typography>
                            <Typography>Crew: {frame.VehicleProfile.Crew}</Typography>
                            <Typography>Passengers: {frame.VehicleProfile.Passengers}</Typography>
                            <Typography>Encumbrance Capacity: {frame.VehicleProfile.EncumbranceCapacity}</Typography>
                            {frame.VehicleProfile.Qualities?.length > 0 && <Typography>{qualitiesString(frame.VehicleProfile.Qualities)}</Typography>}
                        </div>}
                        {frame.BaseMods && <div style={{ padding: "15px" }}>
                            {frame.BaseMods.map((mod, index) => (
                                <div key={mod.Key + index}>
                                    {mod.MiscDesc && (<div
                                        style={{ padding: "15px" }}
                                        dangerouslySetInnerHTML={{
                                            __html: MarkedText.renderer(skillModString(mod.MiscDesc)),
                                        }}
                                    />)}
                                    {mod.Key && (
                                        <div
                                            style={{ padding: "15px" }}
                                            dangerouslySetInnerHTML={{
                                                __html: MarkedText.renderer(getModText(qualities, talents, skills, mod)),
                                            }}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>}
                        {(() => {
                            const profile = frame.VehicleProfile
                            if (!profile || (!profile.Attachments && !profile.BaseMods)) return null;
                            if (profile.BaseMods && !Array.isArray(profile.BaseMods)) { profile.BaseMods = [profile.BaseMods] }
                            return (
                                <>
                                    {profile.BaseMods && (
                                        <Typography sx={{ paddingLeft: "15px" }}>
                                            Base Modifiers:{" "}
                                            {profile.BaseMods.map(
                                                (mod, index) => (
                                                    <div key={index}>
                                                        {mod.MiscDesc && <div
                                                            style={{ padding: "5px" }}
                                                            dangerouslySetInnerHTML={{
                                                                __html: MarkedText.renderer(
                                                                    skillModString(mod.MiscDesc),
                                                                ),
                                                            }}
                                                        />}
                                                        {mod.Key && (
                                                            <div
                                                                style={{ padding: "5px" }}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: MarkedText.renderer(
                                                                        qualities.find(
                                                                            (q) => q.Key === mod.Key,
                                                                        )
                                                                            ? (Math.abs(mod.Count) || "") +
                                                                            " " +
                                                                            qualities.find(
                                                                                (q) => q.Key === mod.Key,
                                                                            ).ModDesc +
                                                                            (mod.Count < 0
                                                                                ? " -1"
                                                                                : "") +
                                                                            (mod.Count > 1 ||
                                                                                mod.Count < -1
                                                                                ? " mods"
                                                                                : " mod")
                                                                            : talents.find(
                                                                                (t) => t.Key === mod.Key,
                                                                            )
                                                                                ? "Innate Talent: " +
                                                                                talents.find(
                                                                                    (t) => t.Key === mod.Key,
                                                                                ).Name
                                                                                : skills.find(
                                                                                    (s) =>
                                                                                        s.Key === mod.Key,
                                                                                )
                                                                                    ? mod.Count +
                                                                                    " Skill: " +
                                                                                    skills.find(
                                                                                        (s) =>
                                                                                            s.Key === mod.Key,
                                                                                    ).skill +
                                                                                    (mod.Count > 1
                                                                                        ? " mods"
                                                                                        : " mod")
                                                                                    : "",
                                                                    ),
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                ),
                                            )}
                                        </Typography>
                                    )}
                                    {profile.Attachments && profile.Attachments[0] && (
                                        <Typography variant="h6">Attachments</Typography>
                                    )}
                                    {profile.Attachments && profile.Attachments.map((attachment, attachI) => (
                                        <Paper key={attachment.Key + attachI} variant="outlined">
                                            <Typography sx={{ mt: "5px" }}>
                                                {attachment.Name}
                                            </Typography>
                                            <Typography sx={{ mt: "5px" }}>
                                                HP: {attachment.HP}
                                            </Typography>
                                            {attachment.BaseMods && (
                                                <Typography sx={{ mt: "5px" }}>
                                                    Base Modifiers:{" "}
                                                    {attachment.BaseMods.map((mod, index) => (
                                                        <div key={mod.Key + index}>
                                                            <div
                                                                style={{ padding: "5px" }}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: MarkedText.renderer(mod.MiscDesc),
                                                                }}
                                                            />
                                                            {mod.Key && (
                                                                <div
                                                                    style={{ padding: "5px" }}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: MarkedText.renderer(getModText(qualities, talents, skills, mod)),
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                    ))}
                                                </Typography>
                                            )}
                                            {attachment.AddedMods && (
                                                <Typography sx={{ mt: "5px" }}>
                                                    Modification Options:{" "}
                                                    {attachment.AddedMods.map((mod, index) => (
                                                        <div key={mod.Key + index}>
                                                            <div
                                                                style={{ padding: "5px" }}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: MarkedText.renderer(mod.MiscDesc),
                                                                }}
                                                            />
                                                            {mod.Key && (
                                                                <div
                                                                    style={{ padding: "5px" }}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: MarkedText.renderer(getModText(qualities, talents, skills, mod)),
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                    ))}
                                                </Typography>
                                            )}
                                        </Paper>
                                    ))}
                                </>
                            );
                        })()}
                    </Grid>
                </Grid>}
            </Box>
        </Modal >
    );
};

export default VehicleCraftingModal;