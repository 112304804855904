import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import callsToAdventureData from "./data/callToAdventure.json";
import forceExperiencesData from "./data/forceExperience.json";
import culturesData from "./data/cultures.json";
import formatSource from "./Util/FormatSource";

let callsToAdventure = callsToAdventureData
let forceExperiences = forceExperiencesData
let cultures = culturesData

function App({ character, handleCharacterChange, appFiles }) {
  useEffect(() => {
    if (appFiles.callToAdventure && appFiles.forceExperience && appFiles.cultures) {
      callsToAdventure = JSON.parse(appFiles.callToAdventure)
      forceExperiences = JSON.parse(appFiles.forceExperience)
      cultures = JSON.parse(appFiles.cultures)
    }
  }, [appFiles.callToAdventure, appFiles.forceExperience, appFiles.cultures]);

  const [culturalBackground, setCulturalBackground] = useState(
    character.Background.Indexes.Culture,
  );
  const [experiencingTheForce, setExperiencingTheForce] = useState(
    character.Background.Indexes.Force,
  );
  const [callToAdventure, setCallToAdventure] = useState(
    character.Background.Indexes.Adventure,
  );
  const [textEntry, setTextEntry] = useState(character.Background.Text);

  const handleCulturalBackgroundChange = (event) => {
    let background = character.Background;
    background.Culture = cultures[event.target.value];
    background.Indexes.Culture = event.target.value;
    setCulturalBackground(event.target.value);
    handleCharacterChange({
      Background: background,
    });
  };

  const handleExperiencingTheForceChange = (event) => {
    let background = character.Background;
    background.Force = forceExperiences[event.target.value];
    background.Indexes.Force = event.target.value;
    setExperiencingTheForce(event.target.value);
    handleCharacterChange({
      Background: background,
    });
  };

  const handleCallToAdventureChange = (event) => {
    let background = character.Background;
    background.Adventure = callsToAdventure[event.target.value];
    background.Indexes.Adventure = event.target.value;
    setCallToAdventure(event.target.value);
    handleCharacterChange({
      Background: background,
    });
  };

  const handleTextEntryChange = (event) => {
    let background = character.Background;
    background.Text = event.target.value;
    setTextEntry(event.target.value);
    handleCharacterChange({
      Background: background,
    });
  };

  return (
    <Container style={{ marginBottom: "16px" }}>
      <h1>Character Background</h1>
      <Paper elevation={3} style={{ padding: "16px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Story"
              multiline
              rows={4}
              value={textEntry}
              onChange={handleTextEntryChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>Cultural Background</InputLabel>
              <Select
                value={culturalBackground}
                onChange={handleCulturalBackgroundChange}
              >
                {cultures.map((culture, index) => (
                  <MenuItem key={index} value={index}>
                    {culture.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {cultures[culturalBackground] && (
              <>
                <Typography>
                  {cultures[culturalBackground].Description}
                </Typography>
                <Typography>
                  {formatSource(cultures[culturalBackground].Source)}
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>Experiencing the Force</InputLabel>
              <Select
                value={experiencingTheForce}
                onChange={handleExperiencingTheForceChange}
              >
                {forceExperiences.map((forceExperience, index) => (
                  <MenuItem key={index} value={index}>
                    {forceExperience.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {forceExperiences[experiencingTheForce] && (
              <>
                <Typography>
                  {forceExperiences[experiencingTheForce].Description}
                </Typography>
                <Typography>
                  {formatSource(forceExperiences[experiencingTheForce].Source)}
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>Call to Adventure</InputLabel>
              <Select
                value={callToAdventure}
                onChange={handleCallToAdventureChange}
              >
                {callsToAdventure.map((callToAdventure, index) => (
                  <MenuItem key={index} value={index}>
                    {callToAdventure.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {callsToAdventure[callToAdventure] && (
              <>
                <Typography>
                  {callsToAdventure[callToAdventure].Description}
                </Typography>
                <Typography>
                  {formatSource(callsToAdventure[callToAdventure].Source)}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

export default App;
