import React, { useState } from "react";
import { AppBar, Tabs, Tab, Toolbar, Typography, Box } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import LandingTab from "./Tab0.Landing"
import BackgroundTab from "./Tab1.Background";
import ODMTab from "./Tab2.ObligationDutyMorality";
import SpeciesTab from "./Tab3.Species";
import CareerTab from "./Tab4.CareerSpecialization";
import CharacteristicsTab from "./Tab5.CharacteristicsSkills";
import ForceTab from "./Tab6.ForcePowers";
import MotivationTab from "./Tab7.Motivations";
import EquipmentTab from "./Tab8.Equipment";
import GroupResourceTab from "./Tab9.GroupResource";
import ExtrasTab from "./Tab10.Misc"
import CharacterSheetTab from "./Tab11.CharacterSheet";
import characterSheet from "./data/characterSheet.json";
import skills from "./data/skills.json";
import "./styles.css";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
  typography: {
    fontFamily: "Tektur",
  },
});

const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
  typography: {
    fontFamily: "Tektur",
  },
});


function App() {
  const [character, setCharacter] = useState({
    ...characterSheet,
    ...{ Skills: skills },
  });
  const [appFiles, setAppFiles] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [theme, setTheme] = useState(darkTheme);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleCharacterChange = (value) => {
    setCharacter({ ...character, ...value });
  };

  if (character.GMGrantMode && character.GMGrantMode !== character.XP) {
    handleCharacterChange({ XP: character.GMGrantMode })
  }

  const handleThemeChange = () => {
    setTheme(theme === darkTheme ? lightTheme : darkTheme);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{
        display: 'flex',
        overflowX: 'auto',
        justifyContent: 'center',
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        scrollbarWidth: 'none',
      }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          className="no-print"
        >
          <Tab label="Home" wrapped />
          <Tab label="Background" wrapped />
          <Tab label="Obligation/Duty/Morality" wrapped />
          <Tab label="Species" wrapped />
          <Tab label="Career/Specialization" wrapped />
          <Tab label="Characteristics/Skills" wrapped />
          <Tab
            label="Force Powers"
            sx={{
              display: (character.ForceRating && character.ForceRating > 0) ? 'flex' : 'none',
            }}
          />
          <Tab label="Motivations" wrapped />
          <Tab label="Equipment" wrapped />
          <Tab label="Group Resource" wrapped />
          <Tab label="Misc" wrapped />
          <Tab label="Character Sheet" wrapped />
        </Tabs>
      </Box>
      {/* Content for each tab */}
      {
        activeTab === 0 && (
          <LandingTab
            appFiles={appFiles}
            setAppFiles={setAppFiles}
            handleThemeChange={handleThemeChange}
          />
        )
      }
      {
        activeTab === 1 && (
          <BackgroundTab
            character={character}
            handleCharacterChange={handleCharacterChange}
            appFiles={appFiles}
          />
        )
      }
      {
        activeTab === 2 && (
          <ODMTab
            character={character}
            handleCharacterChange={handleCharacterChange}
            appFiles={appFiles}
          />
        )
      }
      {
        activeTab === 3 && (
          <SpeciesTab
            character={character}
            handleCharacterChange={handleCharacterChange}
            appFiles={appFiles}
          />
        )
      }
      {
        activeTab === 4 && (
          <CareerTab
            character={character}
            handleCharacterChange={handleCharacterChange}
            appFiles={appFiles}
          />
        )
      }
      {
        activeTab === 5 && (
          <CharacteristicsTab
            character={character}
            handleCharacterChange={handleCharacterChange}
            appFiles={appFiles}
          />
        )
      }
      {
        activeTab === 6 && (
          <ForceTab
            character={character}
            handleCharacterChange={handleCharacterChange}
            appFiles={appFiles}
          />
        )
      }
      {
        activeTab === 7 && (
          <MotivationTab
            character={character}
            handleCharacterChange={handleCharacterChange}
            appFiles={appFiles}
          />
        )
      }
      {
        activeTab === 8 && (
          <EquipmentTab
            character={character}
            handleCharacterChange={handleCharacterChange}
            appFiles={appFiles}
          />
        )
      }
      {
        activeTab === 9 && (
          <GroupResourceTab
            character={character}
            handleCharacterChange={handleCharacterChange}
            appFiles={appFiles}
          />
        )
      }
      {
        activeTab === 10 && (
          <ExtrasTab
            character={character}
            handleCharacterChange={handleCharacterChange}
            appFiles={appFiles}
          />
        )
      }
      {
        activeTab === 11 && (
          <CharacterSheetTab
            character={character}
            handleCharacterChange={handleCharacterChange}
            appFiles={appFiles}
          />
        )
      }
      <AppBar position="sticky" color="primary" sx={{ top: "auto", bottom: 0 }} className="no-print">
        {activeTab !== 0 && (<Toolbar style={{ display: "flex" }}>
          {character.GMGrantMode && <Typography color="error">GM Grant mode is on, all XP expenditures will be ignored.</Typography>}
          <Typography
            color={character.XP < 0 ? "error" : "inherit"}
            variant="subtitle1"
            style={{ padding: "16px" }}
          >
            XP: {character.XP}
          </Typography>
          <Typography
            color={character.Credits < 0 ? "error" : "inherit"}
            variant="subtitle1"
          >
            Credits: {character.Credits}
          </Typography>
        </Toolbar>)}
      </AppBar>
    </ThemeProvider >
  );
}
export default App;
