import characteristics from "../data/characteristics.json"

const getModText = (qualities, talents, skills, mod, solo) => {
    let count = mod.Count || 1
    if (solo) { count = 1 }
    let text =
        qualities.find((q) => q.Key === mod.Key)
            ? (!solo ? Math.abs(count) + " " : "") +
            qualities.find(
                (q) => q.Key === mod.Key,
            ).ModDesc +
            (count < 0 ? " -1" : "") +
            (count > 1 || count < -1
                ? " mods"
                : " mod")
            : talents.find((t) => t.Key === mod.Key)
                ? "Innate Talent: " +
                talents.find(
                    (t) => t.Key === mod.Key,
                ).Name
                : skills.find(
                    (s) => s.Key === mod.Key,
                )
                    ? count +
                    " Skill: " +
                    skills.find(
                        (s) => s.Key === mod.Key,
                    ).skill +
                    (count > 1 ? " mods" : " mod")
                    : characteristics.find((c) => c.Key === mod.Key) ?
                        (count ? count + " " : "1 ") + characteristics.find((c) => c.Key === mod.Key).Name + " mod" + (count > 1 ? "s" : "")
                        : ""
    return text
}

export default getModText