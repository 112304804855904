const findNestedProperty = (obj, propertyName) => {
    let results = [];

    const search = (currentObj) => {
        if (currentObj === null || typeof currentObj !== 'object') {
            return;
        }

        if (propertyName in currentObj) {
            results.push(currentObj[propertyName]);
        }

        if (Array.isArray(currentObj)) {
            currentObj.forEach(item => search(item));
        } else {
            for (let key in currentObj) {
                if (currentObj.hasOwnProperty(key)) {
                    search(currentObj[key]);
                }
            }
        }
    };

    search(obj);
    return results.length > 0 ? results : undefined;
};

export default findNestedProperty