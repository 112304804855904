import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Paper,
  TextField,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Checkbox,
  Button,
} from "@mui/material";
import obligationsData from "./data/obligations.json";
import dutiesData from "./data/duties.json";
import moralitiesData from "./data/moralities.json";
import formatSource from "./Util/FormatSource";

const blankObligation = {
  Name: "",
  Descpription: "",
  Toggle: true,
  Obligation: "",
  Starting: "",
  Text: "",
  XP5: false,
  XP10: false,
  C1: false,
  C2: false,
  Total: 0,
};
const blankDuty = {
  Name: "",
  Description: "",
  Toggle: true,
  Duty: "",
  Starting: "",
  Text: "",
  XP5: false,
  XP10: false,
  C1: false,
  C2: false,
  Total: 0,
};

let obligations = obligationsData
let duties = dutiesData
let moralities = moralitiesData

function App({ character, handleCharacterChange, appFiles }) {
  const [obligation, setObligation] = useState(character.Obligations);
  const [duty, setDuty] = useState(character.Duties);
  const [moralityPair, setMoralityPair] = useState(
    character.Morality.StrengthWeakness,
  );
  const [moralityValue, setMoralityValue] = useState(character.Morality.Score);
  const [moralityBonus5xp1k, setMoralityBonus5xp1k] = useState(
    character.Morality.XPC,
  );
  const [moralityBonus10xp, setMoralityBonus10xp] = useState(
    character.Morality.XP10,
  );
  const [moralityBonus2k, setMoralityBonus2k] = useState(character.Morality.C2);
  const [moralityUp, setMoralityUp] = useState(character.Morality.StartLight);
  const [moralityDown, setMoralityDown] = useState(
    character.Morality.StartDark,
  );
  const [moralityToggle, setMoralityToggle] = useState(
    character.Morality.Toggle,
  );

  useEffect(() => {
    if (appFiles.obligations && appFiles.duties && appFiles.moralities) {
      obligations = JSON.parse(appFiles.obligations)
      duties = JSON.parse(appFiles.duties)
      moralities = JSON.parse(appFiles.moralities)
    }
  }, [appFiles.obligations, appFiles.duties, appFiles.moralities]);

  const handleObligationToggle = () => {
    let currentObject = [...obligation];
    let delta = { Toggle: !obligation[0].Toggle };
    let toggledObligations = { ...currentObject[0], ...delta };
    currentObject[0] = toggledObligations;
    setObligation(currentObject);
    handleCharacterChange({ Obligations: currentObject });
  };

  const handleDutyToggle = () => {
    let currentObject = [...duty];
    let delta = { Toggle: !duty[0].Toggle };
    let toggledDuties = { ...currentObject[0], ...delta };
    currentObject[0] = toggledDuties;
    setDuty(currentObject);
    handleCharacterChange({ Duties: currentObject });
  };

  const handleMoralityToggle = () => {
    setMoralityToggle(!moralityToggle);
    let delta = { Toggle: !moralityToggle };
    let Morality = { ...character.Morality, ...delta };
    handleCharacterChange({ Morality });
  };

  const handleObligationChange = (event, index) => {
    const newObligations = [...obligation];
    newObligations[index] = {
      ...newObligations[index],
      ...obligations.find((o) => o.Key === event.target.value),
    };
    if (newObligations[index] != obligation[index]) {
      setObligation(newObligations);
      handleCharacterChange({ Obligations: newObligations });
    }
  };

  const handleStartingObligationChange = (event) => {
    let newTotal =
      parseInt(obligation[0].Total) +
      parseInt(event.target.value) -
      (parseInt(obligation[0].Starting) || 0);
    let currentObject = [...obligation];
    let delta = {
      Total: newTotal,
      Starting: parseInt(event.target.value),
    };
    currentObject[0] = { ...currentObject[0], ...delta };
    setObligation(currentObject);
    handleCharacterChange({ Obligations: currentObject });
  };

  const handleObligationBonus5xpChange = (event) => {
    let currentObject = [...obligation];
    let delta = { XP5: !obligation[0].XP5, Total: obligation[0].Total };
    if (!obligation[0].XP5) {
      delta.Total += 5;
      currentObject[0] = { ...currentObject[0], ...delta };
      handleCharacterChange({
        XP: character.XP + 5,
        Obligations: currentObject,
      });
    } else {
      delta.Total -= 5;
      currentObject[0] = { ...currentObject[0], ...delta };
      handleCharacterChange({
        XP: character.XP - 5,
        Obligations: currentObject,
      });
    }
    setObligation(currentObject);
  };

  const handleObligationBonus10xpChange = (event) => {
    let currentObject = [...obligation];
    let delta = { XP10: !obligation[0].XP10, Total: obligation[0].Total };
    if (!obligation[0].XP10) {
      delta.Total += 10;
      currentObject[0] = { ...currentObject[0], ...delta };
      handleCharacterChange({
        XP: character.XP + 10,
        Obligations: currentObject,
      });
    } else {
      delta.Total -= 10;
      currentObject[0] = { ...currentObject[0], ...delta };
      handleCharacterChange({
        XP: character.XP - 10,
        Obligations: currentObject,
      });
    }
    setObligation(currentObject);
  };

  const handleObligationBonus1kChange = (event) => {
    let currentObject = [...obligation];
    let delta = { C1: !obligation[0].C1, Total: obligation[0].Total };
    if (!obligation[0].C1) {
      delta.Total += 5;
      currentObject[0] = { ...currentObject[0], ...delta };
      handleCharacterChange({
        Credits: character.Credits + 1000,
        Obligations: currentObject,
      });
    } else {
      delta.Total -= 5;
      currentObject[0] = { ...currentObject[0], ...delta };
      handleCharacterChange({
        Credits: character.Credits + -1000,
        Obligations: currentObject,
      });
    }
    setObligation(currentObject);
  };

  const handleObligationBonus2kChange = (event) => {
    let currentObject = [...obligation];
    let delta = { C2: !obligation[0].C2, Total: obligation[0].Total };
    if (!obligation[0].C2) {
      delta.Total += 10;
      currentObject[0] = { ...currentObject[0], ...delta };
      handleCharacterChange({
        Credits: character.Credits + 2500,
        Obligations: currentObject,
      });
    } else {
      delta.Total -= 10;
      currentObject[0] = { ...currentObject[0], ...delta };
      handleCharacterChange({
        Credits: character.Credits + -2500,
        Obligations: currentObject,
      });
    }
    setObligation(currentObject);
  };

  const handleDutyChange = (event, index) => {
    const newDuties = [...duty];
    newDuties[index] = {
      ...newDuties[index],
      ...duties.find((d) => d.Key === event.target.value),
    };
    if (newDuties[index] != duty[index]) {
      setDuty(newDuties);
      handleCharacterChange({ Duties: newDuties });
    }
  };

  const handleStartingDutyChange = (event) => {
    let newTotal =
      parseInt(duty[0].Total) +
      parseInt(event.target.value) -
      (parseInt(duty[0].Starting) || 0);
    let currentObject = [...duty];
    let delta = {
      Total: newTotal,
      Starting: parseInt(event.target.value),
    };
    currentObject[0] = { ...currentObject[0], ...delta };
    setDuty(currentObject);
    handleCharacterChange({ Duties: currentObject });
  };

  const handleDutyBonus5xpChange = (event) => {
    let currentObject = [...duty];
    let delta = { XP5: !duty[0].XP5, Total: duty[0].Total };
    if (!duty[0].XP5) {
      delta.Total -= 5;
      currentObject[0] = { ...currentObject[0], ...delta };
      handleCharacterChange({
        XP: character.XP + 5,
        Duties: currentObject,
      });
    } else {
      delta.Total += 5;
      currentObject[0] = { ...currentObject[0], ...delta };
      handleCharacterChange({
        XP: character.XP - 5,
        Duties: currentObject,
      });
    }
    setDuty(currentObject);
  };

  const handleDutyBonus10xpChange = (event) => {
    let currentObject = [...duty];
    let delta = { XP10: !duty[0].XP10, Total: duty[0].Total };
    if (!duty[0].XP10) {
      delta.Total -= 10;
      currentObject[0] = { ...currentObject[0], ...delta };
      handleCharacterChange({
        XP: character.XP + 10,
        Duties: currentObject,
      });
    } else {
      delta.Total += 10;
      currentObject[0] = { ...currentObject[0], ...delta };
      handleCharacterChange({
        XP: character.XP - 10,
        Duties: currentObject,
      });
    }
    setDuty(currentObject);
  };

  const handleDutyBonus1kChange = (event) => {
    let currentObject = [...duty];
    let delta = { C1: !duty[0].C1, Total: duty[0].Total };
    if (!duty[0].C1) {
      delta.Total -= 5;
      currentObject[0] = { ...currentObject[0], ...delta };
      handleCharacterChange({
        Credits: character.Credits + 1000,
        Duties: currentObject,
      });
    } else {
      delta.Total += 5;
      currentObject[0] = { ...currentObject[0], ...delta };
      handleCharacterChange({
        Credits: character.Credits + -1000,
        Duties: currentObject,
      });
    }
    setDuty(currentObject);
  };

  const handleDutyBonus2kChange = (event) => {
    let currentObject = [...duty];
    let delta = { C2: !duty[0].C2, Total: duty[0].Total };
    if (!duty[0].C2) {
      delta.Total -= 10;
      currentObject[0] = { ...currentObject[0], ...delta };
      handleCharacterChange({
        Credits: character.Credits + 2500,
        Duties: currentObject,
      });
    } else {
      delta.Total += 10;
      currentObject[0] = { ...currentObject[0], ...delta };
      handleCharacterChange({
        Credits: character.Credits + -2500,
        Duties: currentObject,
      });
    }
    setDuty(currentObject);
  };

  const handleMoralityStrengthChange = (event, index) => {
    let currentObject = character.Morality;
    let strength = moralities.find((m) => m.Key === event.target.value);
    strength = Object.assign(strength, { Text: "" });
    currentObject.StrengthWeakness[index].Strength = strength;
    setMoralityPair(currentObject.StrengthWeakness);
    handleCharacterChange({ Morality: currentObject });
  };

  const handleMoralityWeaknessChange = (event, index) => {
    let currentObject = character.Morality;
    let weakness = moralities.find((m) => m.Key === event.target.value);
    weakness = Object.assign(weakness, { Text: "" });
    currentObject.StrengthWeakness[index].Weakness = weakness;
    setMoralityPair(currentObject.StrengthWeakness);
    handleCharacterChange({ Morality: currentObject });
  };

  const handleObligationTextChange = (event, index) => {
    let currentObject = [...obligation];
    let delta = { Text: event.target.value };
    currentObject[index] = { ...currentObject[index], ...delta };
    setObligation(currentObject);
    handleCharacterChange({ Obligations: currentObject });
  };

  const handleDutyTextChange = (event, index) => {
    let currentObject = [...duty];
    let delta = { Text: event.target.value };
    currentObject[index] = { ...currentObject[index], ...delta };
    setDuty(currentObject);
    handleCharacterChange({ Duties: currentObject });
  };

  const handleMoralityStrengthTextChange = (event, index) => {
    let currentObject = { ...character.Morality };
    currentObject.StrengthWeakness[index].Strength.Text = event.target.value;
    setMoralityPair(currentObject.StrengthWeakness);
    handleCharacterChange({ currentObject });
  };

  const handleMoralityWeaknessTextChange = (event, index) => {
    let currentObject = { ...character.Morality };
    currentObject.StrengthWeakness[index].Weakness.Text = event.target.value;
    setMoralityPair(currentObject.StrengthWeakness);
    handleCharacterChange({ currentObject });
  };

  const handleMoralityBonus5xp1kChange = (event) => {
    setMoralityBonus5xp1k(!moralityBonus5xp1k);
    let currentObject = character.Morality;
    let delta = {
      ...currentObject,
      XPC: !moralityBonus5xp1k,
    };
    let Morality = {
      ...currentObject,
      ...delta,
    };
    if (!moralityBonus5xp1k) {
      handleCharacterChange({
        XP: character.XP + 5,
        Credits: character.Credits + 1000,
        Morality,
      });
    } else {
      handleCharacterChange({
        XP: character.XP + -5,
        Credits: character.Credits + -1000,
        Morality,
      });
    }
  };

  const handleMoralityBonus10xpChange = (event) => {
    setMoralityBonus10xp(!moralityBonus10xp);
    let currentObject = character.Morality;
    let delta = {
      ...currentObject,
      XP10: !moralityBonus10xp,
    };
    let Morality = {
      ...currentObject,
      ...delta,
    };
    if (!moralityBonus10xp) {
      handleCharacterChange({ XP: character.XP + 10, Morality });
    } else {
      handleCharacterChange({ XP: character.XP + -10, Morality });
    }
  };

  const handleMoralityBonus2kChange = (event) => {
    setMoralityBonus2k(!moralityBonus2k);
    let currentObject = character.Morality;
    let delta = {
      ...currentObject,
      C2: !moralityBonus2k,
    };
    let Morality = {
      ...currentObject,
      ...delta,
    };
    if (!moralityBonus2k) {
      handleCharacterChange({ Credits: character.Credits + 2500, Morality });
    } else {
      handleCharacterChange({ Credits: character.Credits + -2500, Morality });
    }
  };

  const handleMoralityUpChange = (event) => {
    setMoralityUp(!moralityUp);
    let currentObject = character.Morality;
    let delta = {};
    let Morality = {};
    if (!moralityUp) {
      setMoralityValue(moralityValue + 21);
      delta = {
        ...currentObject,
        StartLight: !moralityUp,
        Score: moralityValue + 21,
      };
      Morality = {
        ...currentObject,
        ...delta,
      };
      handleCharacterChange({ Morality });
    } else {
      setMoralityValue(moralityValue - 21);
      delta = {
        ...currentObject,
        StartLight: !moralityUp,
        Score: moralityValue - 21,
      };
      Morality = {
        ...currentObject,
        ...delta,
      };
      handleCharacterChange({ Morality });
    }
  };

  const handleMoralityDownChange = (event) => {
    setMoralityDown(!moralityDown);
    let currentObject = character.Morality;
    let delta = {};
    let Morality = {};
    if (!moralityDown) {
      setMoralityValue(moralityValue - 21);
      delta = {
        ...currentObject,
        StartDark: !moralityDown,
        Score: moralityValue - 21,
      };
      Morality = {
        ...currentObject,
        ...delta,
      };
      handleCharacterChange({ Morality });
    } else {
      setMoralityValue(moralityValue + 21);
      delta = {
        ...currentObject,
        StartDark: !moralityDown,
        Score: moralityValue + 21,
      };
      Morality = {
        ...currentObject,
        ...delta,
      };
      handleCharacterChange({ Morality });
      handleCharacterChange({ Morality });
    }
  };

  const addObligation = (event) => {
    let newObligation = [...obligation];
    newObligation.push(blankObligation);
    setObligation(newObligation);
    handleCharacterChange({ Obligations: newObligation });
  };

  const removeObligation = (index) => {
    let newObligation = [...obligation];
    newObligation.splice(index, 1);
    if (newObligation[0] == undefined) {
      newObligation.push(blankObligation);
    }
    setObligation(newObligation);
    handleCharacterChange({ Obligations: newObligation });
  };

  const addDuty = (event) => {
    let newDuty = [...duty];
    newDuty.push(blankDuty);
    setDuty(newDuty);
    handleCharacterChange({ Duties: newDuty });
  };

  const removeDuty = (index) => {
    let newDuty = [...duty];
    newDuty.splice(index, 1);
    if (newDuty[0] == undefined) {
      newDuty.push(blankDuty);
    }
    setDuty(newDuty);
    handleCharacterChange({ Duties: newDuty });
  };
  const addMorality = (event) => {
    let newMorality = [...moralityPair];
    newMorality.push({
      Strength: { Text: "", Key: null },
      Weakness: { Text: "", Key: null },
    });
    setMoralityPair(newMorality);
    newMorality = { ...character.Morality, StrengthWeakness: newMorality };
    handleCharacterChange({ Morality: newMorality });
  };

  const removeMorality = (index) => {
    let newMorality = [...moralityPair];
    newMorality.splice(index, 1);
    if (newMorality[0] == undefined) {
      newMorality.push({
        Strength: { Text: "", Key: null },
        Weakness: { Text: "", Key: null },
      });
    }
    setMoralityPair(newMorality);
    newMorality = { ...character.Morality, StrengthWeakness: newMorality };
    handleCharacterChange({ Morality: newMorality });
  };

  const toggleCount = () => {
    let toggles = 0;
    if (obligation[0].Toggle) {
      toggles += 1;
    }
    if (duty[0].Toggle) {
      toggles += 1;
    }
    if (character.Morality.Toggle) {
      toggles += 2;
    }
    return toggles;
  };

  return (
    <Container style={{ padding: "25px" }}>
      <FormControlLabel
        key="obligation"
        control={
          <Checkbox
            checked={obligation[0].Toggle}
            onChange={() => handleObligationToggle()}
          />
        }
        label="Obligation"
      />
      <FormControlLabel
        key="duty"
        control={
          <Checkbox
            checked={duty[0].Toggle}
            onChange={() => handleDutyToggle()}
          />
        }
        label="Duty"
      />
      <FormControlLabel
        key="morality"
        control={
          <Checkbox
            checked={moralityToggle}
            onChange={() => handleMoralityToggle()}
          />
        }
        label="Morality"
      />
      <Paper elevation={3} style={{ padding: "25px" }}>
        <Grid container spacing={2}>
          {obligation[0].Toggle && (
            <Grid item xs={12} sm={12 / toggleCount()}>
              <Grid container spacing={2}>
                {obligation.map((obligation, index) => (
                  <Grid key={index} item xs={12} sm={12}>
                    <Grid container>
                      <Grid item xs={12} sm={11}>
                        <FormControl fullWidth>
                          <InputLabel>Obligation</InputLabel>
                          <Select
                            value={obligation.Key || ""}
                            onChange={(event) =>
                              handleObligationChange(event, index)
                            }
                          >
                            {obligations.map((obligation, index) => (
                              <MenuItem
                                key={obligation.Key}
                                value={obligation.Key}
                              >
                                {obligation.Name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={1}
                        style={{ display: "grid", autoGridFlow: "column" }}
                      >
                        {index === 0 && (
                          <Button
                            size="small"
                            style={{
                              fontSize: "0.7rem",
                              minWidth: "30px",
                            }}
                            onClick={() => addObligation()}
                          >
                            +
                          </Button>
                        )}
                        <Button
                          size="small"
                          style={{
                            fontSize: "0.7rem",
                            minWidth: "30px",
                          }}
                          onClick={() => removeObligation(index)}
                        >
                          -
                        </Button>
                      </Grid>
                    </Grid>
                    {obligation && (
                      <>
                        <Typography fontSize=".8rem">
                          {obligation.Description}
                        </Typography>
                        {obligation.Source && (<Typography fontSize=".8rem">
                          {formatSource(obligation.Source)}
                        </Typography>)}
                      </>
                    )}
                    <TextField
                      fullWidth
                      label="Obligation Details"
                      multiline
                      rows={3}
                      value={obligation.Text}
                      onChange={(event) =>
                        handleObligationTextChange(event, index)
                      }
                    />
                  </Grid>
                ))}
              </Grid>
              <FormControl fullWidth>
                <InputLabel>Starting Obligation</InputLabel>
                <Select
                  value={obligation[0].Starting || ""}
                  onChange={handleStartingObligationChange}
                >
                  <MenuItem key="5" value="5">
                    5
                  </MenuItem>
                  <MenuItem key="10" value="10">
                    10
                  </MenuItem>
                  <MenuItem key="15" value="15">
                    15
                  </MenuItem>
                  <MenuItem key="20" value="20">
                    20
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControlLabel
                key="5"
                control={
                  <Checkbox
                    checked={obligation[0].XP5}
                    onChange={() => handleObligationBonus5xpChange()}
                  />
                }
                label="+5 starting XP"
              />
              <FormControlLabel
                key="10"
                control={
                  <Checkbox
                    checked={obligation[0].XP10}
                    onChange={() => handleObligationBonus10xpChange()}
                  />
                }
                label="+10 starting XP"
              />
              <FormControlLabel
                key="1k"
                control={
                  <Checkbox
                    checked={obligation[0].C1}
                    onChange={() => handleObligationBonus1kChange()}
                  />
                }
                label="+1000 starting credits"
              />
              <FormControlLabel
                key="2k"
                control={
                  <Checkbox
                    checked={obligation[0].C2}
                    onChange={() => handleObligationBonus2kChange()}
                  />
                }
                label="+2500 starting credits"
              />
              <Typography>Obligation total: {obligation[0].Total} </Typography>
              {obligation[0].Total > parseInt(obligation[0].Starting) * 2 && (
                <Typography color="error">
                  Obligation bonuses exceed limits from Starting Obligation
                </Typography>
              )}
            </Grid>
          )}
          {duty[0].Toggle && (
            <Grid item xs={12} sm={12 / toggleCount()}>
              {duty.map((duty, index) => (
                <div key={duty.Key + index}>
                  <Grid container>
                    <Grid item xs={12} sm={11}>
                      <FormControl fullWidth>
                        <InputLabel>Duty</InputLabel>
                        <Select
                          value={duty.Key || ""}
                          onChange={(event) => handleDutyChange(event, index)}
                        >
                          {duties.map((duty, index) => (
                            <MenuItem key={duty.Key} value={duty.Key}>
                              {duty.Name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={1}
                      style={{ display: "grid", autoGridFlow: "column" }}
                    >
                      {index === 0 && (
                        <Button
                          size="small"
                          style={{
                            fontSize: "0.7rem",
                            minWidth: "30px",
                          }}
                          onClick={() => addDuty()}
                        >
                          +
                        </Button>
                      )}
                      <Button
                        size="small"
                        style={{
                          fontSize: "0.7rem",
                          minWidth: "30px",
                        }}
                        onClick={() => removeDuty(index)}
                      >
                        -
                      </Button>
                    </Grid>
                  </Grid>
                  {duty && (<>
                    <Typography fontSize=".8rem">{duty.Description}</Typography>
                    {duty.Source && (<Typography fontSize=".8rem">{formatSource(duty.Source)}</Typography>)}
                  </>
                  )}
                  <TextField
                    fullWidth
                    label="Duty Details"
                    multiline
                    rows={3}
                    value={duty.Text}
                    onChange={(event) => handleDutyTextChange(event, index)}
                  />
                </div>
              ))}
              <FormControl fullWidth>
                <InputLabel>Starting Duty</InputLabel>
                <Select
                  value={duty[0].Starting || ""}
                  onChange={handleStartingDutyChange}
                >
                  <MenuItem key="5" value="5">
                    5
                  </MenuItem>
                  <MenuItem key="10" value="10">
                    10
                  </MenuItem>
                  <MenuItem key="15" value="15">
                    15
                  </MenuItem>
                  <MenuItem key="20" value="20">
                    20
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControlLabel
                key="5"
                control={
                  <Checkbox
                    checked={duty[0].XP5}
                    onChange={() => handleDutyBonus5xpChange()}
                  />
                }
                label="+5 starting XP"
              />
              <FormControlLabel
                key="10"
                control={
                  <Checkbox
                    checked={duty[0].XP10}
                    onChange={() => handleDutyBonus10xpChange()}
                  />
                }
                label="+10 starting XP"
              />
              <FormControlLabel
                key="1k"
                control={
                  <Checkbox
                    checked={duty[0].C1}
                    onChange={() => handleDutyBonus1kChange()}
                  />
                }
                label="+1000 starting credits"
              />
              <FormControlLabel
                key="2k"
                control={
                  <Checkbox
                    checked={duty[0].C2}
                    onChange={() => handleDutyBonus2kChange()}
                  />
                }
                label="+2500 starting credits"
              />
              <Typography>Duty total: {duty[0].Total}</Typography>
              {duty[0].Total < 0 && (
                <Typography color="error">
                  Duty bonuses exceed limits from Starting Duty
                </Typography>
              )}
            </Grid>
          )}
          {moralityToggle && (
            <Grid item xs={12} sm={24 / toggleCount()}>
              {moralityPair.map((pair, index) => (
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>Emotional Strength</InputLabel>
                      <Select
                        value={pair.Strength.Key || ""}
                        onChange={(event) =>
                          handleMoralityStrengthChange(event, index)
                        }
                      >
                        {moralities
                          .filter(
                            (moralityStrength) =>
                              moralityStrength.Type === "Strength",
                          )
                          .map((moralityStrength, index) => (
                            <MenuItem
                              key={moralityStrength.Key}
                              value={moralityStrength.Key}
                            >
                              {moralityStrength.Name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    {pair.Strength && (
                      <>
                        <Typography fontSize=".8rem">
                          {pair.Strength.Description}
                        </Typography>
                        {pair.Strength.Source && (<Typography fontSize=".8rem">
                          {formatSource(pair.Strength.Source)}
                        </Typography>)}
                      </>
                    )}
                    <TextField
                      fullWidth
                      label="Emotional Strength Details"
                      multiline
                      rows={4}
                      value={moralityPair[index].Strength.Text}
                      onChange={(event) =>
                        handleMoralityStrengthTextChange(event, index)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container>
                      <Grid item xs={12} sm={11}>
                        <FormControl fullWidth>
                          <InputLabel>Emotional Weakness</InputLabel>
                          <Select
                            value={pair.Weakness.Key || ""}
                            onChange={(event) =>
                              handleMoralityWeaknessChange(event, index)
                            }
                          >
                            {moralities
                              .filter(
                                (morality) => morality.Type === "Weakness",
                              )
                              .map((morality, index) => (
                                <MenuItem
                                  key={morality.Key}
                                  value={morality.Key}
                                >
                                  {morality.Name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={1}
                        style={{ display: "grid", autoGridFlow: "column" }}
                      >
                        {index === 0 && (
                          <Button
                            size="small"
                            style={{
                              fontSize: "0.7rem",
                              minWidth: "30px",
                            }}
                            onClick={() => addMorality()}
                          >
                            +
                          </Button>
                        )}
                        <Button
                          size="small"
                          style={{
                            fontSize: "0.7rem",
                            minWidth: "30px",
                          }}
                          onClick={() => removeMorality(index)}
                        >
                          -
                        </Button>
                      </Grid>
                    </Grid>
                    {pair.Weakness && (
                      <>
                        <Typography fontSize=".8rem">
                          {pair.Weakness.Description}
                        </Typography>
                        {pair.Weakness.Source && (<Typography fontSize=".8rem">
                          {formatSource(pair.Weakness.Source)}
                        </Typography>)}
                      </>
                    )}
                    <TextField
                      fullWidth
                      label="Emotional Weakness Details"
                      multiline
                      rows={4}
                      value={moralityPair[index].Weakness.Text}
                      onChange={(event) =>
                        handleMoralityWeaknessTextChange(event, index)
                      }
                    />
                  </Grid>
                </Grid>
              ))}
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  key="5"
                  control={
                    <Checkbox
                      checked={moralityBonus5xp1k}
                      onChange={() => handleMoralityBonus5xp1kChange()}
                      disabled={
                        moralityBonus10xp ||
                        moralityBonus2k ||
                        moralityUp ||
                        moralityDown
                      }
                    />
                  }
                  label="+5 starting XP and +1000 Starting Credits"
                />
                <FormControlLabel
                  key="10"
                  control={
                    <Checkbox
                      checked={moralityBonus10xp}
                      onChange={() => handleMoralityBonus10xpChange()}
                      disabled={
                        moralityBonus5xp1k ||
                        moralityBonus2k ||
                        moralityUp ||
                        moralityDown
                      }
                    />
                  }
                  label="+10 starting XP"
                />
                <FormControlLabel
                  key="2k"
                  control={
                    <Checkbox
                      checked={moralityBonus2k}
                      onChange={() => handleMoralityBonus2kChange()}
                      disabled={
                        moralityBonus5xp1k ||
                        moralityBonus10xp ||
                        moralityUp ||
                        moralityDown
                      }
                    />
                  }
                  label="+2500 starting credits"
                />
                <FormControlLabel
                  key="MoralityUp"
                  control={
                    <Checkbox
                      checked={moralityUp}
                      onChange={() => handleMoralityUpChange()}
                      disabled={
                        moralityBonus5xp1k ||
                        moralityBonus10xp ||
                        moralityBonus2k ||
                        moralityDown
                      }
                    />
                  }
                  label="+21 starting morality"
                />
                <FormControlLabel
                  key="MoralityDown"
                  control={
                    <Checkbox
                      checked={moralityDown}
                      onChange={() => handleMoralityDownChange()}
                      disabled={
                        moralityBonus5xp1k ||
                        moralityBonus10xp ||
                        moralityBonus2k ||
                        moralityUp
                      }
                    />
                  }
                  label="-21 starting morality"
                />
                <Typography>Morality score: {moralityValue}</Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Container>
  );
}

export default App;
